<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="close-icon" @click="closeModal">
        <img src="../images/close-popup.png" alt="" />
      </div>
      <div>
        <h1 class="title">Assign CC</h1>
      </div>
      <div class="cc_list_part" >
      <div class="cc_list" v-for="(item, index) in ccList" :key="index"
        style="max-height: 300px; overflow-y: auto;">
        <div class="status">
          <input type="radio" name="cc" :value=item.welth_user_id>
          <p>{{ item.user_name }}</p>
        </div>
      </div>
      </div>
      <div class="save">
        <button class="save-btn" @click="submitSelection">
          Assign CC
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ccList'],
  data() {
    return {
      selectedCC: null
    }
  },

  mounted() {
    console.log(this.ccList);
  },

  methods: {
    submitSelection() {
      // Select the checked radio button by using :checked selector
      var selectedRadio = document.querySelector('input[name="cc"]:checked');
      //console.log('Selected color value:', selectedRadio.value);
      if (selectedRadio) {
        this.$emit('selected-cc', selectedRadio.value);
      }
      this.closeModal();
    },

    closeModal() {
     
      this.$emit("close");
    },
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 300px;
  padding: 20px;
  margin: 100px auto;
   
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.title {

  color: #c7a84b;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}


.agent_list {
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
}

.status {
  display: flex;
  gap: 20px;
  padding-top: 10px;

}

.status p {
  font-size: 16;
  font-weight: 400;
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding-top: 20px;
}

.save-btn {
  height: 30px;
  width: 100px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;

}

.cc_list_part{
  height: 200px;
  overflow-y: auto;

}
</style>