<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="box1-f3">
        <h1 class="title">Transaction Details</h1>
        <div class="close-icon" @click="closePopup">
          <img src="../images/close-popup.png" alt="" />
        </div>

      </div>
      <p>Are you sure to approve cashback</p>
      <div class="d-flex">
        <div class="save">
          <button class="save-btn" @click="submitSelection">
            Yes
          </button>
        </div>
        <div class="save">
          <button class="save-btn" @click="closePopup">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closePopup() {
      this.$emit('close');
    },

    submitSelection() {
      this.$emit('selected-status', 8);
      this.closePopup()
    },
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 350px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  cursor: pointer;
}

.box1-f3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

}

.title {
  display: table-cell;
  color: #c7a84b;
  justify-content: flex-start;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.title-name {
  color: #263ba0;
  font-size: 14px;
  font-weight: 600;
  width: 170px;
}

.info {
  margin-left: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

p {
  color: #263ba0;
  margin-left: 10px;
  margin-top: 5px;
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;
}

.save-btn {
  height: 36px;
  width: 100px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
  margin-top: 20px;
  border: none;
}
</style>