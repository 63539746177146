<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="box1-f3">
        <h1 class="title">Field Visit</h1>
        <div class="close-icon" @click="closeModal">
          <img src="../images/close-popup.png" alt="" />
        </div>
      </div>
      <div style="overflow-y: auto; height:700px">
        <form @submit="saveFieldVisitInfo">
          <input type="text" placeholder="Add comment" v-model="commentText" />
          <div class="date-info">
            <div style="padding-top:11px"> Date</div>
            <input type="date" v-model="visitDate" style="width:330px" :min="minDateVal" @input="updateTimeRestriction" />
          </div>
          <div class="time-info">
            <div style="padding-top:11px"> Time</div>
            <input type="time" v-model="visitTime" style="width:330px" placeholder="time" :min="minTimeVal" />
          </div>
          <div class="error"> {{ validationMessage }} </div>
          <div class="save">
            <button id="saveBtn" class="save-btn">
              <span>Save</span>
              <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" id="addBtnSpinner"
                style="display: none"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getWelthUserId } from '@/util/loginData';
import axios from 'axios';
import { LEAD_INFO_URL, UPDATE_LEAD_URL } from '../util/constants';

export default {
  props: ['selectedLeadForSA', 'leadId'],

  data() {
    return {
      commentText: "Field visit at",
      visitDate: null,
      visitTime: null,
      validationMessage: "",
      newLeadData: null,
      minDateVal: null,
      minTimeVal: null,
      loggedInUserId: '',
    };
  },

  mounted() {
    console.log("Lead data for field visit: ", this.selectedLeadForSA);
    console.log("Current user id: " + getWelthUserId());
    this.loggedInUserId = getWelthUserId();
    this.minDate();
    this.minTime();
  },

  methods: {
    minDate() {
      console.log("minDate");
      const today = new Date();
      const year = today.getFullYear();
      const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Months are zero-indexed
      const day = `${today.getDate()}`.padStart(2, '0');
      this.minDateVal = `${year}-${month}-${day}`;
      // console.log(this.minDateVal);
      return this.minDateVal;
    },

    minTime() {
      if (this.isToday()) {
        console.log("Its today, so check time");
        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        // Format the time to 'HH:mm'
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        console.log(formattedTime);
        this.minTimeVal = formattedTime;
        console.log("minTimeVal " + this.minTimeVal);
        return formattedTime;
      } else {
        console.log("Its not today, so no time validation");
        // No minimum time restriction if the date is not today
        this.minTimeVal = null;
        console.log("minTimeVal " + this.minTimeVal);
        return null;
      }
    },

    isToday() {
      console.log("isToday function");
      console.log(this.visitDate);
      const selectedDate = new Date(this.visitDate);
      console.log(selectedDate);
      const today = new Date();
      console.log(today);
      return (
        selectedDate.getDate() === today.getDate() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getFullYear() === today.getFullYear()
      );
    },

    updateTimeRestriction() {
      console.log("updateTimeRestriction");
      console.log(this.visitDate);
      // Force reactivity on the minTime computed property
      this.minTime();
      this.$forceUpdate();
    },

    closeModal() {
      this.$emit("close");
    },

    async saveFieldVisitInfo(event) {
      console.log("saveFieldVisitInfo...");
      event.preventDefault(); // Prevent the default form submission
      let validation = true;
      if (validation && this.commentText == "") {
        this.validationMessage = "Please add comment";
        validation = false;
      }
      if (validation && this.visitDate == null) {
        this.validationMessage = "Please select field visit date";
        validation = false;
      }
      if (validation && this.visitTime == null) {
        this.validationMessage = "Please select field visit time";
        validation = false;
      }
      if (validation) {
        $("#saveBtn").attr('disabled', 'disabled');
        $("#addBtnSpinner").show();
        this.updateLeadInfo(this.selectedLeadForSA, this.commentText);
      }
    },

    async updateLeadInfo(leadData, descr) {
      console.log("AddAppointment updateLeadInfo");
      console.log(leadData);
      var description = descr + " " + this.visitDate + " " + this.visitTime;
      console.log(description);
      try {
        let data = {
          welth_leads_id: leadData.id,
          description: description,
          created_by_id: this.loggedInUserId
        }
        var self = this;
        console.log("updateLeadInfo", data);
        const response = await axios.post(LEAD_INFO_URL, data);
        console.log("updateLeadInfo response", response);
        if (response.data.code == 200) {
          console.log(response.data.message);
          this.updateLead();
        } else {
          alert(response.data.message);
          console.log(response.data.message);
          return null;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async updateLead() {
      console.log("updateLead");
      var self = this;
      let data = {
        lead_id: this.selectedLeadForSA.id,
        field_visit_at: this.visitDate + " " + this.visitTime,
        welth_user_id:this.loggedInUserId,
      }
      console.log("updateLead", data);

      const response = await axios.post(UPDATE_LEAD_URL, data);
      console.log("updateLead response", response);

      if (response.data.code == 200) {
        let l = response.data.data;
        this.text = '';
        setTimeout(() => {
          $("#saveBtn").removeAttr('disabled');
          $("#addBtnSpinner").hide();
          this.newLeadData = response.data.data;
          self.$emit('appointment-submitted', this.newLeadData, this.selectedLeadForSA.id);
          self.closeModal();
        }, 50);
      }
      // console.log(response.data.message);
    },
  },
  watch: {
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 600px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  height: 340px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  cursor: pointer;
}

.date-info {
  display: flex;
  gap: 0px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 70px;
  align-items: center;
  cursor: pointer;
  color: #3b53cb;
}

.time-info {
  display: flex;
  gap: 0px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 70px;
  align-items: center;
  cursor: pointer;
  color: #3b53cb;
}

.error {
  margin-top: 10px;
  margin-left: 25px;
  color: red;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.box1-f3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal1 input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.modal1 select {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.modal1 label {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
}

.close-icon {
  display: table-cell;
  justify-content: end;
  cursor: pointer;
  line-height: 48px;
}

.title {
  display: table-cell;
  color: #c7a84b;
  justify-content: flex-start;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}


.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;
}

.save-btn {
  height: 36px;
  width: 200px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
  margin-top: 20px;
}
</style>