// Create a session object to store session data
const session = {
    isAuthenticated: false,
    username: null,
    welthUserId: null,
    useremail: null,
    role: 0,
    roleInfo: null,
    corporateInfo: null,
};

// To set session data when a user logs in
export function setSessionData(username, welthUserId, emailId, userRole) {
    console.log("setSessionData", userRole);
    session.isAuthenticated = true;
    session.username = username;
    session.welthUserId = welthUserId;
    session.useremail = emailId;
    session.role = userRole;
    // console.log("set login session", session);
    // this.$store.dispatch('setData', session);
}

export function getSessionObj() {
    console.log(session);
    return session;
}

export function getSession() {
    // console.log("getSession");
    // return session;
    if (isAuthenticated()) {
        const storedSession = localStorage.getItem('sessionData');
        // console.log(storedSession);
        if (storedSession) {
            // return JSON.parse(storedSession);
            try {
                const sessionObject = JSON.parse(storedSession);
                // console.log(sessionObject);
                return sessionObject;
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }
    return null;
}

export function isAuthenticated() {
    // console.log("isAuthenticated");
    let token = localStorage.getItem('token');
    // console.log(token);
    return token !== null && token !== undefined;
    // return session.isAuthenticated;
}

export function setRoleInfoToSession(info) {
    session.roleInfo = info;
}

export function setCorporateInfoToSession(info) {
    session.corporateInfo = info;
}

function getCorporateInfoFromSession() {
    if (isAuthenticated()) {
        return session.corporateInfo;
    }
    return null;
}

export function getCorporateName(corporateId) {
    // console.log("getCorporateName", session.corporateInfo);
    let corporateInfo = getCorporateInfoFromSession();
    // console.log(corporateInfo);
    if (corporateInfo != null) {
        // console.log("getCorporateName corporateInfo not null");
        const foundData = corporateInfo.find(r => r.id === corporateId);
        if (foundData) {
            // console.log(foundData);
            return foundData.name;
        }
    }
    return corporateId;
}

export function getUserName() {
    // return session.username;
    if (isAuthenticated()) {
        let data = getSession();
        if (data !== null && data !== undefined) {
            return data.username;
        }
    }
    return '';
}

function getRoleInfoFromSession() {
    // return session.roleInfo;
    if (isAuthenticated()) {
        let data = getSession();
        if (data !== null && data !== undefined) {
            return data.roleInfo;
        }
    }
    return null;
}

export function getRoleName(roleid) {
    // console.log("getRoleName", session.role);
    let roleInfo = getRoleInfoFromSession();
    // console.log(roleInfo);
    if (roleInfo != null) {
        // console.log("getRoleName roleInfo not null");
        const foundRole = roleInfo.find(r => r.relationship_id === roleid);
        if (foundRole) {
            // console.log(foundRole);
            return foundRole.relationship_name;
        }
    }
    return roleid;
}

export function getWelthUserId() {
    // return session.welthUserId;
    if (isAuthenticated()) {
        let data = getSession();
        if (data !== null && data !== undefined) {
            return data.welthUserId;
        }
    }
    return -1;
}

// To clear session data when a user logs out
export function clearSessionData() {
    session.isAuthenticated = false;
    session.username = null;
    session.welthUserId = null;
    session.useremail = null;
    session.role = 0;
    console.log("clear login session", session);
    // Remove token from localStorage when the user logs out
    // localStorage.removeItem('sessionToken');
}