<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="close-icon" @click="closeModal">
        <img src="../images/close-popup.png" alt="" />
      </div>
      <div>
        <h1 class="title">Add comments</h1>
      </div>
      <form @submit="submitForm">
        <div class="title_top">
          <textarea placeholder="Update text" v-model="text"></textarea>
        </div>
        <button type="submit" style="display: none;"></button>
        <div class="title_top1">
          <input type="file" id="docId" name="docFiles[]"
            accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            style="display: none" @click="openFileDialog" />
          <label for="docId" class="title_top">
            <h5 class="labelclr">File</h5>
            <img width="24" height="24" src="https://img.icons8.com/color/48/attach.png" alt="attach" />

          </label>
        </div>
        <div class="save">
          <button id="uploadDocBtn" class="save-btn">
            <span>Save</span>
            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" id="uploadDocBtnSpinner"
              style="display:none;"></span>
          </button>
        </div>
        <div class="title_top1">
          <img ref="imagePreview" style="display: none,height:50px;" class="ms-2" @click="deleteImage" />
          <video ref="videoPreview" controls style="display: none;" class="ms-2" @click="deleteVideo"></video>
          <div ref="previewContainer" style="display: none;" @click="deleteFile">
            <iframe id="pdfPreview" style="height: 50px; pointer-events: none;" frameborder="0"></iframe>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import { getWelthUserId } from '@/util/loginData';
import axios from 'axios';
import { LEAD_INFO_URL, LEAD_COMMENT_UPLOADS } from '../util/constants';
export default {
  props: ['leadData'],

  data() {
    return {
      text: '',
      selectedImageFiles: [],
      selectedVideoFiles: [],
      selectedFiles: [],
      hasText: false,
      hasImage: false,
      hasVideo: false,
      hasFile: false,
      newLeadData: null,
      loggedInUserId: '',
    };
  },

  mounted() {
    // console.log(this.leadData);
    console.log("Current user id: " + getWelthUserId());
    this.loggedInUserId = getWelthUserId();
  },

  methods: {
    async updateLeadInfo(leadData, descr, hasImage, hasVideo, hasFile) {
      console.log("updateLeadInfo");
      console.log(hasFile);
      try {
        let data = {
          welth_leads_id: leadData.id,
          description: descr,
          created_by_id: this.loggedInUserId
        }
        var self = this;
        console.log("updateLeadInfo", data);
        const response = await axios.post(LEAD_INFO_URL, data);
        console.log("updateLeadInfo response", response);
        if (response.data.code == 200) {
          console.log(response.data.message);
          this.text = '';
          setTimeout(() => {
            $("#uploadDocBtn").removeAttr('disabled');
            $("#uploadDocBtnSpinner").hide();
            this.newLeadData = response.data.data;
            // this.$emit('text-submitted', newLeadData);
            if (hasFile || hasImage || hasVideo) {
              console.log("updateLeadInfo text, popup not closed");
              let params = {
                welth_leads_info_id: this.newLeadData.id,
                created_by_id: this.loggedInUserId
              }
              if (hasImage) {
                let formData = new FormData();
                for (let i = 0; i < this.selectedImageFiles.length; i++) {
                  formData.append('leads_doc', this.selectedImageFiles[i]);
                }
                this.postDocument(formData, params);
              }
              if (hasVideo) {
                let formData = new FormData();
                for (let i = 0; i < this.selectedVideoFiles.length; i++) {
                  formData.append('leads_doc', this.selectedVideoFiles[i]);
                }
                this.postVideo(formData, params);
              }
              if (hasFile) {
                console.log("updateLeadInfo has file");
                let formData = new FormData();
                for (let i = 0; i < this.selectedFiles.length; i++) {
                  formData.append('leads_doc', this.selectedFiles[i]);
                }
                this.postDocument(formData, params);
              }
            } else {
              // this.$emit('text-submitted', this.newLeadData);
              self.$emit('file-uploaded', this.newLeadData);
              self.closeModal();
            }
          }, 50);
        } else {
          alert(response.data.message);
          console.log(response.data.message);
          return null;
        }
      } catch (e) {
        console.log(e);
      }
    },

    submitForm(event) {
      console.log("submitForm");
      event.preventDefault(); // Prevent the default form submission
      console.log(this.selectedFiles);
      console.log(this.selectedFiles.length);
      if (this.selectedImageFiles != null && this.selectedImageFiles.length > 0) {
        this.hasImage = true;
      }
      if (this.selectedVideoFiles != null && this.selectedVideoFiles.length) {
        this.hasVideo = true;
      }
      if (this.selectedFiles != null && this.selectedFiles.length > 0) {
        this.hasFile = true;
        console.log("Files attached");
      }
      if (this.text.trim() !== '') {
        this.hasText = true;
        this.updateLeadInfo(this.leadData, this.text, this.hasImage, this.hasVideo, this.hasFile);
      }
    },

    async postDocument(formData, params) {
      console.log("postDocument");
      $("#uploadDocBtn").attr('disabled', 'disabled');
      $("#uploadDocBtnSpinner").show();
      var self = this;
      var url = LEAD_COMMENT_UPLOADS + '?welth_leads_info_id=' + params.welth_leads_info_id + '&created_by_id=' + params.created_by_id;
      console.log(url);
      try {
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
          },
        });
        console.log("postDocument response");
        console.log(response);
        if (response.status == 200) {
          console.log("emitting image upload done");
          console.log(response.data.data);
          setTimeout(() => {
            self.$emit('file-uploaded', this.newLeadData);
            if (!self.hasVideo) {
              $("#uploadDocBtn").removeAttr('disabled');
              $("#uploadDocBtnSpinner").hide();
              self.closeModal();
            }
          }, 50);
        }
      } catch (error) {
        console.error('Error uploading file', error);
        $("#uploadDocBtn").removeAttr('disabled');
        $("#uploadDocBtnSpinner").hide();
      }
    },

    async postVideo(formData, params) {
      console.log("postVideo");
      $("#uploadDocBtn").attr('disabled', 'disabled');
      $("#uploadDocBtnSpinner").show();
      var self = this;
      var url = LEAD_COMMENT_UPLOADS + '?welth_leads_info_id=' + params.welth_leads_info_id + '&created_by_id=' + params.created_by_id;
      console.log(url);
      try {
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
          },
        });
        console.log("post response");
        console.log(response);
        if (response.status == 200) {
          console.log("emitting postVideo");
          setTimeout(() => {
            self.$emit('video-uploaded', this.newLeadData);
            $("#uploadDocBtn").removeAttr('disabled');
            $("#uploadDocBtnSpinner").hide();
            self.closeModal();
          }, 50);
        }

      } catch (error) {
        console.error('Error uploading file', error);
        $("#uploadDocBtn").removeAttr('disabled');
        $("#uploadDocBtnSpinner").hide();
      }
    },

    closeModal() {
      this.$emit("close");
    },

    openFileDialog() {
      const inputElement = document.getElementById("docId");
      const imagePreviewElement = this.$refs.imagePreview;
      const filePreviewElement = this.$refs.previewContainer;
      const pdfPreview = document.getElementById('pdfPreview');

      if (!inputElement || !imagePreviewElement) {
        console.error("Element not found.");
        return;
      }
      const self = this;

      inputElement.addEventListener("change", function () {
        if (inputElement.files.length > 0) {
          const file = inputElement.files[0];
          console.log(file);
          var isImage = file.type.startsWith('image/');
          const objectURL = URL.createObjectURL(file);
          console.log(isImage);
          console.log(objectURL);
          if (isImage) {
            self.deleteFile();
            imagePreviewElement.src = objectURL;
            imagePreviewElement.style.display = "block";
            imagePreviewElement.height = 75;
            imagePreviewElement.width = 75;
            console.log("Image Previewed");
          } else {
            self.deleteImage();
            pdfPreview.src = objectURL;
            filePreviewElement.style.display = "block";
            filePreviewElement.height = 75;
            filePreviewElement.width = 75;
            console.log("File Previewed");
          }
          const newFiles = Array.from(inputElement.files);
          self.selectedFiles.push(newFiles[0]);
          // inputElement.value = '';
        } else {
          console.log("No selected files");
          imagePreviewElement.src = "";
          imagePreviewElement.style.display = "none";
          pdfPreview.src = "";
          filePreviewElement.style.display = "none";
          self.selectedFiles = [];
        }
      });

      inputElement.click(); // Trigger the file input dialog
    },

    deleteImage() {
      const imagePreviewElement = this.$refs.imagePreview;
      imagePreviewElement.src = ''; // Reset the image source
      imagePreviewElement.style.display = 'none'; // Hide the image
      this.selectedImageFiles = [];
      this.selectedFiles = [];
    },

    deleteFile() {
      console.log("deleteFile");
      const filePreviewElement = this.$refs.previewContainer;
      const fileElement = document.getElementById('pdfPreview');
      fileElement.src = ''; // Reset the video source
      filePreviewElement.style.display = 'none'; // Hide the video
      this.selectedFiles = [];
    },

    deleteVideo() {
      console.log("deleteVideo");
      const videoPreviewElement = this.$refs.videoPreview;
      videoPreviewElement.src = ''; // Reset the video source
      videoPreviewElement.style.display = 'none'; // Hide the video
      this.selectedVideoFiles = [];
    },

    openImageDialog() {
      const inputElement = document.getElementById("imageId");
      const imagePreviewElement = this.$refs.imagePreview;

      if (!inputElement || !imagePreviewElement) {
        console.error("Element not found.");
        return;
      }
      const self = this;

      inputElement.addEventListener("change", function () {
        if (inputElement.files.length > 0) {
          const file = inputElement.files[0];
          console.log(file);
          const newFiles = Array.from(inputElement.files);
          self.selectedImageFiles.push(newFiles[0]);
          const objectURL = URL.createObjectURL(file);
          imagePreviewElement.src = objectURL;
          imagePreviewElement.style.display = "block";
          imagePreviewElement.height = 75;
          imagePreviewElement.width = 75;
        } else {
          imagePreviewElement.src = "";
          imagePreviewElement.style.display = "none";
          self.selectedImageFiles = [];
        }
      });

      inputElement.click(); // Trigger the file input dialog
    },

    openVideoDialog() {
      const inputElement = document.getElementById('videoId');
      const videoPreviewElement = this.$refs.videoPreview;

      if (!inputElement || !videoPreviewElement) {
        console.error('Element not found.');
        return;
      }
      const self = this;

      inputElement.addEventListener('change', function () {
        if (inputElement.files.length > 0) {
          const file = inputElement.files[0];
          console.log(file);
          const newFiles = Array.from(inputElement.files);
          self.selectedVideoFiles.push(newFiles[0]);
          const objectURL = URL.createObjectURL(file);
          videoPreviewElement.src = objectURL;
          videoPreviewElement.style.display = 'block';
          videoPreviewElement.height = 75;
          videoPreviewElement.width = 75;
        } else {
          videoPreviewElement.src = '';
          videoPreviewElement.style.display = 'none';
          self.selectedVideoFiles = [];
        }
      });

      inputElement.click(); // Trigger the file input dialog
    },

    submitForm1(event) {
      event.preventDefault();
      this.$emit('formSubmitted', this.value);
    },
  },
};
</script>
  
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 800px;
  padding: 20px;
  margin: 100px auto;
  height: 400px;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.title {
  color: #c7a84b;

  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.title_top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_top1 {
  display: flex;
  margin-left: 90px;
  margin-top: 20px;
  align-items: center;
}

.title_top textarea {
  height: 100px;
  width: 80%;
  border-radius: 24px;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
  border-radius: 24px;
  border: 0.5px solid #c7a84b;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
}

.labelclr {
  color: #263ba0;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
}

.save {
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-btn {
  height: 40px;
  width: 100px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
}
</style>
  