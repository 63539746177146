<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="close-icon" @click="closeModal">
        <img src="../images/close-popup.png" alt="" />
      </div>
      <div>
        <!-- <h1 class="title">field Agent</h1> -->
        <div class="title">
          <p>Add Scheduled Callback</p>
        </div>
      </div>

      <form @submit="submitForm">
        <div class="text_update">
          <textarea v-model="text" placeholder="Add comment"></textarea>
        </div>

        <div class="date-info">
          Date
          <input type="date" v-model="selDate" :min="minDateVal" @input="updateTimeRestriction" />
        </div>

        <div class="time-info">
          Time
          <input type="time" v-model="selTime" placeholder="time" :min="minTimeVal" />
        </div>

        <div class="error"> {{ validationMessage }} </div>
        <div class="save">
          <button id="saveBtn" class="save-btn">
            <span>Save</span>
            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" id="addBtnSpinner"
              style="display: none"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getWelthUserId } from '@/util/loginData';
import axios from 'axios';
import { LEAD_INFO_URL, UPDATE_LEAD_URL } from '../util/constants';

export default {
  props: ['leadData', 'leadId'],

  data() {
    return {
      selTime: null,
      selDate: null,
      text: 'Call back at',
      newLeadData: null,
      validationMessage: "",
      minDateVal: null,
      minTimeVal: null,
      loggedInUserId: '',
    };
  },

  mounted() {
    console.log("Mounted leadData");
    console.log(this.leadData);
    console.log("Current user id: " + getWelthUserId());
    this.loggedInUserId = getWelthUserId();
    this.minDate();
    this.minTime();
  },

  methods: {
    minDate() {
      console.log("minDate");
      const today = new Date();
      const year = today.getFullYear();
      const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Months are zero-indexed
      const day = `${today.getDate()}`.padStart(2, '0');
      this.minDateVal = `${year}-${month}-${day}`;
      // console.log(this.minDateVal);
      return this.minDateVal;
    },

    minTime() {
      if (this.isToday()) {
        console.log("Its today, so check time");
        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        // Format the time to 'HH:mm'
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        console.log(formattedTime);
        this.minTimeVal = formattedTime;
        return formattedTime;
      } else {
        console.log("Its not today, so no time validation");
        // No minimum time restriction if the date is not today
        this.minTimeVal = null;
        return null;
      }
    },

    isToday() {
      console.log("isToday function");
      console.log(this.selDate);
      const selectedDate = new Date(this.selDate);
      console.log(selectedDate);
      const today = new Date();
      console.log(today);
      return (
        selectedDate.getDate() === today.getDate() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getFullYear() === today.getFullYear()
      );
    },

    updateTimeRestriction() {
      console.log("updateTimeRestriction");
      console.log(this.selDate);
      // Force reactivity on the minTime computed property
      this.minTime();
      this.$forceUpdate();
    },

    async updateLeadInfo(leadData, descr) {
      console.log("updateLeadInfo");
      var description = descr + " " + this.selDate + " " + this.selTime;
      console.log(description);
      try {
        let data = {
          welth_leads_id: leadData.id,
          description: description,
          created_by_id: this.loggedInUserId
        }
        var self = this;
        console.log("updateLeadInfo", data);
        const response = await axios.post(LEAD_INFO_URL, data);
        console.log("updateLeadInfo response", response);
        if (response.data.code == 200) {
          console.log(response.data.message);
          this.updateLead();
        } else {
          alert(response.data.message);
          console.log(response.data.message);
          return null;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async updateLead() {
      console.log("updateLead");
      var self = this;
      let data = {
        lead_id: this.leadData.id,
        callback_at: this.selDate + " " + this.selTime,
        welth_user_id:this.loggedInUserId,
      }
      console.log("updateLead", data);

      const response = await axios.post(UPDATE_LEAD_URL, data);
      console.log("updateLead response", response);

      if (response.data.code == 200) {
        let l = response.data.data;
        this.text = '';
        setTimeout(() => {
          $("#saveBtn").removeAttr('disabled');
          $("#addBtnSpinner").hide();
          this.newLeadData = response.data.data;
          self.$emit('callback-submitted', this.newLeadData, this.leadData.id);
          self.closeModal();
        }, 50);
      }
      console.log(response.data.message);
    },

    submitForm(event) {
      console.log("submitForm");
      event.preventDefault(); // Prevent the default form submission

      let validation = true;
      if (validation && this.text == "") {
        this.validationMessage = "Please add comment";
        validation = false;
      }
      if (validation && this.selDate == null) {
        this.validationMessage = "Please select callback date";
        validation = false;
      }
      if (validation && this.selTime == null) {
        this.validationMessage = "Please select callback time";
        validation = false;
      }
      if (validation) {
        $("#saveBtn").attr('disabled', 'disabled');
        $("#addBtnSpinner").show();
        console.log(this.text);
        this.updateLeadInfo(this.leadData, this.text);
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 500px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.error {
  margin-top: 10px;
  margin-left: 25px;
  color: red;
}

.title {
  color: #c7a84b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.time-info {
  display: flex;
  gap: 30px;
  margin-top: 10px;
  align-items: center;
  color: #3b53cb;
}

.text_update textarea {
  border: 1px solid #c7a84b;
  border-radius: 25px;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
}

.date-info {
  display: flex;
  gap: 30px;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
  color: #3b53cb;
}

.save {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
}

.save-btn {
  padding: 6px 20px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
}
</style>