<template>
  <div class="home">
    <Narayan />
  </div>
</template>

<script>
import Narayan from '../components/Narayan.vue';
export default {
  name: 'HomeView',
  components: { Narayan }
}
</script>
