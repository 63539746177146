<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="close-icon" @click="closeModal">
        <img src="../images/close-popup.png" alt="" />
      </div>

      <div>
        <h1 class="title">Update Status</h1>
      </div>
      <div class="status_list" v-for="(item, index) in statusList" :key="index">
        <div class="status">
          <input type="radio" name="status" :value=item.status :checked="item.status === statusValue"
            :disabled="isRadioDisabled(index)">
          <p>{{ item.value }}</p>
        </div>
      </div>

      <div class="save">
        <button class="save-btn" @click="submitSelection">
          Update Status
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import { STATUS_VALUES } from '../util/constants';
export default {
  props: ['statusList', 'leadData', 'isAdmin'],
  data() {
    return {
      selectedStatus: -1,
      statusValue: -1,
    }
  },

  mounted() {
    console.log("statusList");
    console.log(this.statusList);
    // console.log(this.leadData);
    this.statusValue = this.leadData?.status;
    // console.log(this.statusValue);
    console.log(this.isAdmin);
  },

  methods: {
    isRadioDisabled(index) {
      if (index == STATUS_VALUES.CASHBACK_APPROVED) { // Approved for Cashback
        return true;
      }
      if (this.leadData.status == STATUS_VALUES.NEW_LEAD) { // New Lead
        return index == STATUS_VALUES.NEW_LEAD || index > STATUS_VALUES.PAYMENT_DONE;
      } else if (this.leadData.status == STATUS_VALUES.CALLBACK || this.leadData.status == STATUS_VALUES.APPOINTMENT) { // Callback & appointment
        return index == STATUS_VALUES.NEW_LEAD || index > STATUS_VALUES.PAYMENT_DONE;  // from callback till payment done
      } else if (this.leadData.status == STATUS_VALUES.NOT_INTERESTED) { // Not interested
        return index > STATUS_VALUES.APPOINTMENT;
      } else if (this.leadData.status == STATUS_VALUES.PAYMENT_DONE) { // Payment done
        return index != STATUS_VALUES.POLICY_ISSUED; // only policy issued
      } else if (this.leadData.status >= STATUS_VALUES.FLP_COMPLETED) {
        return this.isAdmin;
      } else if (this.leadData.status == STATUS_VALUES.POLICY_ISSUED) { // Policy issued
        return index == STATUS_VALUES.POLICY_ISSUED || index <= this.leadData.status;
      }
      return this.leadData.status > index;
    },

    submitSelection() {
      var selectedRadio = document.querySelector('input[name="status"]:checked');
      //console.log('Selected color value:', selectedRadio.value);
      if (selectedRadio) {
        this.$emit('selected-status', selectedRadio.value);
      }
      this.closeModal()
    },

    closeModal() {
      this.$emit("close");
    },
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 400px;

  padding: 20px;
  margin: 100px auto;
  height: auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.title {
  color: #c7a84b;

  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.status_list {
  margin-left: 20px;
  padding-top: 10px;
}

.status_list p {
  font-size: 16;
  font-weight: 400;
}

.status {
  display: flex;
  gap: 20px;
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;
}

.save-btn {
  height: 30px;
  width: 120px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
}
</style>