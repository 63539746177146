<template>
    <!--    <div class="container " > -->
    <div class="leadStats table-responsive">
        <h1 class="mt-4">Leads By Created Date</h1>
        <table class="table  tab-info align-middle">
            <thead style="text-align: center;">
                <tr class="table-primary">
                    <th>Start date</th>
                    <th>End date</th>
                    <th v-for="(item, index) in statusList" :key="index">{{ item.value }}</th>
                </tr>
            </thead>
            <tbody class="center-align" style="text-align: center;">
                <tr>
                    <th><input type="date" v-model="startDate" @input="changeDate" :max="maxStartDateVal"></th>
                    <th><input type="date" v-model="endDate" @input="changeDate" :max="maxEndDateVal"></th>
                    <td class="center-align col-1" v-for="(sitem, sKey) in statusList" :key="sKey">
                        <a href="javascript:void(0)" @click="updateDateFilterSelection(sitem.value)">{{
                            getStatusCount(dateFilteredData, sitem.status) }}</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <h1 class="mt-4">Customer Care Status</h1>
        <table class="table  tab-info align-middle">
            <thead style="text-align: center;">
                <tr class="table-primary">
                    <th class="col-2">Name</th>
                    <th v-for="(item, index) in statusList" :key="index">{{ item.value }}</th>
                </tr>
            </thead>
            <tbody class="center-align" style="text-align: center;">
                <tr v-for="(item, key) in ccData" :key="key">
                    <th class="col-1">{{ getNameUserName(item[0]) }}</th>
                    <td class="center-align col-1" v-for="(sitem, sKey) in statusList" :key="sKey">
                        <a href="javascript:void(0)" @click="updateFilterSelection(item[0], sitem.value)">{{
                            getStatusCount(item[1], sitem.status) }}</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <h1 style="margin-top: 30px;">Filed Agent Status</h1>
        <table class="table  tab-info">
            <thead style="text-align: center;">
                <tr class="table-primary">
                    <th scope="col" class="col-2">Name</th>
                    <th scope="col" v-for="(item, index) in statusList" :key="index">{{ item.value }}</th>
                </tr>
            </thead>
            <tbody class="center-align" style="text-align: center;">
                <tr v-for="(item, key) in fieldAgemtData" :key="key">
                    <th class="col-1">{{ getNameUserName(item[0]) }}</th>
                    <td class="center-align col-1" v-for="(sitem, sKey) in statusList" :key="sKey">
                        <a href="javascript:void(0)" @click="updateFilterSelection(item[0], sitem.value)">{{
                            getStatusCount(item[1], sitem.status) }}</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- </div> -->
</template>

<script>
export default {
    props: ['leadData', 'fieldAgemtData', 'ccData', 'statusList', 'userList'],

    data() {
        return {
            text: '',
            startDate: '',
            endDate: '',
            startDateVal: new Date(),
            endDateVal: new Date(),
            dateFilteredData: new Map(),
            maxStartDateVal: null,
            maxEndDateVal: null,
        };
    },

    watch: {
        leadData: {
            handler(newValue, oldValue) {
                // Handle changes to leadData or its properties here
                console.log('leadData changed:', newValue);
                this.changeDate();
            },
            deep: true
        }
    },

    mounted() {
        // console.log("Lead Data.................................");
        // console.log(this.leadData);
        // console.log(this.statusList);
        this.startDate = new Date().toISOString().split('T')[0];
        this.endDate = new Date().toISOString().split('T')[0];
        this.dateFilteredData = this.getStausMap();
        this.maxDate();
        this.changeDate();
        // console.log(this.ccData)
        // console.log(this.fieldAgemtData)
        // console.log(this.userList)
    },

    methods: {
        maxDate() {
            // console.log("maxDate");
            const today = new Date();
            const year = today.getFullYear();
            const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Months are zero-indexed
            const day = `${today.getDate()}`.padStart(2, '0');
            this.maxStartDateVal = `${year}-${month}-${day}`;
            this.maxEndDateVal = `${year}-${month}-${day}`;
            // console.log(this.minDateVal);
            return this.maxDateVal;
        },

        changeDate() {
            console.log("changeDate");
            // console.log("start date", this.startDate);
            // console.log("today date", this.todayDate);
            if (this.startDate && this.endDate) {
                this.startDateVal = new Date(this.startDate);
                this.endDateVal = new Date(this.endDate);
                // console.log("start date", this.startDateVal);
                // console.log("today date", this.endDateVal);
                if (this.startDateVal > this.endDateVal) {
                    window.alert("Start date cannot be higher than end date");
                    this.endDate = "";
                    this.dateFilteredData.clear();
                } else {
                    this.updatedStats();
                }
            }
        },

        updatedStats() {
            // console.log("updatedStats in stats");
            this.dateFilteredData.clear();
            this.dateFilteredData = this.getStausMap();
            // console.log("dateFilteredData " + this.dateFilteredData);
            const startDateOnly = this.startDateVal.toISOString().split('T')[0];
            const endDateOnly = this.endDateVal.toISOString().split('T')[0];
            // console.log("start date only ", startDateOnly);
            // console.log("today date only ", endDateOnly);
            this.leadData.forEach(lead => {
                if (lead.created_at != null) {
                    let leadDate = new Date(lead.created_at);
                    if (lead.created_at_original) {
                        leadDate = new Date(lead.created_at_original);
                    }
                    const leadDateOnly = leadDate.toISOString().split('T')[0];
                    // console.log("leadDate " + leadDate);
                    if (leadDateOnly >= startDateOnly && leadDateOnly <= endDateOnly) {
                        // console.log("consider leadDateOnly " + leadDateOnly);
                        // console.log("lead status " + lead.status);
                        let countData = this.dateFilteredData.get(lead.status);
                        this.dateFilteredData.set(lead.status, countData + 1);
                    } else {
                        // console.log("ignore leadDateOnly " + leadDateOnly);
                    }
                }
            });
            // console.log(this.dateFilteredData);
        },

        getStausMap() {
            let sdata = new Map();
            this.statusList.forEach(s => {
                sdata.set(s.status, 0)
            });
            return sdata;
        },

        updateFilterSelection(userId, status) {
            // console.log("updateFilterSelection");
            // console.log(this.getNameUserName(userId), status);
            this.$emit('FilterChaned', userId, status);
        },

        updateDateFilterSelection(status) {
            // console.log("updateDateFilterSelection " + status);
            // console.log(this.getNameUserName(userId), status);
            this.$emit('DateFilterChanged', status, this.startDate, this.endDate);
        },

        getNameUserName(id) {
            //console.log("user ID ssa" + id)
            let uInfo = this.userList.get(id);
            // console.log(uInfo);
            return uInfo.user_name;
        },

        getStatusCount(item, statusId) {
            //console.log("getStatusCount")
            return item.get(statusId);
        },
    }
}
</script>

<style scoped>
.leadStats {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px;
    flex-direction: column;
    background: #fff;
    width: 80%;
    margin-left: 12%;
    border-radius: 25px;
    padding-top: 40px;
}

.leadStats h1 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    height: 30px;
    background: #3B53CB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0px 5px;
    position: relative;
    right: 500px;
}

input {
    border: 0.5px solid #c7a84b;
    border-radius: 8px;
    padding-left: 5px;
    height: 30px;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

.leadStats p {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    height: 30px;
    background: #3B53CB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0px 5px;
    position: relative;
    right: 520px;
}

.tab-info {
    width: 95%;
}

.table-header {
    color: #3B53CB;
}

.table_data {
    position: relative;
    left: 20px;
}
</style>