<template>
  <div>
    <div class="container">
      <input
        class="search_input"
        type="text"
        v-model="searchQuery"
        placeholder="Search by Name or Phone Number"
      />
      <div class="d-flex">
        <h2 class="title1 ms-2">Welth Users Name</h2>
        <!-- <h2  class="title1 ms-5">Phone Number</h2> -->
      </div>
      <div v-for="(user, index) in filteredUsers" :key="index">
        <div
          v-if="user.user_name !== null && user.user_name !== ''"
          class="ms-2 user_info"
        >
          <div class="d-flex justify-content-between">
            <div
              class="d-flex"
              v-if="user.user_name !== null && user.user_name !== ''"
            >
              <div class=" " style="width: 200px">
                <div>{{ user.user_name }}</div>
                {{ user.user_phoneno }}
              </div>

              <span class="mt-2 show_doc" v-if="user.doc_name.length > 0">
                <span
                  v-for="(docName, docIndex) in user.doc_name"
                  :key="docIndex"
                  class="mx-2 my-3"
                >
                  <a
                    :href="user.doc_urls[docIndex]"
                    class="btn2 mx-2"
                    :download="'Document' + (docIndex + 1)"
                    target="_blank"
                    >{{ docName }}</a
                  >
                  <span
                    class="delete-icon"
                    @click="deleteDocument(user.id[docIndex])"
                  >
                    <i class="fas fa-times"></i>
                  </span>
                </span>
              </span>
            </div>

            <div
              v-if="user.user_name !== null && user.user_name !== ''"
              class="btn_add"
              @click="openDocumentModal(user)"
            >
              Add User Documents
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="closeDocumentModal"></div>
    <div class="model1">
      <div class="box">
        <div class="d-flex justify-content-between align-items-center">
          <h3>Add User Document</h3>
          <div class="close" @click="closeDocumentModal">X</div>
        </div>
        <div class="field">
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter Document Name"
              v-model="fileName"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="file-label d-flex gap-2">
              <input
                type="file"
                @change="handleFileSelect"
                ref="fileInput"
                name="user_doc"
                accept=".pdf, image/*"
                style="display: none"
              />
              <div class="button is-info pe-2">
                <span>Choose File</span>
              </div>
              <span class="file-icon ms-3 mt-3">
                <i class="fas fa-upload"></i>
              </span>
            </label>
            <iframe
              class="my-2 mx-3"
              v-if="selectedFile"
              :src="fileURL"
              style="width: 100%; height: 200px"
            ></iframe>
          </div>
          <p v-if="errorMessage" style="color: red;margin:10px">{{ errorMessage }}</p>
        </div>
        <div class="field">
          <div class="control d-flex align-items-center justify-content-center">
            <button class="button3" @click="addDocument">
              <span v-if="!loading">Add Document</span>
              <span v-else>
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Adding Document...
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeDocumentModal"
    ></button>
  </div>

  <!-- Delete confirmation modal -->
  <div class="modal" :class="{ 'is-active': isDeleteFileModal }">
    <div class="modal-background" @click="closeDeleteConfirmtion"></div>
    <div class="model1">
      <div class="box">
        <div class="d-flex justify-content-between align-items-center">
          <h3>Delete User Document</h3>
          <div class="close" @click="closeDeleteConfirmtion">X</div>
        </div>

        <div>
          <h1 class="d-flex justify-content-center" style="font-size: 20px">
            Are you sure you want to delete this file ?
          </h1>

          <div class="d-flex justify-content-center gap-3 mt-4">
            <button class="button4" @click="deleteConfirm">Yes</button>
            <button class="button4" @click="closeDeleteConfirmtion">No</button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeDeleteConfirmtion"
    ></button>
  </div>
</template>

<script>
import axios from "axios";
import {
  GET_WELTH_USER_INFO,
  UPLOAD_USER_DOCUMENT,
  UPLOAD_USER_DOC_DET,
  GET_WELTH_USER_DOCS,
  DELETE_WELTH_USER_DOCS,
} from "@/util/constants";
export default {
  data() {
    return {
      Users: [],
      UserDoc: [],
      searchQuery: "",
      showModal: false,
      fileName: "",
      selectedFile: null,
      currentUser: null,
      fileURL: null,
      loading: false,
      isDeleteFileModal: false,
      deleteId: null,
      errorMessage: '',
    };
  },
  mounted() {
    this.getWelthUser();
    this.getUserDocs();
  },
  computed: {
    filteredUsers() {
      return this.Users.map((user) => {
        const userDocs = this.UserDoc.filter(
          (doc) => doc.welth_user_id === user.welth_user_id
        );
        const doc_urls = userDocs.map((doc) => doc.doc_url);
        const doc_name = userDocs.map((doc) => doc.doc_name);
        const id = userDocs.map((doc) => doc.id);
        return {
          ...user,
          doc_urls: doc_urls.length > 0 ? doc_urls : [],
          doc_name: doc_name.length > 0 ? doc_name : [],
          id: id.length > 0 ? id : [],
        };
      }).filter(
        (user) =>
          user.user_name !== null &&
          (user.user_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
            (user.user_phoneno !== null &&
              user.user_phoneno.includes(this.searchQuery)))
      );
    },
  },

  methods: {
    async getWelthUser() {
      try {
        let response = await axios.get(GET_WELTH_USER_INFO);
        this.Users = response.data.data.welth_user_info;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },

    async getUserDocs() {
      try {
        let response = await axios.get(GET_WELTH_USER_DOCS);
        this.UserDoc = response.data.data.welth_docs;
      } catch (error) {
        console.error("Error fetching docs:", error);
      }
    },

    openDocumentModal(user) {
      this.showModal = true;
      this.currentUser = user.welth_user_id;
    },
    closeDocumentModal() {
      this.showModal = false;
      this.fileName = "";
      this.selectedFile = null;
      const input = this.$refs.fileInput;
      if (input) {
        input.value = "";
      }
    },

    closeDeleteConfirmtion() {
      this.isDeleteFileModal = false;
    },

    handleFileSelect(event) {
      if (event.target.files && event.target.files.length > 0) {
        const newFile = event.target.files[0];

        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (newFile.size > maxSizeInBytes) {
          // File size exceeds 2MB, show error message
          this.errorMessage = ' File size exceeds 5MB. Please choose a smaller file.';
          event.target.value = null; // Reset input
          return;
        }

        if (this.selectedFile !== newFile) {
          // New file selected
          this.selectedFile = newFile;
          this.fileURL = URL.createObjectURL(this.selectedFile);
        } else {
          // Same file selected again, reset input
          event.target.value = null;
        }
        this.errorMessage = '';
      }
    },

    async addDocument() {
      this.loading = true;
      if (!this.fileName || !this.selectedFile) {
        alert("Please Enter file Name and Select a Document.");
        this.loading = false;
        return;
      }
      const formData = new FormData();
      formData.append("user_doc", this.selectedFile);
      console.log(" this.selectedFile", this.selectedFile);
      console.log("this.currentUser : ", this.currentUser);

      let resp = await axios.post(UPLOAD_USER_DOCUMENT, formData);
      console.log(resp);
      let userData = {
        welth_user_id: this.currentUser,
        doc_url: resp.data.data.user_doc_url,
        doc_name: this.fileName,
      };
      if (resp.data.code == 200) {
        let uploadData = await axios.post(UPLOAD_USER_DOC_DET, userData);
        console.log(uploadData);
        this.closeDocumentModal();
        this.getWelthUser();
        this.getUserDocs();
      }
      this.loading = false;
    },

    async deleteConfirm() {
      console.log(DELETE_WELTH_USER_DOCS + this.deleteId);
      try {
        let resp = await axios.delete(DELETE_WELTH_USER_DOCS + this.deleteId);
        console.log(resp);
        this.getWelthUser();
        this.getUserDocs();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
      this.isDeleteFileModal = false;
    },

    async deleteDocument(docIndex) {
      this.isDeleteFileModal = true;

      this.deleteId = docIndex;
    },
  },
};
</script>

<style scoped>
.title1 {
  color: #3b53cb;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  line-height: 48px;
}

.user_info {
  background: #fff;
  padding: 10px;
  margin: 5px 0px;
  border-radius: 10px;
}

.btn2 {
  padding: 8px 20px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
  cursor: pointer;
}

.btn_add {
  width: 200px;
  padding-top: 8px;
  height: 40px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
  cursor: pointer;
  text-align: center;
}
.search_input {
  width: 60%;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.04em;
  color: #000;
}

.modal {
  display: none;
}

.box {
  width: 800px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  flex-direction: column;
}

.box input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.box .button {
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
  padding-left: 20px;
  padding-top: 5px;
  cursor: pointer;
}

.box h3 {
  color: #c7a84b;

  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
}

.modal-close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-close.is-large::after {
  font-size: 30px;
  color: black;
}

.close {
  color: #c7a84b;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.button3 {
  border-radius: 24px;
  background: #3b53cb;
  color: #fff;
  border: 1px solid #c7a84b;
  height: 40px;
  padding: 0px 10px;
}
.button4 {
  border-radius: 24px;
  background: #3b53cb;
  color: #fff;
  border: 1px solid #c7a84b;
  height: 40px;
  padding: 0px 30px;
}

.delete-icon {
  color: #c7a84b;
  position: relative;
  right: 10px;
  bottom: 5px;
  cursor: pointer;
  /*background: #3b53cb;*/
  padding: 5px;
  border-radius: 50%;
}

.user_name {
  display: flex;
  flex-direction: column;
}

.show_doc {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}
</style>
