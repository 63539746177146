<template>
  <div v-if="show" class="toast" :class="[type]">
    {{ message }}
  </div>
</template>
  
<script>
export default {
  props: {
    message: String,
    show: Boolean,
  },
  // data() {
  //   return {
  //     show: false,
  //   };
  // },
  // methods: {
  //   showToast() {
  //     this.show = true;
  //     setTimeout(() => {
  //       this.show = false;
  //     }, 3000);
  //   },
  // },
};
</script>
  
<style scoped>
/* Add your styles for the toast component here */
.toast {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white-space;
  font-family: Raleway;
  font-size: 20px;
  font-style: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .success {
    background-color: #4CAF50;
  }
  
  .error {
    background-color: #F44336;
  }
  
  .info {
    background-color: #2196F3;
  } */
</style>
  