<template>
  <div class="app col-sm col-md ">
    <!-- navbar starts -->
    <nav class="navbar navbar-expand-lg py-5">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../images/logo.png" alt="" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item px-3" @click="selectedLinks = 'Home'" :class="{ activelink: selectedLinks === 'Home' }">
              <a class="nav-link  " href="#"> Home</a>
            </li>
            <li class="nav-item px-3" @click="selectedLinks = 'Services'"
              :class="{ activelink: selectedLinks === 'Services' }">
              <a class="nav-link  " href="#"> Services</a>
            </li>
            <li class="nav-item px-3" @click="selectedLinks = 'About us'"
              :class="{ activelink: selectedLinks === 'About us' }">
              <a class="nav-link  " href="#"> About us</a>
            </li>
            <li class="nav-item px-3" @click="selectedLinks = 'Contact'"
              :class="{ activelink: selectedLinks === 'Contact' }">
              <a class="nav-link  " href="#"> Contact</a>
            </li>
            <li v-if="loginSucess1" class="nav-item px-3" @click="selectedLinks = 'Leads'"
              :class="{ activelink: selectedLinks === 'Leads' }">
              <a class="nav-link  " href="#"> Leads</a>
            </li>
            <li v-if="loginSucess1" class="nav-item px-3" @click="selectedLinks = 'User'"
              :class="{ activelink: selectedLinks === 'User' }">
              <a class="nav-link  " href="#"> User</a>
            </li>
          </ul>
          <input id="chooseFile" v-if="loginSucess && this.isSuperAdmin" style="display: none;" type="file"
            @change="handleFileChange" />
          <div style="margin-right:5px">{{ filename }}</div>
          <label for="chooseFile" v-if="loginSucess1 && this.isSuperAdmin" class="file-upload1 gy-4">select file</label>
          <label v-if="uploadToserver" class="file-upload gy-4" @click="uploadFile">Upload</label>
          <a>
            <div v-if="!loginSucess" class="btn gy-4" @click="toggleModal">Register / Login</div>
            <div v-else class="btn gy-4" @click="initLogout"> {{ username }} ( {{ userRoleName }} ) </div>
          </a>
        </div>
      </div>
    </nav>
    <!-- navbar ends -->
    <div v-show="selectedLinks === 'Home'">
      <section>
        <div class="container mt-4">
          <div class="row text-center mt-4">
            <div class="col">
              <div class="head">
                <h1> </h1>
              </div>
            </div>
          </div>

          <div class="row text-center mt-2">
            <div class="col col-md col-sm col-xl">
              <div class="honer">
                <h2>
                  Honor Confidentiality, <br />
                  Trust and Commitment
                </h2>
              </div>
            </div>
          </div>

          <div class="row text-center mt-2">
            <div class="col col-md col-sm col-xl">
              <div class="our">
                <h3>
                  Our business is of trust and ensuring all confidentialities are
                  maintained with utmost care. <br />
                  Zero tolerance for any default is our commitment.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="divider_line align_center">
        <div>
          <img class="me-2" src="../images/left-arrow.png" />
          <img class="mx-2" src="../images/right-arrow.png" />
        </div>
      </div>

      <div class="policy">
        <div class="p1">
          <button>
            <img src="../images/round.png" alt="" />
            <span class="c"><img src="../images/c.png" alt="" /></span><span class="b">Renew Policy</span>
          </button>
        </div>

        <div class="p2">
          <button>
            <img class="p2img" src="../images/round.png" alt="" />
            <span class="gun"><img src="../images/gun.png" alt="" /> </span>
            <span class="t">Track Policy </span>
          </button>
        </div>

        <div class="p3">
          <button>
            <img src="../images/round.png" alt="" />
            <span class="c"><img src="../images/Union.png" alt="" /></span><span class="d">Download Policy</span>
          </button>
        </div>
      </div>

      <!-- Cardview stars -->
      <div class="header-wrapper gy-5">
        <div class="container col-md col-sm col-xl col-xxl col-sm">
          <div class="row gy-5">
            <div class="col col-md-2 col-sm-2">
              <div class="card gy-5">
                <div class="insurance">
                  <img src="../images/umbrella.png" alt="" />
                </div>
                <div class="card-title">
                  <p class="card-text">
                    Term <br />
                    Insurance
                  </p>
                </div>
              </div>
            </div>
            <div class="col col-md-2 col-sm-2">
              <div class="card">
                <div class="insurance">
                  <img src="../images/plus.png" alt="" />
                </div>
                <div class="card-title">
                  <p class="card-text">
                    Health <br />
                    Insurance
                  </p>
                </div>
              </div>
            </div>
            <div class="col col-md-2">
              <div class="card">
                <div class="insurance">
                  <img src="../images/capa.png" alt="" />
                </div>
                <div class="card-title">
                  <p class="card-text">
                    Investment <br />
                    Plans
                  </p>
                </div>
              </div>
            </div>
            <div class="col col-md-2">
              <div class="card">
                <div class="insurance">
                  <img src="../images/car.png" alt="" />
                </div>
                <div class="card-title">
                  <p class="card-text">
                    Car <br />
                    Insurance
                  </p>
                </div>
              </div>
            </div>
            <div class="col col-md-2">
              <div class="card">
                <div class="insurance">
                  <img src="../images/plus.png" alt="" />
                </div>
                <div class="card-title">
                  <p class="card-text">
                    Bike <br />
                    Insurance
                  </p>
                </div>
              </div>
            </div>
            <div class="col col-md-2">
              <div class="card">
                <div class="insurance">
                  <img src="../images/flight.png" alt="" />
                </div>
                <div class="card-title">
                  <p class="card-text">
                    Travel <br />
                    Insurance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Cardview ends-->

      <!-- form stars  -->

      <div class="get_free">
        <div>
          <h1>
            Get a free quote to create your desired <br />
            Financial Plan Advisory
          </h1>
        </div>

        <div class="getimg">
          <img src="../images/getfree.png" alt="" />
        </div>
      </div>

      <div class="full_outter">
        <div class="outter_input">
          <div class="inner_input">
            <div class="right gap-3">
              <input id="male" type="text" placeholder="Male" />
              <input id="female" type="text" placeholder="Female" />
            </div>
            <input id="name" type="text" placeholder="Name" />
          </div>
          <div class="inner_input input2">
            <img src="../images/year.png" class="cal" alt="" />
            <input type="text" id="dob" placeholder="Date of birth (DD/MM/YYYY)" />
            <div class="left">
              <select name="" id="india">
                <option value="">India (+91)</option>
              </select>
              <input type="text" id="mnumber" placeholder="Mobile number" />
            </div>
          </div>
          <div class="inner_input check">
            <div class="t1">
              <input type="checkbox" id="get-det" />
              <label class="cbox1" for="get-det" style="cursor:pointer">Get Details on WhatsApp</label>
            </div>
            <div class="t1">
              <input type="checkbox" name="" id="tobacco" />
              <label class="cbox1" for="tobacco" style="cursor:pointer">I do not smoke or chew tobacco</label>
            </div>
          </div>
        </div>
      </div>

      <div class="view_plans" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <button>View plans for free</button>
      </div>

      <div class="by_click">
        <p>
          By clicking, I agree to
          <span class="terms_design"> Terms & conditions</span> and
          <span class="terms_design"> Privacy policy</span>
        </p>
      </div>

      <!-- form ends -->

      <!-- form Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="
          max-width: 70%;
          max-height: auto;
          border-radius: 10px;
          border: 4px solid #c7a84b;
          background: #f2f2f2;
        ">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" style="color: #c7a84b" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="get_free" style="margin-top: -15px">
                <div>
                  <h1>
                    Get a free quote to create your desired <br />
                    Financial Plan Advisory
                  </h1>
                </div>

                <div class="getimg">
                  <img src="../images/getfree.png" alt="" />
                </div>
              </div>

              <div class="full_outter">
                <div class="outter_input">
                  <div class="inner_input">
                    <div class="right gap-3">
                      <input id="male" type="text" placeholder="Male" />
                      <input id="female" type="text" placeholder="Female" />
                    </div>
                    <input id="name" type="text" placeholder="Name" />
                  </div>
                  <div class="inner_input input___2">
                    <img src="../images/year.png" class="cal" alt="" />
                    <input type="text" id="dob" placeholder="Date of birth (DD/MM/YYYY)" />
                    <div class="left">
                      <select name="" id="india">
                        <option value="">India (+91)</option>
                      </select>
                      <input type="text" id="mnumber" placeholder="Mobile number" />
                    </div>
                  </div>
                  <div class="inner_input check">
                    <div class="t1">
                      <input type="checkbox" id="gd" checked /><span class="cbox1">Get Details on WhatsApp</span>
                    </div>
                    <div class="t1">
                      <input type="checkbox" name="" id="tobacco" checked /><span class="cbox1">I do not smoke or chew
                        tobacco</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="view_plans" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                <button>View plans for free</button>
              </div>

              <div class="by_click">
                <p>
                  By clicking, I agree to
                  <span class="terms_design"> Terms & conditions</span> and
                  <span class="terms_design"> Privacy policy</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- smoke Modal -->

      <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width:60%">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                  <path
                    d="M17 9H18V7H17V9ZM0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM17 7H1V9H17V7Z"
                    fill="#C7A84B" />
                </svg>
              </h1>
              <button type="button" style="color: #c7a84b" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="select-rounds">
                <div class="first-select">
                  <h1>1</h1>
                </div>

                <div class="mid-line"></div>

                <div class="second-select">
                  <h1>2</h1>
                </div>

                <div class="mid-line"></div>

                <div class="second-select">
                  <h1>3</h1>
                </div>

                <div class="mid-line"></div>

                <div class="second-select">
                  <h1>4</h1>
                </div>
              </div>

              <div class="yes-image-last">
                <img src="../images/yes2.png" alt="" />
              </div>

              <div class="do-you-smoke">
                <h1>Do you smoke or chew Tobacco ?</h1>
              </div>

              <div class="select-yes">
                <h4>
                  Select Yes if you have smoked or chewed tobacco in last 12 months
                </h4>
              </div>

              <div class="yes-no-btn">
                <button class="yes-btn">No</button>
                <button class="no-btn"><router-link to="/plans1" class="yes-txt">Yes</router-link></button>
              </div>

              <div class="arrow-img">
                <img src="../images/arrow.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- Trused starts -->
      <div class="trusted">
        <h1>
          Trusted Advisor and <br />
          Wealth Management Solutions Provider
        </h1>
      </div>

      <div class="narayana">
        <div class="row">
          <h1>
            Narayan Insurance Brokers is backed by over 200 years of cumulative
            expertise and experience that are the company’s drivers of success.
            <br />
            50+ awards we have won is fitting complement to the team's vast and
            relevant experience. Read more
          </h1>
        </div>
      </div>

      <div class="youtube_part">
        <span><img src="../images/h.png" alt="" /></span>
        <div class="youtube_img">
          <div class="img1">
            <img src="../images/you1.png" alt="" />
          </div>
          <div class="img2">
            <img src="../images/you2.png" alt="" />
          </div>
        </div>
        <div class="box">
          <div>
            <div class="satisfied">
              <h1>4.2k</h1>
              <h2>Satisfied Clients</h2>
            </div>
            <div class="year_exp">
              <h1>200k+</h1>
              <h2>Partners Worldwide</h2>
            </div>
          </div>
          <div>
            <div class="satisfied">
              <h1>50+</h1>
              <h2>Awards Won</h2>
            </div>
            <div class="satisfied">
              <h1>10+</h1>
              <h2>Owned Brands</h2>
            </div>
          </div>
          <div>
            <div class="satisfied">
              <h1 class="">200+</h1>
              <h2>Years Experience</h2>
            </div>
            <!-- <span class="dot"><img src="./images/dot.png" alt=""></span>
            <span class="yes2"><img src="./images/yes2.png" alt=""></span>  -->
          </div>
        </div>
        <div></div>
      </div>

      <div class="carousel">
        <span>
          <img src="../images/left.png" alt="" />
        </span>
        <div class="inner_carousel">
          <div class="boxs">
            <img src="../images/img1.png" alt="" />
            <h1>Life Insurance</h1>
            <p>
              We at Narayan Insurance Brokers hold the portfolio and services ...
              <span class="more_"><a href="">Read more</a></span>
            </p>
          </div>
          <div class="boxs">
            <img src="../images/img2.png" alt="" />
            <h1>Health Insurance</h1>
            <p>
              We at Narayan Insurance Brokers hold the portfolio and services ...
              <span><a href=""> Read more</a></span>
            </p>
          </div>
          <div class="boxs">
            <img src="../images/img3.png" alt="" />
            <h1>General Insurance</h1>
            <p>
              We at Narayan Insurance Brokers hold the portfolio and services ...
              <span><a href="">Read more</a></span>
            </p>
          </div>
        </div>
        <span>
          <img src="../images/right.png" alt="" />
        </span>
      </div>

      <div class="why">
        <h1>
          Why you should choose us to invest your money <br />
          in mutual funds
        </h1>
      </div>

      <div class="we_clearly1">
        <div class="we_clearly2">
          <h1>We understand your need</h1>

          <p>
            We clearly understand our client’s needs and strive hard to customize
            solutions for them that <br />
            suit their requirements and needs.
          </p>
        </div>
      </div>
      <div class="clients_focused1">
        <div class="clients_focused2">
          <h1>Clients focused</h1>

          <p>Our priority is to grow your wealth by leaps and bounds.</p>
        </div>
      </div>
      <div class="we_are1">
        <div class="we_are2">
          <h1>We are professionals</h1>

          <p>
            Our holistic approach and professional way of decision making makes
            our clients coming back to <br />
            us time and again.
          </p>
        </div>
      </div>

    </div>
    <div v-show="selectedLinks === 'Services'">
      <Plans1 />
    </div>
    <div v-if="loginSucess1" v-show="selectedLinks === 'Leads'">
      <Leads />
    </div>
    <div v-if="loginSucess1" v-show="selectedLinks === 'User'">
      <Users/>
    </div>

    <div class="footer_1">
      <div class="footer_head">
        <a><router-link to="/">Careers</router-link> </a>
        <a><router-link to="/"> Disclosures</router-link> </a>
        <a><router-link to="/privacy">Privacy Policy</router-link> </a>
        <a><router-link to="/terms">Terms</router-link> </a>

      </div>
    </div>

    <div class="footerline_1"></div>
    <div class="footerline_2"></div>

    <div class="copy_rights">
      <!-- <div>
        <h1>© 2021 Narayan Insurance Brokers.</h1>
      </div> -->
      <div class="all">
        <h1>All Rights Reserved.</h1>
      </div>
    </div>

    <div class="address">
      <!-- <h1>East Patel Nagar, New Delhi</h1> -->
      <a href="">hello@narayanwealth.com</a>
    </div>

  </div>

  <div v-if="showModal">
    <Login @close="toggleModal" />
  </div>
  <div>
    <loading-dialog :showLoading=this.showUploadingDialog />
  </div>
  <div>
    <toast :show=this.showToast :message=this.toastMessage />
  </div>
</template>
 
<script>
import Plans1 from './Plans1.vue';
import Login from './Login.vue';
import Leads from './Leads.vue';
import axios from 'axios';
import { UPLOAD_TX_URL, PARTNER_ID, GET_USER_ROLES, GET_CORPORATE_DATA } from '../util/constants';
import { getSession, setRoleInfoToSession, setCorporateInfoToSession, getUserName, getRoleName, 
  isAuthenticated, getWelthUserId, clearSessionData } from '../util/loginData';
import LoadingDialog from './LoadingDialog.vue';
import toast from './Toast.vue';
import Users from './Users.vue';

export default {
  name: "Narayan",

  components: { Plans1, Login, LoadingDialog, toast, Leads, Users },

  data() {
    return {
      links: ['Home', 'Services', 'About us', 'Contact', 'Leads','User'],
      selectedLinks: 'Home',
      showModal: false,
      loginSucess: isAuthenticated(),
      loginSucess1: isAuthenticated(),
      username: getUserName(),
      userRoleName: "NA",
      isSuperAdmin: false,
      sessionData: getSession(),
      showUploadingDialog: false,
      showToast: false,
      toastMessage: '',
      uploadToserver: false,
      filename: '',
    }
  },

  mounted() {
    console.log("Mounted Narayan");
    // console.log(getSession());
    this.loadRolesInfo();
    this.loadCorporateInfo();
    if (this.loginSucess) {
      this.userRoleName = getRoleName(getSession().role);
      this.isSuperAdmin = getSession().role === 4;
    }
    console.log("Mount isSuperAdmin: " + this.isSuperAdmin);
  },

  created() {
    this.selectedLinks = localStorage.getItem('lastVisitedSection') || 'Home';
  },
  watch: {
    selectedLinks(newSection) {
      localStorage.setItem('lastVisitedSection', newSection);
    }
  },

  methods: {
    async loadRolesInfo() {
      // console.log("loadRolesInfo called ");
      const response = await axios.get(GET_USER_ROLES, {});
      // console.log(response.data.data);
      if (response.data.code == 200) {
        setRoleInfoToSession(response.data.data);
      }
    },

    async loadCorporateInfo() {
      // console.log("loadRolesInfo called ");
      const response = await axios.get(GET_CORPORATE_DATA, {});
      // console.log(response.data.data);
      if (response.data.code == 200) {
        setCorporateInfoToSession(response.data.data);
      }
    },

    startLoading() {
      this.showUploadingDialog = true;
      console.log('startLoading:', this.showUploadingDialog);
    },

    stopLoading() {
      this.showUploadingDialog = false;
      console.log('stopLoading:', this.showUploadingDialog);
    },

    showToastMessage(message) {
      if (message != '') {
        this.toastMessage = message;
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
          this.toastMessage = '';
        }, 3000);
      }
      console.log('showToastMessage message:', message);
    },

    toggleModal() {
      this.showModal = !this.showModal;
      this.loginSucess = isAuthenticated();
      this.loginSucess1 = isAuthenticated();
      this.username = getUserName();
      if (this.loginSucess) {
        this.userRoleName = getRoleName(getSession().role);
        this.isSuperAdmin = getSession().role === 4;
      }
      console.log("Login isSuperAdmin: " + this.isSuperAdmin);
      // console.log('toggleModal sessionData:', this.sessionData);
      // console.log('toggleModal showModal:', this.showModal);
      // console.log('toggleModal loginSucess:', this.loginSucess);
      // console.log('toggleModal username:', this.username);
    },

    handleFileChange(event) {
      console.log('handleFileChange event:', event);
      if (event == null) {
        this.selectedFile.click;
        this.selectedFile = null;
      } else {
        console.log('handleFileChange file:', event.target.files[0]);
        this.selectedFile = event.target.files[0];
        this.filename = this.selectedFile.name;
        this.uploadToserver = true;
        this.loginSucess1 = false;
      }
    },

    uploadFile() {
      if (this.selectedFile != null) {
        this.startLoading();
        const formData = new FormData();
        formData.append('transaction_file', this.selectedFile);
        axios.post(UPLOAD_TX_URL + PARTNER_ID, formData).then(response => {
          this.stopLoading();
          console.log('File uploaded successfully:', response.data);
          this.handleFileChange(null);
          this.showToastMessage('File uploaded successfully.');
        }).catch(error => {
          console.error('Error uploading file:', error);
          this.stopLoading();
          this.showToastMessage('File upload failed.');
          this.handleFileChange(null);
        });
      } else {
        this.showToastMessage('Please select file first.');
      }
    },

    initLogout() {
      const userConfirmed = window.confirm('Do you want to logout?');
      if (userConfirmed) {
        // console.log('confirmed.');
        clearSessionData();
        this.loginSucess = isAuthenticated();
        this.username = getUserName();
        this.loginSucess1 = isAuthenticated();
        this.$store.dispatch('logout');
        // Redirect to the default route after logout
        setTimeout(() => {
          console.log("reload after logout");
          this.$router.push({ name: 'home', forceReload: true });
          window.location.reload(true);
        }, 500);
      } else {
        console.log('canceled.');
      }
    },
    updateSelectedLinks(section) {
      this.selectedLinks = section;
    }
  }
};
</script>
 
<style >
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

body {
  background: #f2f2f2;
  width: 100%;
}

.app {
  font-size: 14px;
  background-color: #f2f2f2;
  background: url("../images/head_m1.png");
  background-repeat: no-repeat;
  background-position: center top;
}

/* Navbar start */

a {
  text-decoration: none;
  outline: none;
  border: none;
}

.file-upload {
  width: 100px;
  height: 48px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
  padding-top: 10px;
  padding-left: 18px;
  color: #f2f2f2;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.file-upload1 {
  width: 100px;
  height: 48px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
  padding-top: 10px;
  padding-left: 12px;
  color: #f2f2f2;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.btn {
  width: 300px;
  height: 48px;

  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
  padding-top: 12px;
  color: #f2f2f2;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn:hover {
  width: 300px;
  height: 48px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #c7a84b;
  padding-top: 12px;
  color: #f2f2f2;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.navbar-brand img {
  width: 104px;
  height: 104px;
  flex-shrink: 0;
}

.nav-item a {
  color: #263ba0;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.activelink {
  font-size: 20px;
  font-weight: 800;

  border-bottom: 3px solid #c7a84b;
}

/* Navbar ends */

/* About Stats */
.head {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  flex-shrink: 0;
}

.head h1 {
  color: #263ba0;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 1.28px;
}

.honer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.honer h2 {
  color: #c7a84b;
  text-align: center;
  font-family: Raleway;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px;
}

.our {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.our h3 {
  color: #263ba0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
}

/* About ends */

/* divider line starts */

.divider_line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider_line {
  background: url("../images/line_img.png");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 20px;
}

/* divider line starts */

/* policy  stars*/

.policy {
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
  display: flex;
  margin-top: 50px;
  width: 100%;
  gap: 20px;
}

.p1 button {
  width: 149px;
  height: 42px;
  flex-shrink: 0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
}

.p2 button {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 42px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
}

.p3 button {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 171px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
}

.c img {
  position: relative;
  right: 21px;
}

.b {
  position: relative;
  right: 10px;
}

.d {
  position: relative;
  right: 6px;
}

.p2img {
  position: relative;
  right: -5px;
}

.gun {
  position: relative;
  right: 15px;
}

.t {
  position: relative;
  right: 11px;
}

@media (max-width: 608px) {
  .head h1 {
    font-size: 14px;
  }

  .honer h2 {
    font-size: 40px;
    width: 100%;
  }

  .policy {
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
    display: flex;
    margin-top: 40px;
    width: 100%;
    gap: 20px;
    flex-direction: column;
  }

  @media (max-width: 328px) {
    .app {
      background: rgba(242, 242, 242, 1);
      width: 100%;
    }

    .honer h2 {
      font-size: 20px;
      width: 100%;
    }
  }
}

/*policy ends*/

/* Card view stars */

.header-wrapper {
  background: rgba(242, 242, 242, 1);
  margin-top: 60px;
}

.insurance {
  width: 107px;
  height: 107px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border: 1px solid #c7a84b;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card img {
  margin-top: 22px;
}

.card {
  background: rgba(242, 242, 242, 1);
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
}

.card p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #c7a84b;
  margin-top: 20px;
}

/* Card view ends */

/*form stars*/

.get_free {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 80px;
}

.get_free {
  color: #c7a84b;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
}

.full_outter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

#male {
  width: 191.04px;
  height: 48px;
  background: #c7a84b;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  padding-left: 22px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
}

#female {
  width: 202.791px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-left: 10px;
  padding-left: 20px;
  font-weight: 500;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);

  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
}

#name {
  width: 532.94px;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  margin-left: 30px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.04em;
  color: #9598a7;
}

#dob {
  width: 406.56px;
  height: 48px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  letter-spacing: 0.04em;
  color: #9598a7;
  padding-left: 20px;
}

#india {
  width: 151.85px;
  height: 48px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  -webkit-appearance: none;
  appearance: none;
  letter-spacing: 0.04em;
  color: #9598a7;
}

#mnumber {
  width: 364.44px;
  height: 48px;
  padding-left: 20px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-left: 10px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

.input2 {
  padding-right: 28px;
}

.inner_input {
  display: flex;

  flex-wrap: wrap;
  margin: 10px;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.input___2 {
  padding-right: 20px;
}

.inner_input .cal {
  position: relative;
  left: 390px;
  top: 6px;
}

.inner_input .left {
  margin-left: 30px;
}

.cbox1 {
  color: #263ba0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
  margin-left: 10px;
}

a {
  text-decoration: none;
}

.check input {
  margin-left: 10px;
}

.check input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 24px;
  border-radius: 4px;
  width: 24px;
  background: rgba(199, 168, 75, 1);
  cursor: pointer;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
}

.check input[type="checkbox"]:after {
  font-family: "font Awesome 6 free";
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  display: none;
}

.check input[type="checkbox"]:checked {
  background: rgba(199, 168, 75, 1);
}

.check input[type="checkbox"]:checked:after {
  display: block;
}

.inner_input.check {
  justify-content: flex-start;
  margin-left: 35px;
}

.ml {
  margin-left: 10px;
}

.right input,
.left input,
#name,
#dob {
  margin-top: 10px;
}

.t1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 608px) {
  body {
    width: 608px;
  }

  input {
    max-width: 608px;
  }

  .right input {
    margin-left: 104px;
  }

  .inner_input {
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #dob {
    width: 532.94px;
    margin-left: 30px;
  }

  .check {
    margin-top: 20px;
  }

  .t1 {
    margin-left: 20px;
  }

  .full_outter {}

  .modal-dialog {
    max-width: 1000%;
    max-height: 1000%;
  }

  .modal {
    max-width: 100%;
    padding: 20px;
    max-height: 100%;
  }

  .inner_input.check {
    margin-left: 220px;
  }

  .inner_input .left {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .right input {
    margin-left: 104px;
  }

  .inner_input {
    margin-top: -10px;
  }

  #dob {
    width: 532.94px;
    margin-left: 30px;
  }

  .check {
    margin-top: 20px;
  }

  .t1 {
    margin-left: 20px;
  }

  .inner_input.check {
    margin-left: 250px;
  }

  .full_outter {
    /*   margin-left: 200px;*/
  }

  .inner_input .left {
    margin-left: 50px;
  }
}

.view_plans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.view_plans button {
  width: 208px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
  color: #f2f2f2;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.by_click {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.by_click p {
  color: #263ba0;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.48px;
}

.terms_design {
  color: rgba(199, 168, 75, 1);
  border-bottom: 1px solid rgba(199, 168, 75, 1);
}

/* smoke stats */


.modal-dialog {
  max-height: auto;
  border-radius: 14px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  max-width: 60%;
}

.select-rounds {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 65px; */
}

.first-select {
  height: 50px;
  width: 50px;
  background: #c7a84b;
  text-align: center;
  border-radius: 50%;
}

.first-select h1 {
  color: #fff;
  text-align: center;
  /* font-family: Raleway;
    font-style: normal; */
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
}

.second-select {
  height: 50px;
  width: 50px;
  border: 1px solid #c7a84b;
  text-align: center;
  border-radius: 50%;
  background: #fff;
}

.second-select h1 {
  color: #c7a84b;
  text-align: center;
  /* font-family: Raleway;
    font-style: normal; */
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
}

.second-select:hover {
  height: 50px;
  width: 50px;
  background: #c7a84b;
  text-align: center;
  border-radius: 50%;
}

.second-select h1:hover {
  color: #fff;
  text-align: center;
  /* font-family: Raleway;
    font-style: normal; */
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
}

.mid-line {
  border-bottom: 2px solid #c7a84b;
  width: 52px;
  margin: 10px;
}

.yes-image-last {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.yes-image-last img {
  width: 67px;
  height: 56px;
  flex-shrink: 0;
}

.do-you-smoke {
  display: flex;
  justify-content: center;
  align-items: center;
}

.do-you-smoke h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  /* 100% */
  letter-spacing: 0.96px;
}

.select-yes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-yes h4 {
  color: #263ba0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
}

.yes-no-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 30px;
  flex-wrap: wrap;
}

.yes-btn {
  width: 107px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  color: #3b53cb;
  text-align: center;
  /* font-family: Raleway; */
  font-size: 16px;
  /* font-style: normal; */
  font-weight: 700;
  line-height: normal;
}

.no-btn {
  width: 107px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
}

.yes-txt {
  color: #f2f2f2;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.arrow-img img {
  width: 61.99px;
  height: 42.08px;
  flex-shrink: 0;
  margin-left: 80px;
}

@media screen and (max-width: 700px) {
  .modal {
    max-width: 100%;
    padding: 20px;
  }

  .modal-dialog {
    max-width: 1000%;
  }

  .select-rounds {
    width: 100%;
  }

  .yes-image-last {
    margin: 10px;
  }

  .yes-no-btn {
    gap: 20px;
  }

  .arrow-img {
    margin: 20px;
  }
}


/* smoke ends */


/* Trusted stats */

.trusted {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.trusted h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}

.narayana {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.narayana h1 {
  color: #263ba0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
}

/* youtube stars */

.youtube_part {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 30px;
}

.youtube_img {
  display: flex;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  width: 501px;
  height: 315px;
  flex-shrink: 0;
}

.img1 img {
  border-radius: 32px 0px 0px 32px;
}

.img2 img {
  border-radius: 0px 32px 32px 0px;
}

.youtube_part {
  flex-wrap: wrap;
}

.satisfied {
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

@media (max-width: 510px) {
  .youtube_img {
    display: flex;
    border-radius: 32px;
    border: 2px solid #c7a84b;
    flex-shrink: 0;
  }

  .satisfied {
    width: 168.504px;
    height: 138.065px;
    flex-shrink: 0;
    border-radius: 32px;
    border: 1px solid #c7a84b;
    background: #f2f2f2;
    margin-top: 30px;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  }

  .satisfied h1 {
    display: flex;
    width: 102px;
    height: 69px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #c7a84b;
    text-align: center;
    font-size: 48px;
    padding-left: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  .satisfied h2 {
    display: flex;
    width: 79px;
    height: 36px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #263ba0;
    text-align: center;
    font-size: 18px;
    padding-left: 49px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.72px;
  }

  .box {
    display: flex;
    gap: 30px;
  }

  .dot {
    position: relative;
    left: 420px;
    bottom: 60px;
  }

  .yes2 {
    position: relative;
    left: 500px;
    bottom: 150px;
  }
}

.satisfied {
  width: 168.504px;
  height: 138.065px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #c7a84b;
  background: #f2f2f2;
  margin-top: 30px;
}

.satisfied h1 {
  display: flex;
  width: 102px;
  height: 69px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #c7a84b;
  text-align: center;
  font-size: 48px;
  padding-left: 40px;
  font-weight: 700;
  line-height: 48px;
}

.satisfied h2 {
  display: flex;
  width: 79px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #263ba0;
  text-align: center;
  font-size: 18px;
  padding-left: 49px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
}

.year_exp {
  width: 168.504px;
  height: 138.065px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #c7a84b;
  background: #f2f2f2;
  margin-top: 30px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

.year_exp h1 {
  display: flex;
  width: 102px;
  height: 69px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #c7a84b;
  text-align: center;
  font-size: 48px;
  padding-left: 20px;
  font-weight: 700;
  line-height: 48px;
}

.year_exp h2 {
  display: flex;
  width: 79px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #263ba0;
  text-align: center;
  font-size: 18px;
  padding-left: 28px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
}

.box {
  display: flex;
  gap: 30px;
}

.dot {
  position: relative;
  left: 420px;
  bottom: 60px;
}

.yes2 {
  position: relative;
  left: 500px;
  bottom: 150px;
}

/* youtube part ends*/

.we {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 40px;
}

.we h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  /* line-height: 48px; */
}

/* Trusted stats */

/* carosel starts */

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 60px;
  background: rgba(242, 242, 242, 1);
}

.inner_carousel {
  display: flex;
  justify-content: space-evenly;
  gap: 60px;
}

.boxs {
  width: 250px;
  height: 359px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #c7a84b;
}

.boxs img {
  width: 100%;
  border-radius: 32px;
}

.boxs h1 {
  color: #c7a84b;
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  padding: 0px 20px;
}

.boxs p {
  color: #263ba0;
  font-size: 12px;

  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.48px;
  padding: 0px 20px;
}

@media (max-width: 1065px) {
  body {
    width: 1065px;
  }

  .carousel {
    width: 1065px;
  }
}

/* carosel ends */

/* Why starts */

.why {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.why h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

.we_clearly1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.we_clearly2 {
  width: 861px;
  height: 117px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #c7a84b;
}

.we_clearly1 h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  position: relative;
  top: 10px;
}

.we_clearly1 p {
  color: #263ba0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
}

.clients_focused1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 7px;
}

.clients_focused2 {
  width: 861px;
  height: 117px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #c7a84b;
}

.clients_focused1 h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  position: relative;
  top: 10px;
}

.clients_focused1 p {
  color: #263ba0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
}

.we_are1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 15px;
}

.we_are2 {
  width: 861px;
  height: 117px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #c7a84b;
}

.we_are1 h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  position: relative;
  top: 10px;
}

.we_are1 p {
  color: #263ba0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
}

@media (max-width: 878px) {
  .trusted h1 {
    font-size: 25px;
    line-height: 40px;
  }

  .why h1 {
    font-size: 25px;
    line-height: 40px;
  }

  .we h1 {
    font-size: 25px;
    line-height: 40px;
  }

  .we_clearly2 {
    width: 90%;
  }

  .we_clearly1 p {
    font-size: 12px;

    line-height: 12px;
  }

  .clients_focused2 {
    width: 90%;
  }

  .clients_focused1 p {
    font-size: 12px;

    line-height: 12px;
  }

  .we_are2 {
    width: 90%;
  }

  .we_are1 p {
    font-size: 12px;

    line-height: 12px;
  }
}

/* Why ends */

/* footer stats */

.footer_1 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 100px;
}

.footer_head a {

  display: inline-block;
  padding: 15px;
}

.footer_1 a {
  color: #3b53cb;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  line-height: 48px;
}

.footerline_1 {
  border-bottom: 4px solid #c7a84b;
}

.footerline_2 {
  margin-top: 1px;
  border-bottom: 4px solid #3b53cb;
}

@media (max-width: 449px) {
  .footer_head a {
    display: inline-block;
    padding: 2px;
    font-size: 13px;
  }
}

.dot {
  width: 30px;
}

.copy_rights {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.copy_rights h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
}

.all {
  display: flex;
  justify-content: center;
}

@media (max-width: 626px) {
  .copy_rights h1 {
    padding-top: 10px;
    font-size: 14px;
    line-height: 15px;
  }

  .all {
    display: flex;
    justify-content: center;
  }
}

.address {
  display: flex;
  justify-content: center;
}

.address h1 {
  color: #273786;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.12px;
  padding: 10px;
}

.address a {
  color: rgba(199, 168, 75, 1);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1.12px;
  padding: 10px;
}

@media (max-width: 523px) {
  .address h1 {
    font-size: 12px;
  }

  .address a {
    font-size: 12px;
  }
}

@media (max-width: 476px) {
  .address h1 {
    font-size: 10px;
  }

  .address a {
    font-size: 10px;
  }
}

/* footer ends */
</style>