<template>
  <div class="leadTeams table-responsive">
    <table class="table tab-info align-middle">
      <thead style="text-align: center">
        <tr class="table-primary">
          <th class="col-2">Name</th>
          <th class="col-1">Role</th>
          <th class="col-2">Phone Number</th>
          <th class="col-1">Added Date</th>
          <th class="col-2">Added by</th>
          <th class="col-1">Edit</th>
          <th class="col-1">Delete</th>
        </tr>
      </thead>
      <tbody class="center-align" style="text-align: center">
        <tr v-for="(employee, index) in employeeData" :key="index">
          <th>{{ employee.user_name }}</th>
          <th> {{ getRoleName(employee.role) }} </th>
          <th class="font-style">{{ employee.user_phoneno }}</th>
          <th class="font-style">{{ formatDate(employee.created_at) }}</th>
          <th>{{ employee.created_by_info ? employee.created_by_info.user_name : "" }}</th>
          <th @click="callEdit(employee)" v-html="changeRole"></th>
          <th>
            <div v-html="dltIcon"></div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { dltIcon, changeRole } from "@/assets/svg";
import axios from 'axios';
import { GET_CITY_INFO, GET_STATES_INFO, GET_USER_ROLES, } from '../util/constants';
import { getRoleName } from "@/util/loginData";

export default {
  props: ["employeeData",],
  data() {
    return {
      dltIcon: dltIcon,
      changeRole: changeRole,
    };
  },

  mounted() {
    // console.log("employeeData:", this.employeeData);
  },

  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },

    getRoleName(relationshipId) {
      return getRoleName(relationshipId)
    },

    callEdit(e) {
      this.$emit("edit_emplyoee", e)
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.font-style {
  font-family: "Alte Haas Grotesk", sans-serif;
}

.leadTeams {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  flex-direction: column;
  background: #fff;
  width: 80%;
  margin-left: 12%;
  border-radius: 25px;
  padding-top: 40px;
}

.tab-info {
  width: 95%;
}

.table-header {
  color: #3b53cb;
}

.table_data {
  position: relative;
  left: 20px;
}
</style>