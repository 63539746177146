<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="box1-f3">
        <h1 class="title">Transaction Details</h1>
        <div class="close-icon" @click="closePopup">
          <img src="../images/close-popup.png" alt="" />
        </div>
      </div>
      <div style="overflow-y: auto; height:550px">
        <!-- <form @submit="submitForm"> -->
        <input type="text" placeholder="Transaction Id" v-model="txId" />
        <input type="text" placeholder="Policy Holder Name" v-model="policyHolderName" />
        <div class="date-info">
          <div style="padding-top:11px"> Date of Birth</div>
          <input type="date" v-model="dob" style="width:330px" :max="maxDOBVal"   :style="{color:dob ? '#263ba0' : ''}"/>
        </div>
        <input type="text" placeholder="Policy Name" v-model="policyName" />
        <select name="" id="" v-model="selectedType"   >
          <option v-for="(info, index) in insType" :key="index"  >{{ info.value }}</option>
        </select>
        <select name="" id="" v-model="selectedCategory" @change="filterBrandData">
          <option v-for="(info, index) in insCategory" :key="index">{{ info.value }}</option>
        </select>
        <select name="" id="" v-model="selectedBrand">
          <option v-for="(info, index) in selectedBrandData" :key="index">{{ info.value }}</option>
        </select>
        <input type="text" placeholder=" ₹ Total Payment " v-model="amount" />
        <div v-if="showAdditionalFields">
          <input  type="text" placeholder=" ₹ OD Amount" v-model="odAmount" />
          <input type="text" placeholder=" ₹  TP Amount" v-model="tpAmount" />
        </div>
          <input type="text" placeholder=" ₹  GST Amount" v-model="SGSTAmount" />
          <input type="text" placeholder=" ₹  CGST Amount" v-model="CGSTAmount" />
          <input type="text" placeholder=" ₹  IGST Amount" v-model="IGSTAmount" />
    
        <div class="date-info">
          <div style="padding-top:11px"> Payment Date</div>
          <input type="date" v-model="payDate" style="width:330px" :max="maxDateVal" :style="{color:payDate ? '#263ba0' : ''}"/>
        </div>
    
        <!-- <input type="text" placeholder="Kyc Token"  /> -->
        <input type="file" id="uploadFile" style="display:none" />
        <label for="uploadFile" style="display:none">upload Transaction Proof</label>
        <div class="error"> {{ validationMessage }} </div>
        <div class="save">
          <button id="saveBtn" class="save-btn" @click="addTransaction">
            <span>Save Transaction</span>
            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" id="addBtnSpinner"
              style="display: none"></span>
          </button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { GET_BRAND_DATA, CREATE_NEW_TRANSACTION, UPLOAD_TX_IMAGE } from '@/util/constants';
import { getWelthUserId } from '@/util/loginData';
import { loadInsCategory, loadBrandData, loadInsType } from '../util/commonFunctions'
import axios from 'axios';

export default {
  props: ['selectedLeadForTx'],

  data() {
    return {
      txId: "",
      payDate: null,
      dob: null,
      policyHolderName: "",
      policyName: "",
      policyType: -1,
      policyCategory: -1,
      brandName: "",
      amount: "",
      odAmount : "",
      tpAmount : "",
      SGSTAmount : "",
      CGSTAmount : "",
      IGSTAmount : "",
      
      allBrandData: [],
      selectedBrandData: [],
      selectedBrand: "Brand Name",
      insType: [],
      selectedType: "New / Renew",
      insCategory: [],
      selectedCategory: "Policy Type",
      validationMessage: "",
      maxDateVal: null,
      maxDOBVal: null,
      showAdditionalFields: false ,
      odAmount: "",  
      tpAmount: "",
      GST:"",
      CGST:"",
      IGST:"",
    };
  },

  mounted() {
    console.log("Lead data for transaction: ", this.selectedLeadForTx);
    console.log("Current user id: " + getWelthUserId());
    this.getCategory();
    this.getInsType();
    this.getBrandData();
    this.maxDate();
    this.maxDOBDate();
   
  },

  methods: {
    maxDate() {
      console.log("maxDate");
      const today = new Date();
      const year = today.getFullYear();
      const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Months are zero-indexed
      const day = `${today.getDate()}`.padStart(2, '0');
      this.maxDateVal = `${year}-${month}-${day}`;
      // console.log(this.minDateVal);
      return this.maxDateVal;
    },

    maxDOBDate() {
      console.log("maxDOBDate");
      const today = new Date();
      const year = today.getFullYear();
      // Set the maximum date of birth to be 16 years ago...
      today.setFullYear(year - 16);
      const maxYear = today.getFullYear();
      const maxMonth = `${today.getMonth() + 1}`.padStart(2, '0');
      const maxDay = `${today.getDate()}`.padStart(2, '0');
      this.maxDOBVal = `${maxYear}-${maxMonth}-${maxDay}`;
      console.log(this.maxDOBVal);
      return this.maxDOBVal;
    },

    filterBrandData() {
      console.log("filterBrandData: selectedCategory: " + this.selectedCategory);
      var category = this.getIdForValue(this.insCategory, this.selectedCategory);
      console.log(category);
      if (category == 0) {
        this.selectedBrandData = this.allBrandData;
      } else {
        let filteredData = [];
        filteredData.unshift({
          id: 0,
          categoty_id: 0,
          value: "Brand Name",
        });
        this.allBrandData.forEach(data => {
          if (data.categoty_id == category) {
            filteredData.unshift(data);
          }
        })
        if (filteredData.length > 0) {
          this.selectedBrandData = [];
          for (let i = 0; i < filteredData.length; i++) {
            this.selectedBrandData.unshift(filteredData[i]);
          }
        }
      }
      // console.log("filterBrandData: selectedBrandData size: ", this.selectedBrandData);
      this.selectedBrand = "Brand Name";
    },

    closePopup() {
      this.closeModal(false, 0, null);
    },

    closeModal(isTxAdded, order_id, order_details) {
      console.log("close add tx: isTxAdded: " + isTxAdded + " orderId: " + order_id);
      this.$emit("close", isTxAdded, order_id, order_details);
    },

    async getInsType() {
      this.insType = await loadInsType();
    },

    async getCategory() {
      this.insCategory = await loadInsCategory();
    },

    async getBrandData() {
      // console.log("getBrandData");
      this.allBrandData = await loadBrandData();
      this.filterBrandData();
      console.log(this.allBrandData);
    },

    async addTransaction() {
      console.log("Add tx called...");
      let validation = true;
      if (validation && this.txId == "") {
        this.validationMessage = "Please enter transaction number";
        validation = false;
      }
      if (validation && this.policyHolderName == "") {
        this.validationMessage = "Please enter policy holder name";
        validation = false;
      }
      // if (validation && this.dob == null) {
      // this.validationMessage = "Please select date of birth";
      // validation = false;
      // }
      if (validation && this.policyName == "") {
        this.validationMessage = "Please enter policy name";
        validation = false;
      }
      var type = this.getIdForValue(this.insType, this.selectedType);
      if (validation && type == 0) {
        this.validationMessage = "Please select New / Renew type";
        validation = false;
      }
      var category = this.getIdForValue(this.insCategory, this.selectedCategory);
      if (validation && category == 0) {
        this.validationMessage = "Please select policy type";
        validation = false;
      }
      var brand = this.getIdForValue(this.allBrandData, this.selectedBrand);
      if (validation && brand == 0) {
        this.validationMessage = "Please select brand name";
        validation = false;
      }
      if (validation && this.payDate == null) {
        this.validationMessage = "Please enter payment date";
        validation = false;
      }
      if (validation && this.amount == "") {
        this.validationMessage = "Please enter amount";
        validation = false;
      }
      if (!this.selectedLeadForTx || !this.selectedLeadForTx.lead_user_info) {
        this.validationMessage = "Invalid lead data";
        validation = false;
      }
 
      if (! this.odAmount ) { 
        // alert("this.odAmount")
        this.odAmount = 0;
      }
      if (! this.tpAmount ) {
        this.tpAmount = 0;
      }

      if (! this.CGSTAmount ) {
        this.CGSTAmount = 0;
      } 
      if (! this.SGSTAmount ) {
         this.SGSTAmount = 0;
      }
      if (! this.IGSTAmount ) {
        this.IGSTAmount = 0;
      } 
        
      
      if (validation) {
        $("#saveBtn").attr('disabled', 'disabled');
        $("#addBtnSpinner").show();
        let txData = {
          "orderType": type,
          "transactionId": this.txId,
          "policyHolderName": this.policyHolderName,
          "dob": this.dob,
          "paymentAmount": this.amount,
          "paymentDate": this.payDate,
          "insuranceCategory": category,
          "productName": this.policyName,
          "insurerId": brand,
          "userId": this.selectedLeadForTx.lead_user_info.user_id,
          "created_by_id": getWelthUserId(),
          "welth_lead_id": this.selectedLeadForTx.welth_lead_id,
          "narayan_lead_id": this.selectedLeadForTx.id,
          "od_amount" : this.odAmount,
          "tp_amount" : this.tpAmount,
          "cgst_amount": this.CGSTAmount,
          "sgst_amount" : this.SGSTAmount,
          "igst_amount": this.IGSTAmount
        }
        console.log("Add tx data:", txData);
        try {
          const response = await axios.post(CREATE_NEW_TRANSACTION, txData);
          console.log("Add tx response: ", response);
          if (response.data.code == 200) {
            this.closeModal(true, response.data.data.order_id, response.data.data);
          } else {
            if (response.data.message)
              this.validationMessage = response.data.message;
              $("#saveBtn").removeAttr('disabled');
              $("#addBtnSpinner").hide();
          }
        } catch (error) {
          console.error('Add transaction error ', error);
          this.validationMessage = error.response.data.message;
          // this.validationMessage = "Add transaction error";
          $("#saveBtn").removeAttr('disabled');
          $("#addBtnSpinner").hide();
        }
      }
    },

    getIdForValue(collection, value) {
      let id = 0;
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].value == value) {
          id = collection[i].id;
        }
      }
      return id;
    },

    toggleAdditionalFields() {
      const category = this.getIdForValue(this.insCategory, this.selectedCategory);
      this.showAdditionalFields = category === 3;
    }
    // async postDocument(formData, params) {
    //   console.log("postDocument");
    //   // $("#uploadDocBtn").attr('disabled', 'disabled');
    //   // $("#uploadDocBtnSpinner").show();
    //   var self = this;
    //   var url = UPLOAD_TX_IMAGE + '?userId=' + getWelthUserId() + '&transId=' + this.txId;
    //   console.log(url);
    //   try {
    //     const response = await axios.post(url, formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         'Accept': 'application/json',
    //       },
    //     });
    //     console.log("postDocument response");
    //     console.log(response);
    //     if (response.status == 200) {
    //       console.log("emitting image upload done");
    //       console.log(response.data.data);
    //       setTimeout(() => {
    //         self.$emit('file-uploaded', this.newLeadData);
    //         if (!self.hasVideo) {
    //           // $("#uploadDocBtn").removeAttr('disabled');
    //           // $("#uploadDocBtnSpinner").hide();
    //           self.closeModal();
    //         }
    //       }, 50);
    //     }
    //   } catch (error) {
    //     console.error('Error uploading file', error);
    //     // $("#uploadDocBtn").removeAttr('disabled');
    //     // $("#uploadDocBtnSpinner").hide();
    //   }
    // },
  },
  watch: {
    paymentDate(newDate) {
      // Format the date as "dd-mm-yyyy"
      if (newDate) {
        const dateObject = new Date(newDate);
        const formattedDate = `${dateObject.getDate().toString().padStart(2, '0')}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getFullYear()}`;
        this.paymentDate = formattedDate;
      }
    },
    selectedCategory() {
      this.toggleAdditionalFields();
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 600px;
  padding: 20px;
  margin: 50px auto;
  border-radius: 24px;
  height: 640px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  cursor: pointer;
}

.date-info {
  display: flex;
  gap: 0px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 70px;
  align-items: center;
  cursor: pointer;
  color: #3b53cb;
}

.error {
  margin-top: 10px;
  margin-left: 25px;
  color: red;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.box1-f3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal1 input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.modal1 select {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.modal1 label {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
}


.modal1 input[type="text"]:focus,
.modal1-info input[type="date"]:focus,
.modal1 label:focus-within {
  outline: 1px solid #263ba0;
  border: none;
  box-shadow:0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #263ba0;
}

select option:checked {
 
  color: #263ba0; /* Adjust text color for better visibility */
}

select:focus {
  border-color: blue;
  outline: none; /* Remove default focus outline if needed */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Optional: Add a shadow for focus */
}
.close-icon {
  display: table-cell;
  justify-content: end;
  cursor: pointer;
  line-height: 48px;
}

.title {
  display: table-cell;
  color: #c7a84b;
  justify-content: flex-start;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}


.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;
}

.save-btn {
  height: 36px;
  width: 200px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
  margin-top: 20px;
}
</style>