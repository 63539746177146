<template>
  <div>
    <div class="terms-condition">
      <h1 class="title mt-4">"TERMS & CONDITIONS"</h1>
      <div class="heading mt-4">
        <p>
          These terms and conditions ("Terms & Conditions") govern your use of
          the website and mobile application –NIBPL("App") (Website and App
          shall collectively be referred to as "Platform") owned and operated by
          Narayan Insurance Brokers Private Limited with its registered office
          at E- 1/7, 3RD FLOOR, EAST PATEL NAGAR, NEW DELHI-110008 DELHI West
          Delhi DL 110008 IN (hereinafter referred to as "Us" / "We" / "NIBPL").
        </p>

        <p class="mt-3">
          As a user ("User" or "You") of the Platform, You understand that NIBPL
          is a Insurance Broker providing access between multiple Users in the
          form of consumers, financial institutions, data partners and other
          partner service providers etc.
        </p>

        <p class="mt-3">
          Please read these Terms & Conditions carefully before accessing or
          using our Platform or any part thereof. By accessing or using any part
          of the Platform, You agree to be bound by these Terms & Conditions. If
          You do not agree to all the terms and conditions, then YOU MAY NOT
          ACCESS THE PLATFORM OR USE ANY SERVICES PROVIDED BY US.
        </p>

        <p class="mt-3">
          These Terms and Conditions form a legal and binding agreement between
          the User and Us and is made pursuant to the terms of the Information
          Technology Act, 2000.
        </p>
      </div>
      <h1 class="genreal mt-4">1. GENERAL</h1>
      <div class="heading mt-2">
        <p class="mt-1">
          The Platform provides access to an online technology platform bringing
          together consumers, financial institutions, data partners and other
          partners.
        </p>
        <p class="mt-3">
          NIBPL is an organization registered with IRDA and holds license
          bearing Registration no. ____. NIBPL is not a Financial Institution
          under the Companies Act, 2013 or the Banking Regulation Act, 1949 or
          any other laws for the time being in force in India. NIBPL is also not
          a deposit taking company or a chit fund or a company offering any
          investment schemes as per the rules and regulations for the time being
          in force in India.
        </p>
        <p class="mt-3">
          NIBPL is a technology service provider facilitating the insurance
          purchase and renewal for the Indian users. In addition, NIBPL also
          provides certain other services with respect to data analytics subject
          to these Terms & Conditions.
        </p>
        <p class="mt-3">
          In the event You wish to avail any of the products displayed on the
          Platform, You will be required to provide certain information.
        </p>
        <p class="mt-3">
          NIBPL provides various services, including but not limited to the
          following ("Technology Platform and Software Solutions" or
          "Services"):
        </p>
        <p class="facility mt-3">
          a. Facilitating purchase of Life, Health and General Insurance from
          various insurers;
        </p>
        <p class="facility">
          b. Facilitating renewal of Life, Health and General Insurance from
          various insurers;
        </p>
        <p class="mt-2">
          For the provision of the above-mentioned Services, NIBPL will be using
          and sharing the information of the User with multiple
          financial/insurance institutions and other parties, as may be required
          for the purpose mentioned above and NIBPL shall also run multiple
          checks to be able to get the best available offers to suit the needs
          of the Users.
        </p>

        <h1 class="do">Do Not Disturb</h1>

        <p class="mt-2">
          In the event You have registered Your phone number on the 'Do Not
          Disturb' registers with Your network provider, You shall ensure to
          take all steps to enable the Company's representative's to contact You
          via phone to provide details about different financial and insurance
          products and You shall ensure that such calls received by You are
          pursuant to You providing Us with information and You shall not
          register a compliant with the relevant authorities for the same.
        </p>
      </div>
      <h1 class="genreal mt-4">2. ELIGIBILITY</h1>
      <div class="heading">
        <p>
          The User of this Website/App unequivocally declares and agrees that in
          case of an individual, the User is a natural / legal person who has
          attained the age of at least eighteen (18) years and is legally
          allowed to enter into a contract in India. The User is allowed to
          utilize the Services in accordance with these Terms & Conditions. If
          the User violates any of these Terms & Conditions, or otherwise
          violates an agreement entered into through the medium of the Website /
          App, NIBPL may terminate the User's membership, delete his/her profile
          and any content or information posted online by the User on the
          Platform or prohibit the User from using or accessing the Platform at
          any time in its sole discretion, with or without notice, including
          without limitation if NIBPL believes that User is under the age of
          eighteen (18) years.
        </p>
      </div>

      <h1 class="genreal mt-4">3. ACCEPTANCE OF TERMS AND CONDITIONS</h1>
      <div class="heading">
        <p>
          As a User of this Platform, You have agreed to the Terms & Conditions
          provided hereunder or anywhere else on this Platform including but not
          limited to disclaimers on this Platform.
        </p>
        <p class="mt-3">
          NIBPL reserves the right, at its sole discretion, to change, modify,
          add or remove portions of these Terms & Conditions, at any time
          without any prior written notice to the User. It is the User's
          responsibility to review these Terms & Conditions periodically for any
          updates/ changes. User's continued use of the App or Website following
          the modification of these Terms and Conditions will imply the
          acceptance by the User of the revisions
        </p>
      </div>
      <h1 class="genreal mt-4">4. YOUR ACCOUNT</h1>
      <div class="heading">
        <p>
          If You wish to use the Platform, You are required to maintain an
          account on the Website or App through Your mobile number or email and
          You will be required to furnish certain information and details,
          including Your name, email id, contact number and any other
          information deemed necessary by NIBPL or its partners for the purpose
          of accepting Your eligibility for the financial and insurance products
          offered on our Platform through various regulated financial and
          insurance institutions.
        </p>
        <p class="mt-3">
          You are responsible for maintaining the confidentiality and security
          of Your account, password, activities that occur in or through Your
          account and for restricting access to Your computer or mobile, as the
          case may be, to prevent unauthorized access to Your account. You agree
          to accept responsibility for all activities that occur under Your
          account or password. You should take all necessary steps to ensure
          that the password is kept confidential and secure and should inform us
          immediately if You have any reason to believe that Your password has
          become known to anyone else, or if the password is being, or is likely
          to be, used in an unauthorized manner.
        </p>
        <p class="mt-3">
          Please ensure that the details You provide to Us are true, correct,
          accurate and complete. Upon NIBPL gaining knowledge of having any
          reasonable suspicion that the information provided by You is wrong,
          inaccurate or incorrect, NIBPL shall immediately terminate Your
          account without any notice to You in this regard. In the event of any
          changes to the information shared by You after registering Yourself on
          the Platform, You shall be responsible for forthwith notifying the
          said changes to Us. You can access and update most of the information
          You provided us on the 'USER Profile' area of Your account after You
          log-in or by writing to Us at support@narayaninsurancebrokers.com. The
          right to use this Platform is personal to the User and is not
          transferable to any other person or entity.
        </p>

        <p class="mt-3">
          Any changes we make will be effective immediately upon our making such
          changes available on the Website or otherwise providing notice
          thereof. We may provide notice of any such changes by posting an
          updated Agreement with the date of the update set forth below. You
          agree that by entering, re-entering, or continuing to use the Website
          thereafter, you accept any and all such changes. Be sure to return to
          this page periodically to ensure familiarity with the most current
          version of this Agreement.
        </p>
      </div>

      <h1 class="genreal mt-4">5. PRIVACY</h1>
      <div class="heading">
        <p class="">
          NIBPL collects certain information from You in order to provide the
          Services. NIBPL's use of Your personal information is governed in
          accordance with the Privacy Policy located at our App/website.
        </p>
      </div>

      <h1 class="genreal mt-4">6. LICENSE AND ACCESS</h1>
      <div class="heading">
        <p class="">
          NIBPL grants You a limited, non-transferable and revocable license to
          access and use the Platform or any part thereof for availing the
          Services, but not to download any material from it (other than page
          caching) or modify it, or any portion of it, except with express
          consent of NIBPL and / or its affiliates, as may be applicable. Any
          unauthorized access to the Platform (or any part thereof) or any
          networks, servers or computer systems connected to Platform and any
          attempt to modify, adapt, translate or reverse engineer any part of
          the Platform or re-format or frame any portion of the pages of the
          Platform, save to the extent expressly permitted by these Terms &
          Conditions, is not permitted.
        </p>
        <p class="mt-3">
          This license is non-transferable and does not permit any resale or
          commercial use of this Platform or its contents; any downloading or
          copying of account information for the benefit of anyone other than
          Your use; or any use of data mining, robots, or similar data gathering
          and extraction tools. The Platform or any portion of the Platform
          (including but not limited to any copyrighted material, trademarks, or
          other proprietary information) may not be reproduced, duplicated,
          copied, sold, resold, visited, distributed or otherwise exploited for
          any commercial purpose without express written consent of NIBPL and /
          or its affiliates, as may be applicable. Any unauthorized use of the
          Platform shall terminate the permission or revoke the license granted
          by NIBPL.
        </p>
      </div>
      <h1 class="genreal mt-4">
        7. MONITORING OF THE PLATFORM AND YOUR ACCOUNT
      </h1>
      <div class="heading">
        <p class="">
          NIBPL always has the right and liberty to monitor the content of the
          Platform or any part thereof which shall include information provided
          in Your account. The monitoring of the Platform is important to
          determine the veracity of the information provided by You and that
          every User remains in consonance with the Terms & Conditions provided
          herein. Subject to the Terms & Conditions mentioned herein, NIBPL
          shall also have the liberty to remove any objectionable content which
          is in contravention of the Terms & Conditions or any applicable laws
          in force in India or share such information with any governmental
          authority as per procedures laid down by the law for the time being in
          force in India.
        </p>
      </div>

      <h1 class="genreal mt-4">8. CASHBACK OR LOYALTY POINTS</h1>
      <div class="heading">
        <p class="">
          NIBPL offers its users loyalty points in various forms for their
          regular and continuous use of the platform. These loyalty points are
          provided for performing various actions on the platform. Each user who
          makes his/her insurance renewal or purchase through NIBPL earns
          loyalty points. These loyalty points or cashback is rewarded purely
          for the regular use of the NIBPL and the NIBPL does not permit any
          purchase or accrual of these loyalty points in any other way. These
          loyalty points may be redeemed by Users to participate in various
          rewards/offers that are run by NIBPL for all users from time to time.
        </p>
        <p class="mt-3">
          NIBPL reserves the right, at its sole discretion, to change the mode
          of the accrual of one or any of these types of loyalty points,
          including the number of such loyalty points received in proportion to
          the User’s activities. Further, NIBPL reserves the right to disqualify
          any User, who does not meet the offer requirements or for any other
          reason including but not limited to any misuse of the offer or fraud
          or suspicious transaction/activity or under any legal requirement or
          applicable rules and regulations, from receiving any loyalty points.
          NIBPL also reserves the right to discontinue or change or issue any
          new form of loyalty points offered at any time, at its sole
          discretion. NIBPL may also at its discretion specify an expiry period
          for the loyalty points.
        </p>
      </div>

      <h1 class="genreal mt-4">8. CASHBACK OR LOYALTY POINTS</h1>
      <div class="heading">
        <p class="">
          NIBPL offers its users loyalty points in various forms for their
          regular and continuous use of the platform. These loyalty points are
          provided for performing various actions on the platform. Each user who
          makes his/her insurance renewal or purchase through NIBPL earns
          loyalty points. These loyalty points or cashback is rewarded purely
          for the regular use of the NIBPL and the NIBPL does not permit any
          purchase or accrual of these loyalty points in any other way. These
          loyalty points may be redeemed by Users to participate in various
          rewards/offers that are run by NIBPL for all users from time to time.
        </p>
        <p class="mt-3">
          NIBPL reserves the right, at its sole discretion, to change the mode
          of the accrual of one or any of these types of loyalty points,
          including the number of such loyalty points received in proportion to
          the User’s activities. Further, NIBPL reserves the right to disqualify
          any User, who does not meet the offer requirements or for any other
          reason including but not limited to any misuse of the offer or fraud
          or suspicious transaction/activity or under any legal requirement or
          applicable rules and regulations, from receiving any loyalty points.
          NIBPL also reserves the right to discontinue or change or issue any
          new form of loyalty points offered at any time, at its sole
          discretion. NIBPL may also at its discretion specify an expiry period
          for the loyalty points.
        </p>
      </div>
      <h1 class="genreal">9. TAX LIABILITY</h1>
      <div class="heading">
        <p>
          You hereby agree to comply with any and all applicable Indian tax laws
          in connection with your use of the NIBPL Services, including without
          limitation, the reporting and payment of any taxes arising in
          connection with payments made through NIBPL, or funds/income received
          through the NIBPL Services.
        </p>
      </div>

      <h1 class="genreal">10. SERVICE SUSPENSION</h1>
      <div class="heading">
        <p>
          NIBPL may wish to stop providing the Services and may terminate use of
          it at any time without giving notice of termination to You. Unless
          NIBPL informs the User otherwise, upon any termination, (a) the rights
          and licenses granted to You in these Terms and Conditions will end;
          and (b) User must stop using the Platform or any part thereof
          forthwith. NIBPL reserves the right to suspend or cease providing any
          Service and shall have no liability or responsibility to the User in
          any manner whatsoever.
        </p>
      </div>

      <h1 class="genreal">11. PROHIBITED USES</h1>
      <div class="heading">
        <p>
          In addition to other prohibitions as set forth in the Terms &
          Conditions, You are prohibited from using the Platform: (a) for any
          unlawful purpose; (b) to solicit others to perform or participate in
          any unlawful acts; (c) to violate any international, federal,
          provincial or state regulations, rules, laws, or local ordinances; (d)
          to infringe upon or violate our intellectual property rights or the
          intellectual property rights of others; (e) to harass, abuse, insult,
          harm, defame, slander, disparage, intimidate, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability; (f) to submit false or misleading information;
          (g) to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Service or of any related website, other websites,
          or the Internet; (h) to collect or track the personal information of
          others; (i) to spam, phish, harm, pretext, spider, crawl, or scrape;
          (j) for any obscene or immoral purpose; or (k) to interfere with or
          circumvent the security features of the Service or any related
          website, other websites, or the Internet. We reserve the right to
          terminate Your use of the Service or any related website for violating
          any of the prohibited uses.
        </p>
      </div>

      <h1 class="genreal">
        12. LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES
      </h1>
      <div class="heading">
        <p>
          Notwithstanding anything to the contrary contained herein, neither
          NIBPL nor its affiliated companies, subsidiaries, officers, directors,
          employees or any related party shall have any liability to You or to
          any third party for any indirect, incidental, special or consequential
          damages or any loss of revenue or profits arising under or relating to
          these Terms & Conditions. To the maximum extent permitted by law, You
          waive, release, discharge and hold harmless NIBPL, its affiliated and
          subsidiary companies, and each of their directors, officers,
          employees, and agents, from any and all claims, losses, damages,
          liabilities, expenses and causes of action arising out of Your use of
          the Platform / Website / App.
        </p>
        <p class="mt-3">
          As a User, You also acknowledge that NIBPL has maintained and carried
          out all possible checks and balances to assure the credibility of the
          Users as listed on the Platform. The information provided on the
          Website / App is based on the information that is provided to NIBPL by
          its Users. In case the User does not disclose any information to
          NIBPL, and the same is not found out at the first instance by NIBPL,
          NIBPL shall not be responsible for any claims or liabilities.
        </p>
        <p class="mt-3">
          NIBPL is merely an information technology platform and the User is
          entitled to conduct its own diligence before taking any steps to
          initiate the processes outlined on the Platform or any part thereof.
        </p>
        <p class="mt-3">
          Your use of the service and all information, products and other
          content (including that of third parties) included in or accessible
          from the service is at your sole risk. The service is provided on an
          "as is" and "as available" basis.
        </p>
        <p class="mt-3">
          NIBPL expressly disclaims all warranties of any kind as to the service
          and all information, products and other content (including that of
          third parties) included in or accessible from the service, whether
          express or implied, including, but not limited to the implied
          warranties of merchantability, fitness for a particular purpose and
          noninfringement. NIBPL further makes no warranty that: (i) the service
          will meet your requirements, (ii) the service will be uninterrupted,
          timely, secure, or error-free, (iii) the results that may be obtained
          from the use of the service will be accurate or reliable, (iv) the
          quality of any products, services, information, or other material
          purchased or obtained by you through the service will meet your
          expectations, or (v) any errors in the technology will be corrected
          and you are solely responsible for any damage to your computer system
          or loss of data that results from the download of any such material.
          No advice or information, whether oral or written, obtained by you
          from NIBPL through or from the service will create any warranty not
          expressly stated in these terms.
        </p>
      </div>

      <h1 class="genreal">13. INDEMNITY</h1>
      <div class="heading">
        <p>
          You agree to indemnify, save, and hold NIBPL, its affiliates,
          contractors, employees, officers, directors, agents and its third
          party associates, licensors, and partners harmless from any and all
          claims, demands, losses, damages, and liabilities, costs and expenses,
          including without limitation legal fees and expenses, arising out of
          or related to Your use or misuse of the Services or of the Website or
          App or Platform, any violation by You of these Terms & Conditions, or
          any breach of the representations, warranties, and covenants made by
          You herein or Your infringement of any intellectual property or other
          right of any person or entity, or as a result of any threatening,
          libelous, obscene, harassing or offensive material posted/ transmitted
          by You on the Website / App. NIBPL reserves the right, at Your
          expense, to assume the exclusive defense and control of any matter for
          which You are required to indemnify NIBPL, including rights to settle,
          and You agree to cooperate with NIBPL’s defense and settlement of
          these claims. NIBPL will use reasonable efforts to notify You of any
          claim, action, or proceeding brought by a third party that is subject
          to the foregoing indemnification upon becoming aware of it. This
          paragraph shall survive termination of this Terms & Conditions.
        </p>
      </div>

      <h1 class="genreal">
        14. ADVERTISERS/ THIRD PARTY LINKS ON THE PLATFORM
      </h1>
      <div class="heading">
        <p>
          NIBPL accepts no responsibility for advertisements contained within
          the Platform or any part thereof. NIBPL has no control over and
          accepts no responsibility for the content of any website or mobile
          application to which a link from the Platform or any part thereof
          exists. Such linked websites and mobile applications are provided "as
          is" for User's convenience only with no warranty, express or implied,
          for the information provided within them or the veracity thereof.
        </p>
      </div>

      <h1 class="genreal">15. USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h1>
      <div class="heading">
        <p>
          If, at NIBPL's request, You send certain specific submissions (for
          example contest entries) or without a request from Us, You send
          creative ideas, suggestions, proposals, plans, or other materials,
          whether online, by email, by postal mail, or otherwise (collectively,
          'comments'), You agree that We may, at any time, without restriction,
          edit, copy, publish, distribute, translate and otherwise use in any
          medium any comments that You forward to Us. We are and shall be under
          no obligation (a) to maintain any comments in confidence; (b) to pay
          compensation for any comments; or (c) to respond to any comments.
        </p>
        <p class="mt-3">
          You agree that Your comments will not violate any right of any
          third-party, including copyright, trademark, privacy, personality or
          other personal or proprietary right. You further agree that Your
          comments will not contain libelous or otherwise unlawful, abusive or
          obscene material, or contain any computer virus or other malware that
          could in any way affect the operation of the Service or any related
          website. You may not use a false e-mail address, pretend to be someone
          other than Yourself, or otherwise mislead Us or third-parties as to
          the origin of any comments. You are solely responsible for any
          comments You make and their accuracy. We take no responsibility and
          assume no liability for any comments posted by You or any third-party.
        </p>
        <p class="mt-3">
          You are licensing to NIBPL and its service providers, any information,
          data, passwords, materials or other content (collectively, "Content")
          You provide through or to the Service. Company and service provider of
          Company may use, modify, display, distribute and create new material
          using such Content to provide the Service to You. We may also use,
          sell, license, reproduce, distribute and disclose aggregate,
          non-personally identifiable information that is derived through Your
          use of the Service. By submitting Content, You automatically agree, or
          promise that the owner of such Content has expressly agreed that,
          without any particular time limit, and without the payment of any
          fees, NIBPL and its third party service provider may use the Content
          for the purposes set out above. As between NIBPL and third-party
          service provider, Company owns Your confidential account information.
        </p>
      </div>

      <h1 class="genreal">16. APPLICABLE LAWS</h1>
      <div class="heading">
        <p>
          Your use of this Platform and any Term & Conditions stated in this
          agreement is subject to laws of India. In case of any disputes arising
          out of the use of the Platform or any part thereof, Courts of
          Bangalore, India will have exclusive jurisdiction.
        </p>
      </div>
      <h1 class="genreal">17. REFUNDS & CANCELLATION:</h1>
      <div class="heading">
        <p>
          Refunds / Cancellation initiated will be credited to the account/card
          from where the transaction had initiated.
        </p>
      </div>
      <h1 class="genreal">18. CONTACT US</h1>
      <div class="heading">
        <p class="mb-4">
          Please feel free to reach out to us for any complaint/ feedback by email
          us at support<a href="" class="email-link"> hello@narayanwealth.com</a>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

body {
  background: #f2f2f2;
  width: 100%;
}

.terms-condition {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f2f2f2;
}

.title {
  text-align: center;
  color: #c7a84b;
  font-size: 2em;
  font-weight: 800;

}

.heading {
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  margin: auto 10%;
}

.heading p {
  color: #263ba0;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
  text-align: justify;
}

.genreal {
  color: #c7a84b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 240% */
  margin-left: 10%;
}

.heading .facility {
  color: #c7a84b;
}

.heading .do {
  color: #c7a84b;
  font-size: 20px;
  margin: 10px 0px;
  font-weight: 700;
}

.email-link {
  color: #c7a84b;
  text-decoration: none;
}
</style>