<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="close-icon" @click="closeModal">
        <img src="../images/close-popup.png" alt="" />
      </div>
      <div>
        <h1 class="title">Add Roles</h1>
      </div>
      <input type="text" placeholder="Add Name" v-model="agentName">
      <input type="text" placeholder="Phone Number" v-model="agentPhoneNo">
      <select id="genderSelect" v-model="selectedGender">
        <option value="" selected disabled>Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </select>
      <select id="stateSelect" v-model="selectedStateId" @change="handleStateChange">
        <option value="-1" disabled>Select State</option>
        <option v-for="state in allStateInfo" :key="state.state_id" :value="state.state_id">
          {{ state.state_name }}
        </option>
      </select>
      <select id="citySelect" v-model="selectedCityId" @change="">
        <option value="-1" disabled>Select a City</option>
        <option v-for="city in allCityInfo" :key="city.city_id" :value="city.city_id">
          {{ city.city_name }}
        </option>
      </select>
      <select id="stateRole" v-model="selectedRoleId">
        <option value="-1" disabled>Select Role</option>
        <option v-for="role in allRoles" :key="role.relationship_id" :value="role.relationship_id">
          {{ role.relationship_name }}
        </option>
      </select>
      <div class="error"> {{ validationMessage }} </div>
      <div class="save">
        <button id="saveBtn" class="save-btn" @click="addAgent">
          <span>Add Role</span>
          <span class="spinner-border spinner-border-sm ml-4" role="status" aria-hidden="true" id="addBtnSpinner"
            style="display: none"></span>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { GET_CITY_INFO, GET_STATES_INFO, GET_USER_ROLES, ADD_EXECUTIVE, VENDOR_ID } from '../util/constants';
import { getWelthUserId } from '@/util/loginData';

export default {
  data() {
    return {
      agentName: "",
      agentPhoneNo: "",
      allStateInfo: [],
      selectedStateId: -1,
      allCityInfo: [],
      selectedCityId: -1,
      allRoles: [],
      selectedRoleId: -1,
      selectedGender: "",
      validationMessage: "",
    }
  },

  mounted() {
    console.log("Add Roles page, mounted");
    console.log("Current user id: " + getWelthUserId());
    this.loadStateInfo();
    this.loadRolesInfo();
  },

  methods: {
    closeModal() {
      console.log("close");
      this.$emit("close");
    },

    async loadCityInfo() {
      console.log("loadCityInfo called ");
      const response = await axios.get(GET_CITY_INFO + this.selectedStateId, {});
      console.log(response.data.message);
      if (response.data.code == 200) {
        this.allCityInfo = response.data.data;
      }
      // console.log("loadCityInfo size: " + this.allCityInfo.length);
      // console.log("loadCityInfo: " + this.allCityInfo);
    },

    async loadStateInfo() {
      console.log("loadStateInfo called ");
      const response = await axios.get(GET_STATES_INFO, {});
      console.log(response.data.message);
      if (response.data.code == 200) {
        this.allStateInfo = response.data.data;
      }
      console.log("loadStateInfo size: " + this.allStateInfo.length);
      // console.log("allStateInfo: " + this.allStateInfo);
    },

    handleStateChange() {
      console.log("handleStateChange");
      this.loadCityInfo();
    },

    async loadRolesInfo() {
      console.log("loadRolesInfo called ");
      const response = await axios.get(GET_USER_ROLES, {});
      console.log(response.data.message);
      if (response.data.code == 200) {
        this.allRoles = response.data.data;
      }
      // console.log("loadRolesInfo size: " + this.allRoles.length);
      if (this.allRoles.length > 0) {
        // Create a array excluding the user role
        this.allRoles = this.allRoles.filter(role => role.relationship_id !== 0);
        console.log("loadRolesInfo final size: " + this.allRoles.length);
      }
    },

    async addAgent() {
      console.log("addAgent called...");
      let validation = true;
      if (validation && this.agentName == "") {
        this.validationMessage = "Please enter agent name";
        validation = false;
      }
      if (validation && this.agentPhoneNo == "") {
        this.validationMessage = "Please enter agent phone number";
        validation = false;
      }
      if (validation && this.selectedGender == "") {
        this.validationMessage = "Please select gender";
        validation = false;
      }
      if (validation && this.selectedStateId == -1) {
        this.validationMessage = "Please select state";
        validation = false;
      }
      if (validation && this.selectedCityId == -1) {
        this.validationMessage = "Please select city";
        validation = false;
      }
      if (validation && this.selectedRoleId == -1) {
        this.validationMessage = "Please select role";
        validation = false;
      }

      if (validation) {
        $("#saveBtn").attr('disabled', 'disabled');
        $("#addBtnSpinner").show();
        let agentData = {
          "adminUserId": getWelthUserId(),
          "name": this.agentName,
          "number": this.agentPhoneNo,
          "role": this.selectedRoleId,
          "state_id": this.selectedStateId,
          "city_id": this.selectedCityId,
          "vendor_id": VENDOR_ID,
          "gender": this.selectedGender,
        }
        console.log(agentData);
        try {
          const response = await axios.post(ADD_EXECUTIVE, agentData);
          console.log("Add executive response: ", response);
          this.validationMessage = response.data.message;
          self = this;
          if (response.data.code == 200) {
            setTimeout(() => {
              this.$emit('added-agent');
              self.closeModal();
            }, 100);
          } else {
            setTimeout(() => {
              $("#saveBtn").removeAttr('disabled');
              $("#addBtnSpinner").hide();
            }, 100);
          }
        } catch (error) {
          this.validationMessage = error.response.data.message;
          $("#saveBtn").removeAttr('disabled');
          $("#addBtnSpinner").hide();
          console.error('Error adding executive ', error);
        }
      }
    }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 500px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.title {
  color: #c7a84b;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.modal1 input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.modal1 select {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding-top: 20px;
}

.save-btn {
  height: 30px;
  width: 100px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
}

.error {
  margin-top: 10px;
  margin-left: 25px;
  color: red;
}
</style>