import { GET_BRAND_DATA, RENEW_POLICY, UPLOAD_TX_IMAGE } from '@/util/constants';
import axios from 'axios';

export const formatDate1 = (inputDate) => {
  const date = new Date(inputDate);

  // Ensure inputDate is a valid date
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Define month names in the abbreviated form (MMM)
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

export const formatDate = (inputDate) => {
  const utcDate = new Date(inputDate);
  if (isNaN(utcDate.getTime())) {
    return 'Invalid Date';
  }

  // Convert UTC date to local date
  const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

  // Format local date to "10-Jan-2024" format
  const formattedDate = localDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });

  return formattedDate;
}

export const formatTime = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    // timeZone: 'UTC'
  });
}

export const loadInsCategory = async () => {
  let data = [];
  data.unshift({
    id: 14,
    value: "Travel Insurance",
  });
  data.unshift({
    id: 13,
    value: "Property Insurance",
  });
  data.unshift({
    id: 12,
    value: "Group Accidental Insurance",
  });
  data.unshift({
    id: 11,
    value: "Group Health Insurance",
  });
  data.unshift({
    id: 10,
    value: "MF",
  });
  data.unshift({
    id: 9,
    value: "SIP",
  });
  data.unshift({
    id: 5,
    value: "Term Insurance",
  });
  data.unshift({
    id: 4,
    value: "Fixed Deposit",
  });
  data.unshift({
    id: 3,
    value: "Motor Insurance",
  });
  data.unshift({
    id: 2,
    value: "Health Insurance",
  });
  data.unshift({
    id: 1,
    value: "Life Insurance",
  });
  data.unshift({
    id: 0,
    value: "Policy Type",
  });

  return data;
}

export const loadInsType = async () => {
  let data = [];
  data.unshift({
    id: 2,
    value: "Renew",
  });
  data.unshift({
    id: 1,
    value: "New Policy",
  });
  data.unshift({
    id: 0,
    value: "New / Renew",
  });
  return data;
};

export const loadBrandData = async () => {
  console.log("loadBrandData called");
  try {
    var url = GET_BRAND_DATA + "?typeId=" + "1&version=1";
    const response = await axios.get(url, {});
    // console.log("get brand data:", response);
    // console.log("get brand dataa:", response.data.data);
    if (response.status == 200) {
      let finalData = [];
      finalData.unshift({
        id: 0,
        categoty_id: 0,
        value: "Brand Name",
      });
      if (response && response.data && response.data.data) {
        for (let i = 0; i < response.data.data.length; i++) {
          var insData = response.data.data[i];
          // console.log("Insurer Name:", insData.insurer_name);
          // console.log("Insurer Logo:", insData.insurer_logo);
          finalData.unshift({
            id: insData.insurer_id,
            categoty_id: insData.ins_category_id,
            value: insData.insurer_name,
          });
          // for (let j = 0; j < insData.products.length; j++) {
          //   finalData.unshift({
          //     id: insData.products[j].id,
          //     categoty_id: insData.ins_category_id,
          //     value: insData.products[j].titleName,
          //   });
          // }
        }
      } else {
        console.error("Response or its data is undefined.");
      }
      let reverseData = [];
      for (let i = 0; i < finalData.length; i++) {
        reverseData.unshift(finalData[i]);
      }
      // console.log("All brand data:", reverseData);
      return reverseData;
    }
  } catch (error) {
    console.error("Error getting brand data", error);
  }
  return [];
};

export const loadBrandDataV0 = async () => {
  console.log("loadBrandData called");
  try {
    var url = GET_BRAND_DATA + "?typeId=" + "1";
    const response = await axios.get(url, {});
    // console.log("get brand data:", response);
    if (response.status == 200) {
      let finalData = [];
      finalData.unshift({
        id: 0,
        categoty_id: 0,
        value: "Brand Name",
      });
      for (let i = 0; i < response.data.data.length; i++) {
        var insData = response.data.data[i];
        for (let j = 0; j < insData.ins_category_insurer.length; j++) {
          finalData.unshift({
            id: insData.ins_category_insurer[j].insurer_id,
            categoty_id: insData.ins_category_insurer[j].insurance_category_id,
            value: insData.ins_category_insurer[j].insurer_name,
          });
        }
      }
      let reverseData = [];
      for (let i = 0; i < finalData.length; i++) {
        reverseData.unshift(finalData[i]);
      }
      // console.log("All brand data:", reverseData);
      return reverseData;
    }
  } catch (error) {
    console.error("Error getting brand data", error);
  }
  return [];
};