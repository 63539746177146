<template>
  <div>
    <div class="privacy-policy">
      <h1 class="title mt-4">" PRIVACY POLICY "</h1>

      <div class="heading mt-4">
        <p>
          We, Narayan Insurance Brokers Private Limited, with our registered
          office at New Delhi India, are the owner and operator of the Platform
          NIBPL(hereinafter shall be referred to as "NIBPL" or "Us" or "We" or
          "Our").
        </p>
        <p class="mt-3">
          This privacy policy describes our policies and procedures for
          collection, use, storage, processing, disclosure and protection of any
          information, including, but not limited to, business or personal
          information provided by you as a user ("You(r)") while using the
          platform through the website and mobile and/or web application (the
          “App”).
        </p>
        <p class="mt-3">
          This Policy is published in accordance with the provisions of the
          Information Technology Act, 2000 and the Information Technology
          (Reasonable Security Practices and Procedures and Sensitive Personal
          Data or Information) Rules, 2011.
        </p>
        <p class="mt-3">
          USER shall mean any person/ persons, who visits, uses, deals with and/
          or transacts through the website / app or avails our services and
          includes a browser.
        </p>
        <p class="mt-3">
          By visitng/ accessing the platform, through the website or the app and
          voluntarily providing us with information (personal and / or non-
          personal), you are consenting to our use of your personal/non-personal
          information in accordance with this policy.
        </p>
        <p class="mt-3">
          This policy does not apply to third-party websites/ applications that
          are connected via links to the platform. If you do not agree with this
          policy, please do not proceed further to use / access this
          platform.This policy is a part of the platform terms & conditions and
          all capitalized terms under this policy that have not been
          specifically defined herein shall have the meaning as ascribed to it
          under the platform terms & conditions.
        </p>
        <p class="mt-3"></p>
      </div>
      <h1 class="genreal mt-4">1. COLLECTION OF INFORMATION</h1>
      <div class="heading">
        <p>
          You may scroll through our Website and generally view the contents of
          the Website without providing us any personal information. In such a
          case, We will place a Cookie on Your computer that will help us
          identify You should You decide to visit us again.
        </p>

        <p class="mt-3">
          During Your use of the Platform, whether the App or the Website, We
          will collect certain NON-PERSONAL AND PERSONAL information from You.
        </p>
        <p class="mt-3">
          a. The Non-Personal Information: When You visit our Website, we
          typically collect the IP address of the device you use to connect to
          the internet, information such as what browser, browser version,
          operating system you have, the website you came from or the
          advertisement you viewed or clicked on. We also collect non-personal
          information on our Website and from third parties to provide an online
          experience that matches Your requirements.
        </p>
        <p class="mt-3">
          b. The Personal Information: Is collected by Us after Your explicitly
          provided us consent or is shared by You with Us pursuant to a clear
          disclosure - such as name, address, date of birth, phone number, and
          email address. We will collect your personal information when you:
          <br />
          <br />
          i. Register on our Website or App using your mobile number; and/or
          <br /><br />
          ii.. Information that You provide to Us:<br /><br />
          a. Your name, address, date of birth, contact information, marital
          status, PAN number, name, account number and IFSC code of the bank
          where You have a primary account <br />
          b. Your Aadhar Number at your sole discretion which will be stored
          after masking the same.<br />
          c. Other information that You provide when You write directly to Us
          (including by e-mail) or provide Us over telephone or while filling
          out any applications on the Platform. Information we collect from Your
          Device or a third Party:<br /><br />
          We collect information from the device when you download and install
          the App and explicitly seek permissions from YOU to get the required
          information from the device. The information we collect from your
          device includes the unique ID i.e., IMEI number, information on
          operating system, SDK version and mobile network information including
          carrier name, storage, location. We collect information about your
          device to provide automatic updates and additional security so that
          your account is not used in other people's devices. In addition, the
          information provides us valuable feedback on your identity as a device
          holder as well as your device behavior, thereby allowing us to improve
          our services and provide an enhanced customized user experience to
          you.<br /><br />
          <span class="key-info">The key information collected from the device is further detailed
            below </span><br />
          <br />
          i. Location <br />
          We collection location information from your device to reduce the risk
          associated with your account. <br /><br />
          ii. Storage <br />
          We require the storage information permission to enable the App to
          enable you to upload photos and/or documents to complete the
          application form during your financial product(s) / services
          buying/renewal journey.<br /><br />
          iii. Camera <br />
          We require the camera information permission to provide you an
          easy/smooth experience and to enable you to click photos of your KYC
          documents along with other requisite documents and upload the same on
          the App during your financial product(s) / services buying/renewal
          journey. Other Information: <br /><br />
          i. Any other information that NIBPL is required to collect as a
          requirement under applicable law(s); <br /><br />
          ii. Information from You electronically - like the pages viewed, how
          You navigate through the Website / Platform and interact with
          webpages, etc.<br /><br />
          iii. Cookies: Cookies are small data files that a Website / App stores
          on Your computer. We will use cookies on our Website / App similar to
          other websites / apps and online marketplace websites / apps. Use of
          this information helps Us identify You in order to make our Platform
          more user friendly. We might also use this information to display
          advertising from third party companies. Most browsers will permit You
          to decline cookies but if You choose to do this it might affect
          service on some parts of Our Website.<br />
          <br />iv. We might also use other technology to track how You interact
          with Platform and employ some third-party agencies to use the data on
          Our behalf. <br />
          <br /><br />
          The information We collect about You will depend on the products and
          services we offer or You avail, on an ongoing basis. The information
          collected from You will be used in order to effectively provide
          Services to You. If You do not allow Us to collect all the information
          We request on the Platform, We may not be able to deliver all of these
          services effectively.
        </p>
      </div>
      <h1 class="genreal mt-4">
        2. PURPOSE, USE AND DISCLOSURE OF INFORMATION
      </h1>
      <div class="heading">
        <p>
          We will use and retain Your information for such periods as necessary
          to provide You the services on our Platform, to comply with our legal
          obligations, to resolve disputes, and enforce our agreements with you.
        </p>

        <p class="mt-3" style="color: #c7a84b">Use of Information:</p>

        <p class="mt-3">
          We use data collected to support our Product, NIBPL, as a platform
          assists You to reach and select diversity of third-party
          financial/insurance partners, along with their products, and
          technically help you accomplish transaction with these third-party
          financial/insurance partners.
        </p>
        <p class="mt-2">
          <span class="mt-2" style="color: #c7a84b">We may use the information provided by You in the following
            ways:</span>
          <br />
          <br />
          a. to establish identity and verify the same; <br />
          b. monitor, improve and administer our Platform; <br />
          c. design and offer customized products and services offered by our
          third-party financial/insurance partners; <br />
          d. analyze how the Platform is used, diagnose service or technical
          problems and maintain security; <br />
          e. send communications notifications, information regarding the
          products or services requested by You or process queries and
          applications that You have made on the Platform; <br />
          f. manage Our relationship with You and inform You about other
          products or services We think You might find of some use; <br />
          g. conduct data analysis in order to improve the Services provided to
          the User; <br />
          h. use the User information in order to comply with country laws and
          regulations; <br />
          i. to conduct KYC based on the information shared by the User for the
          provision of Services; <br />
          j. use the User information in other ways permitted by law to enable
          You to take financial/insurance services from our partners. <br />
        </p>

        <p class="mt-3" style="color: #c7a84b; font-size: 20px">
          Disclosure of Information:
        </p>

        <p class="mt-2">
          We may share Your information with third parties including our
          regulated financial/insurance partners for provision of services on
          the Platform to a User. We may also share Your information with third
          parties under a confidentiality agreement for provision of services
          which inter alia restricts such third parties from further disclosing
          the information unless such disclosure is for the purpose as detailed
          under that confidentiality agreement.
        </p>

        <p class="mt-2">
          a. We may disclose Your information to the financial/insurance service
          providers and Our third-party partners for providing the Services as
          detailed under the Terms & Conditions; <br />
          b. We may share Your information with our third-party partners in
          order to conduct data analysis in order to serve You better and
          provide Services on our Platform;<br />
          c. We may disclose Your information, without prior notice, if We are
          under a duty to do so in order to comply with any legal obligation or
          an order from the government and/or a statutory authority, or in order
          to enforce or apply Our terms of use or assign such information in the
          course of corporate divestitures, mergers, or to protect the rights,
          property, or safety of Us, Our users, or others. This includes
          exchanging information with other companies and organizations for the
          purposes of fraud protection.<br />
          d. We and our affiliates may share Your information with another
          business entity should we (or our assets) merge with, or be acquired
          by that business entity, or re-organization, amalgamation,
          restructuring of business for continuity of business.<br />
        </p>
      </div>

      <h1 class="genreal mt-4">3. STORAGE OF INFORMATION</h1>
      <div class="heading mt-2">
        <p>
          You further understand and agree that purchase/renewal of insurance
          policy does not automatically rescind the consents you have provided
          to us under the Privacy Policy nor does deletion of your account with
          the Platform. Any information provided to us is retained by Us until
          You have an account based / active relationship Us or with a
          financial/insurance / Service partner(s).
        </p>

        <p class="mt-3">
          We store and process Your personal information on secure AWS cloud
          servers and other cloud service providers within India. Some of the
          safeguards We use are firewalls and encryption using SSL, and
          information access authorization controls. We use reasonable
          safeguards to preserve the integrity and security of Your information
          against loss, theft, unauthorized access, disclosure, reproduction,
          use or amendment. To achieve the same, We use reasonable security
          practices and procedures as mandated under applicable laws for the
          protection of Your information. Information You provide to Us may be
          stored on Our secure servers located within India.
        </p>
        <p class="mt-3">
          However, You understand and accept that there's no guarantee that data
          transmission over the Internet will be completely secure and that any
          information that You transmit to Us is at Your own risk. We assume no
          liability for any disclosure of information due to errors in
          transmission, unauthorized third party access to our Platform and data
          bases or other acts of third parties, or acts or omissions beyond Our
          reasonable control and You shall not be entitled to hold NIBPL
          responsible for any breach of security.
        </p>
      </div>

      <h1 class="genreal mt-4">4. THIRD PARTY WEBSITES</h1>
      <div class="heading mt-2">
        <p>
          With this Policy We're only addressing the disclosure and use of data
          collected by Us. If You visit any websites through the links on the
          NIBPL Platform, please ensure You go through the privacy policies of
          each of those websites. Their data collection practices and their
          policies might be different from this Policy and NIBPL does not have
          control over any of their policies neither does it have any liability
          in this regard.
        </p>
      </div>
      <h1 class="genreal mt-4">5. LINK TO THIRD-PARTY SDK</h1>
      <div class="heading mt-2">
        <p>
          The App is integrated with a registered third party SDK which collects
          data when they are invoked. We ensure that our third party service
          provider takes extensive security measures in order to protect your
          personal information against loss, misuse or alteration of the data.
        </p>
        <p class="mt-3">
          Our third-party service provider employs separation of environments
          and segregation of duties and have strict role-based access control on
          a documented, authorized, need-to-use basis. The stored data is
          protected and stored by application-level encryption. They enforce key
          management services to limit access to data.
        </p>
      </div>
      <h1 class="genreal mt-4">
        6. ACCESSING YOUR INFORMATION / CONTACTING US
      </h1>
      <div class="heading mt-2">
        <p>
          At any point of time, the Users associated with Us can choose to
          edit/modify or delete/withdraw any permitted Personal Information
          shared for use of the Platform by sending us an email on
          support@narayaninsurancebrokers.com. In case of modification to any of
          the information shared to us on the platform, Users will be required
          to furnish supporting documents relating to change in Information for
          the purpose of verification by the Company.
        </p>
      </div>
      <h1 class="genreal mt-4">7. USER’S PRIVACY CONTROLS</h1>
      <div class="heading mt-2">
        <p>
          Every user has his/her privacy controls regarding the information that
          is collected by the Platform and how it is used. User can modify the
          device level settings & control the level of permission to be shared
          on the device. For example, Modify APP permissions on your Android
          device for access to Camera, delete the App from your device. You can
          also request to remove content from our servers based on applicable
          law or by writing to our Grievance Officer.
        </p>
      </div>
      <h1 class="genreal mt-4">8. SECURITY PRECAUTIONS</h1>
      <div class="heading mt-2">
        <p>
          The Platform intends to protect your information and to maintain its
          accuracy as confirmed by you. We implement reasonable physical,
          administrative and technical safeguards to help us protect your
          information from unauthorized access, use and disclosure. For example,
          we provide strict & stringent encryption. All information is
          transmitted with SSL 2048 bit AES Encryption mechanism or better over
          the internet as required by the applicable IT laws for Data
          Transmission. We also require that our registered third party service
          providers protect such information from unauthorized access, use and
          disclosure.
        </p>

        <p class="mt-3">
          Our products & services are equipped with the state of the art
          technology to ensure our systems maintain strong security measures and
          the overall data and privacy. Security design allow us to defend our
          systems ranging from low hanging issue up to sophisticated attacks.
        </p>
      </div>
      <h1 class="genreal mt-4">9. PHISHING</h1>
      <div class="heading mt-2">
        <p>
          "Phishing" usually occurs when users of a website are induced by an
          individual/entity into divulging sensitive personal data by using
          fraudulent websites and/ or e-mail addresses. In the event You provide
          information to a website or respond to an e-mail which does not belong
          to Us or is not connected with Us in any manner, You will be a victim
          to Phishing. We do not send e-mails requesting a user for payment
          information, user name or passwords. However, We may verify the user
          name, password etc. provided by You from time to time.
        </p>
      </div>
      <h1 class="genreal mt-4">10. SEVERABILITY AND EXCLUSION</h1>
      <div class="heading mt-2">
        <p>
          We have taken every effort to ensure that this Policy adheres with the
          applicable laws. The invalidity or unenforceability of any part of
          this Policy shall not prejudice or affect the validity or
          enforceability of the remainder of this Policy. This Policy does not
          apply to any information other than the information collected by NIBPL
          through the Platform. This Policy shall be inapplicable to any
          unsolicited information You provide Us through the Platform or through
          any other means. This includes, but is not limited to, information
          posted in any public areas of the Website / App. All unsolicited
          information shall be deemed to be non-confidential and the Company
          shall be free to use and/ or disclose such unsolicited information
          without any limitations.
        </p>
      </div>
      <h1 class="genreal mt-4">11. NO WAIVER</h1>
      <div class="heading mt-2">
        <p>
          The rights and remedies of available under this Policy may be
          exercised as often as necessary and are cumulative and not exclusive
          of rights or remedies provided by law. It may be waived only in
          writing. Delay in exercising or non-exercise of any such right or
          remedy does not constitute a waiver of that right or remedy, or any
          other right or remedy.
        </p>
      </div>
      <h1 class="genreal mt-4">12. GREVIANCE OFFICER</h1>
      <div class="heading mt-2">
        <p>
          If You require any information or clarification regarding the use of
          Your personal information or this Privacy Policy, You can contact us
          at supporthello@narayanwealth.com
        </p>
        <p class="mt-3">
          If You have grievance with respect to use of Your personal
          information, please write to supporthello@narayanwealth.com
        </p>
        <p class="mt-3">
          The Company is a company based in India. By browsing/using the
          Platform, You hereby acknowledge that the Company is not responsible
          or liable in any manner to comply with any local laws of Your
          territory except India.
        </p>
      </div>
      <h1 class="genreal mt-4">13. CHANGES IN THIS PRIVACY STATEMENT</h1>
      <div class="heading mt-2">
        <p class="m-4">
          We reserve the right to modify this privacy statement at any time by
          posting the same on the website/app, so please review it frequently on
          the website/app. If we materially change our privacy practices we may
          notify you by sending an email or by posting a notice on our
          website/app.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

body {
  background: #f2f2f2;
  width: 100%;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f2f2f2;
  flex-wrap: wrap;
}

.title {
  text-align: center;
  color: #c7a84b;
  font-size: 2em;
  font-weight: 800;
}

.heading {
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  margin: auto 10%;
}

.heading p {
  color: #263ba0;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.64px;
  text-align: justify;
}

.genreal {
  color: #c7a84b;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 240% */
  margin-left: 10%;
}

.heading .facility {
  color: #c7a84b;
}

.heading .do {
  color: #c7a84b;
  font-size: 20px;
  margin: 10px 0px;
  font-weight: 700;
}

.email-link {
  color: #c7a84b;
  text-decoration: none;
}

.key-info {
  color: #c7a84b;
}
</style>