<template>
  <div>
    <section id="sec">
      <!-- <div class="navbar">
        <img src="../images/logo.png" alt="" class="log1" /> 

        <div class="col-sm-2"></div>

        <div class="logo2">
          <img src="../images/nav-btn.png" alt="" class="log2" />
          <div class="nav-text">
            <div class="t1">Arun Kumar</div>
            <div class="t2">
              Male, 41 Yrs , Non-Smoker , Salaried
              <span><img src="Group 1042.png" alt="" /></span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="dropbar">
        <div class="dropmenu">
          <div>
            <select name="" id="">
              <option>Coverage: D50 Lacs</option>
            </select>
          </div>
          <div>
            <select name="" id="">
              <option>Cover upto: 65 years</option>
            </select>
          </div>
          <div>
            <select name="" id="">
              <option>Payment frequency: Monthly</option>
            </select>
          </div>
          <div>
            <select name="" id="">
              <option>Sort by: Relevance</option>
            </select>
          </div>
        </div>
      </div>
      <div class="plans">
        <div class="p1">
          <h2>7 Plans for You</h2>
          <p>Prices are inclusive of GST</p>
        </div>
        <div class="p2">
          <div class="p22">
            <h2>Base Plan</h2>
            <p>Starts @ D1299</p>
          </div>
          <div class="p21">Return of Premium</div>
          <div class="p21">Whole Life</div>
        </div>
        <div></div>
      </div>
      <div class="box1">
        <div class="box1-f1">
          <img src="../images/hdfc.png" alt="" />
          <h1>Click 2 Protect Life</h1>
          <h2>Coverage: 50Lacs</h2>
          <h2>Cover upto: 65 Years</h2>
          <h2>Claim Settled: 98.7%</h2>
          <button>&#8377;1,599.00 / Month</button>
        </div>
        <div class="box1-f2">
          <div class="box1-f21">
            <img src="../images/youtube.png" alt="" />
            <h3>Watch Video</h3>
            <h3 class="th1">Tax Benefits</h3>
            <h3 class="th2">Plan Details</h3>
          </div>
          <div class="box1-f22">
            <input type="checkbox" name="" id="" /><span>Add to Compare</span>
          </div>
        </div>
      </div>
      <div class="box1">
        <div class="box1-f1">
          <img src="../images/egon.png" alt="" />
          <h1>Click 2 Protect Life</h1>
          <h2>Coverage: 50Lacs</h2>
          <h2>Cover upto: 65 Years</h2>
          <h2>Claim Settled: 98.7%</h2>
          <button>&#8377;E2,349.00 / Month</button>
        </div>
        <div class="box1-f2">
          <div class="box1-f21">
            <img src="../images/youtube.png" alt="" />
            <h3>Watch Video</h3>
            <h3 class="th1">Tax Benefits</h3>
            <h3 class="th2">Plan Details</h3>
          </div>
          <div class="box1-f22">
            <input type="checkbox" name="" id="" /><span>Add to Compare</span>
          </div>
        </div>
      </div>
      <div class="box1">
        <div class="box1-f1">
          <img src="../images/metlife.png" alt="" />
          <h1>Click 2 Protect Life</h1>
          <h2>Coverage: 50Lacs</h2>
          <h2>Cover upto: 65 Years</h2>
          <h2>Claim Settled: 98.7%</h2>
          <button>&#8377; E1,999.00 / Month</button>
        </div>
        <div class="box1-f2">
          <div class="box1-f21">
            <img src="../images/youtube.png" alt="" />
            <h3>Watch Video</h3>
            <h3 class="th1">Tax Benefits</h3>
            <h3 class="th2">Plan Details</h3>
          </div>
          <div class="box1-f22">
            <input type="checkbox" name="" id="" /><span>Add to Compare</span>
          </div>
        </div>
      </div>

      <!-- <div class="footer_1">
        <div class="footer_head">
          <h1>Careers</h1>
          <h1>Disclosures</h1>
          <h1>Privacy Policy</h1>
          <h1>Terms</h1>
        </div>
      </div>

      <div class="footerline_1"></div>
      <div class="footerline_2"></div>

      <div class="copy_rights">
        <div>
          <h1>© 2021 Narayan Insurance Brokers.</h1>
        </div>
        <div class="all">
          <h1>All Rights Reserved.</h1>
        </div>
      </div>

      <div class="address">
        <h1>East Patel Nagar, New Delhi</h1>
        <a href="hello@narayaninsurancebrokers.com"
          >hello@narayaninsurancebrokers.com</a
        >
      </div> -->
    </section>
  </div>
</template>

<script>
export default {
  name: "Plans1",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.log1 {
  width: 80px;
  height: 80px;
}

#sec {
  /* height: 100vh; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 200px;
  padding: 30px;
}

.logo2 {
  display: flex;
  gap: 120px;
  align-items: center;
}

.nav-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 400px;
}

.t1 {
  position: absolute;
  left: -100px;
  width: 139px;
  border-radius: 32px;
  border: 2px;
  background-color: #3b53cb;
  border: 2px solid #c7a84b;
  padding: 12px;
  color: #f2f2f2;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

.t2 {
  width: 453px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  padding: 10px;
  padding-left: 50px;
  color: #3b53cb;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

/* .t2 input {
    width: 453px;
    border-radius: 32px;
    border: 2px solid #C7A84B;
    padding: 10px;
    padding-left: 50px;
    color: #3B53CB;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    padding-left: 20px;
} */

.t2 span {
  margin-left: 80px;
}

.dropmenu {
  width: 922px;
  height: 57px;
  border-radius: 8px;
  margin-left: 60px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dropmenu select {
  padding: 8px;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

#sec {
  background-color: #f2f2f2;
}

.dropbar {
  height: 97px;
  background-color: #ffffff;
  border-bottom: 2px solid #c7a84b;
  margin: auto 13%;
}

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 480px;
  gap: 50px;
  padding: 40px 10px;
  margin-left: 60px;
}

.p2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plans h2 {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #c7a84b;
}

.p1 p {
  font-size: 12px;
}

.p2 p {
  font-size: 14px;
}

.p22 {
  padding: 8px;
  border: 1px solid #c7a84b;
  border-radius: 8px;
  background: #fff;
}

.p21 {
  padding: 18px 8px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-left: 10px;
  color: #474747;
}

@media screen and (max-width: 608px) {
  #sec {
    width: 608px;
    overflow: hidden;
  }

  .t2 span {
    margin: 20px;
  }

  .t2 {
    width: 400px;
  }

  .navbar {
    margin-left: 0px;
    width: 608px;
    position: relative;
    margin-bottom: 100px;
  }

  .dropmenu {
    width: 608px;
    margin-left: 0px;
  }

  .nav-text {
    position: absolute;
    left: 350px;
    top: 150px;
  }

  body {
    width: 608px;
  }

  .dropbar {
    width: 608px;
  }

  .plans {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  #sec {
    width: 1024px;
    overflow: hidden;
  }

  .t2 span {
    margin: 20px;
  }

  .t2 {
    width: 400px;
  }

  .navbar {
    margin-left: 0px;
    width: 1024px;
  }

  .logo2 {
    margin: 0px 100px;
  }

  .dropmenu {
    width: 1024px;

    margin-left: 0px;
  }

  body {
    width: 1024px;
  }

  .dropbar {
    width: 1024px;
  }

  .plans {
    margin-right: 320px;
  }
}

.box1 {
  width: 1118px;
  border-radius: 8px;
  margin-left: 200px;
  margin-top: 40px;
}

.box1-f21 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  margin-left: 40px;
}

.box1-f2 .th1,
.box1-f2 .th2 {
  margin-left: 30px;
}

.box1-f2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
}

.box1-f2 h3,
.box1-f22 span {
  font-size: 12px;
  font-weight: 600;
  color: #3b53cb;
  margin-left: 10px;
}

.box1-f1 {
  margin-bottom: 3px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
}

.box1-f1 h1 {
  font-size: 16px;
  color: #3b53cb;
}

.box1-f1 h2 {
  font-size: 14px;
  color: #474747;
  font-weight: 600;
}

.box1-f1 button {
  padding: 8px 20px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
}

@media screen and (max-width: 608px) {
  .box1 {
    width: 608px;
    margin-left: 0px;
  }

  .box1-f21,
  .box1-f22 {
    width: 608px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .box1 {
    width: 1024px;
    margin-left: 0px;
  }

  .box1-f21,
  .box1-f22 {
    width: 1024px;
    margin-left: 0px;
  }
}

/* footer stats */

.footer_1 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 100px;
}

.footer_head h1 {
  display: inline-block;
  padding: 15px;
}
.footer_1 h1 {
  color: #3b53cb;
  text-align: center;
  font-size: 16px;

  font-weight: 700;
  line-height: 48px;
}

.footerline_1 {
  border-bottom: 4px solid #c7a84b;
}

.footerline_2 {
  margin-top: 1px;
  border-bottom: 4px solid #3b53cb;
}

@media (max-width: 449px) {
  .footer_head h1 {
    display: inline-block;
    padding: 2px;
    font-size: 13px;
  }
}

.dot {
  width: 30px;
}

.copy_rights {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.copy_rights h1 {
  color: #c7a84b;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
}

.all {
  padding-left: 40px;
}

@media (max-width: 626px) {
  .copy_rights h1 {
    padding-top: 10px;
    font-size: 14px;
    line-height: 15px;
  }
  .all {
    padding-left: 10px;
  }
}

.address {
  display: flex;
  justify-content: center;
}

.address h1 {
  color: #273786;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.12px;
  padding: 10px;
}
.address a {
  color: rgba(199, 168, 75, 1);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1.12px;
  padding: 10px;
}

@media (max-width: 523px) {
  .address h1 {
    font-size: 12px;
  }
  .address a {
    font-size: 12px;
  }
}

@media (max-width: 476px) {
  .address h1 {
    font-size: 10px;
  }
  .address a {
    font-size: 10px;
  }
}

/* footer ends */
</style>