<template>
  <div>
    <section id="sec">
      <div>
        <div class="d-flex justify-content-between" style="margin-right: 150px">
          <div class="main_tabs">
            <ul>
              <li
                @click="selectedLinks = 'Lead Info'"
                :class="{ activelink: selectedLinks === 'Lead Info' }"
              >
                Lead Info
              </li>
              <li
                @click="selectedLinks = 'Lead Stats'"
                :class="{ activelink: selectedLinks === 'Lead Stats' }"
              >
                Lead Stats
              </li>
              <li
                v-if="isAdmin"
                @click="selectedLinks = 'Lead Teams'"
                :class="{ activelink: selectedLinks === 'Lead Teams' }"
              >
                Team
              </li>
            </ul>
          </div>
          <div class="add_role d-flex">
            <div class="mx-4">
              <button @click="importData">Download Leads</button>
            </div>
            <button v-if="isAdmin" @click="toggleAddAgent()">Add Roles</button>
            <div style="margin-left: 20px" class="">
              <button @click="fetchLeads(false)">Reload Leads</button>
            </div>
            <div class="show-leads" v-if="haveExtraLeads">
              <p style="font-size: 15px">
                {{ extraLeadsCount > 9 ? "9+" : extraLeadsCount }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4" v-show="selectedLinks === 'Lead Info'">
        <div class="dropbar">
          <div class="dropmenu">
            <label style="color: #3b53cb">Start Date</label>
            <input
              type="date"
              v-model="startDate"
              @input="changeDate"
              :max="maxStartDateVal"
            />
            <label style="color: #3b53cb">&nbsp;End Date&nbsp;</label>
            <input
              type="date"
              v-model="endDate"
              @input="changeDate"
              :max="maxEndDateVal"
            />

            <div>
              <select v-model="selectedCityId" @change="handleUserFilterChange">
                <option value="-1">Location</option>
                <option
                  v-for="(item, key) in agentAndCCLocationInfo"
                  :key="key"
                  :value="item[0]"
                >
                  {{ item[1].city_name }}
                </option>
              </select>
            </div>
            <div>
              <select v-model="selectedUserId" @change="handleFilterChange">
                <option value="-1">Agents/CC</option>
                <option
                  v-for="(item, key) in filteredUserList"
                  :key="key"
                  :value="item[0]"
                >
                  {{ item[1].user_name }}
                </option>
              </select>
            </div>

            <div>
              <select v-model="selectedLeadType" @change="handleFilterChange">
                <option value="0">Lead Type</option>
                <option value="1">New Policy</option>
                <option value="2">Renewal</option>
              </select>
            </div>

            <div>
              <select
                v-model="selectedStatusFilterValue"
                @change="handleFilterChange"
              >
                <option value="All">All</option>
                <option v-for="(info, index) in statusData" :key="index">
                  {{ info.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-4" v-show="selectedLinks === 'Lead Info'">
        <div class="">
          <input
            type="text"
            class="search_name"
            v-model="searchQuery"
            placeholder="Search by Name Or Phone Number"
          />
        </div>
        <div
          class="box1"
          v-for="(lead, index) in filteredLeadsData"
          :key="index"
        >
          <div class="box1-f1">
            <div class="box1-f3">
              <h1>Lead : {{ lead.lead_display_id }}</h1>
              <p style="color: red">
                {{
                  lead.created_at_original && isOverdue(lead)
                    ? "Handling this lead is overdue"
                    : ""
                }}
              </p>
              <h1>
                Created on : {{ lead.created_at }} {{ lead.created_at_time }}
              </h1>
            </div>
            <div class="box1-f4">
              <div class="leads_right">
                <!-- <button v-if="isAdmin || isCustomerCare" @click="toggleTransaction(lead.id)">Add Transaction</button> -->

                <button
                  v-if="isDisplayDuplicateButton(lead)"
                  @click="duplicateLead(lead)"
                  :id="'addDupBtn_' + lead.id"
                >
                  <span>Duplicate Lead</span>
                  <span
                    class="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                    :id="'addDupBtnSpinner_' + lead.id"
                    style="display: none"
                  ></span>
                </button>
                <button
                  v-if="isDisplayCCButton(lead)"
                  @click="toggleCC(lead.id)"
                >
                  Assign CC
                </button>
                <button
                  v-if="isDisplayStatusButton(lead)"
                  @click="toggleStatus(lead.id)"
                >
                  Update Status
                </button>
                <button
                  v-if="isDisplayAgentButton(lead)"
                  @click="toggleAgent(lead.id)"
                >
                  Assign Field Agent
                </button>
                <button
                  v-if="isDisplayCommentsButton(lead)"
                  @click="toggleModal(lead.id)"
                >
                  Add Comments
                </button>
                <button
                  v-if="isDisplayCashbackButton(lead)"
                  @click="toggleCashback(lead.id)"
                >
                  Approve Cashback
                </button>
                <div class="d-flex align-items-center gap-1">
                  <div
                    style="cursor: pointer"
                    @click="openOrCloseComment(lead.id)"
                  >
                    <svg
                      v-if="lead.isLeadInfoOpen == false"
                      width="12"
                      viewBox="0 0 12 8"
                      fill="none"
                    >
                      <path
                        d="M6 7.375L11.1962 0.976562H0.803848L6 7.375Z"
                        fill="#BB2649"
                      />
                    </svg>
                    <svg
                      v-else
                      width="13"
                      height="6"
                      viewBox="0 0 13 6"
                      fill="none"
                    >
                      <path
                        d="M6.5 0L12.1292 5.25H0.870835L6.5 0Z"
                        fill="#BB2649"
                      />
                    </svg>
                  </div>

                  <div class="d-flex align-items-center">
                    <h4 class="no_of_count">comments</h4>
                    <div v-if="lead.leadInof">
                      <h2>: {{ lead.leadInof.length }}</h2>
                    </div>
                    <div v-else>
                      <h2>: {{ lead.comments_count }}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="leads-status-info">
              <div class="d-flex align-items-center">
                <h2>Current status :</h2>
                <p class="text-clr">
                  {{ this.getLeadStatusDisplayName(lead.status) }}
                </p>
              </div>
              <div class="d-flex">
                <h2>CC Name : </h2>
                <p> {{ lead.assigned_cc_info ? lead.assigned_cc_info.user_name :"" }}</p>
              </div>
            </div> -->
            <div class="user_info">
              <div class="d-flex align-items-center">
                <h3>Lead Type</h3>
                <h2>: {{ this.getLeadType(lead.lead_type) }}</h2>
              </div>
              <div class="d-flex align-items-center">
                <h3>Lead status</h3>
                <h2>
                  : {{ this.getLeadStatusDisplayName(lead.status) }}
                  {{ this.getCallbackTime(lead.status, lead.callback_at) }}
                  {{
                    this.getAppointmentTime(lead.status, lead.field_visit_at)
                  }}
                </h2>
              </div>
              <div class="d-flex align-items-center">
                <h3>Name</h3>
                <h2>
                  :
                  {{ lead.lead_user_info ? lead.lead_user_info.user_name : "" }}
                </h2>
              </div>
              <div class="d-flex align-items-center">
                <h3>Phone No</h3>
                <h2>
                  :
                  {{
                    lead.lead_user_info ? lead.lead_user_info.user_phoneno : ""
                  }}
                </h2>
              </div>

              <div
                v-if="formattedDOB[index] != NaN - NaN - NaN"
                class="d-flex align-items-center"
              >
                <h3>Date of Birth</h3>
                <h2>: {{ formattedDOB[index] }}</h2>
              </div>

              <div
                v-if="lead.lead_user_info?.corporate_id != 0"
                class="d-flex align-items-center"
              >
                <h3>Corporate Id</h3>
                <h2>
                  :
                  {{
                    lead.lead_user_info
                      ? getCorporateNameValue(lead.lead_user_info.corporate_id)
                      : ""
                  }}
                </h2>
              </div>

              <div class="d-flex align-items-center">
                <h3>Interested Policies</h3>
                <p style="width: 80%">: {{ lead.interested_products }}</p>
              </div>
              <div class="d-flex align-items-center">
                <h3>Field Agent</h3>
                <h2>
                  :
                  {{
                    lead.assigned_agent_info
                      ? lead.assigned_agent_info.user_name +
                        getLocation(lead.assigned_agent_info)
                      : ""
                  }}
                </h2>
              </div>
              <div class="d-flex align-items-center">
                <h3>CC Name</h3>
                <h2>
                  :
                  {{
                    lead.assigned_cc_info
                      ? lead.assigned_cc_info.user_name +
                        getLocation(lead.assigned_cc_info)
                      : ""
                  }}
                </h2>
              </div>
            </div>
            <div class="d-flex gap-5">
              <div class="d-flex gap-5 mt-2">
                <button
                  style="height: 35px; padding-bottom: 20px"
                  v-if="isAdmin && lead.status >= 4"
                  @click="showTransaction(lead)"
                >
                  Show Transaction
                </button>
              </div>
              <div class="d-flex gap-5 mt-2">
                <button
                  style="height: 35px; padding-bottom: 20px"
                  v-if="isAdmin"
                  @click="handleLeadClick(lead)"
                >
                  Show Documents
                </button>
              </div>
              <div class="d-flex gap-5 mt-2">
                <button
                  style="height: 35px; padding-bottom: 25px"
                  v-if="isAdmin"
                  @click="openDocumentModal(lead)"
                >
                  Add Documents
                </button>
              </div>
            </div>
          </div>

          <div v-if="lead.isLeadInfoOpen">
            <div
              class="box1-f2"
              v-for="(info, index) in lead.leadInof"
              :key="index"
            >
              <div class="">
                <div class="updated_top">
                  <h5 class="txt-size">
                    Updated by : {{ info.created_by.user_name }}
                  </h5>
                  <h5 class="txt-size">
                    Update on: {{ info.created_at }} {{ info.created_at_time }}
                  </h5>
                </div>
                <div class="txt-size1 updated_top">
                  <p>{{ info.description }}</p>
                  <div v-if="info.doc_infos.length > 0">
                    <div v-for="docInfo in info.doc_infos" :key="docInfo.id">
                      <a :href="docInfo.file_uri" target="_blank">Attachment</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" v-show="selectedLinks === 'Lead Stats'">
        <LeadsStats
          v-if="isDataLoaded"
          :leadData="leadsData"
          :statusList="statusData"
          :fieldAgemtData="fieldAgemtData"
          :ccData="ccAgentData"
          :userList="userList"
          @FilterChaned="updateFilter"
          @DateFilterChanged="updateDateFilter"
        />
      </div>
      <div class="" v-show="selectedLinks === 'Lead Teams'">
        <LeadTeams
          :employeeData="employeeData"
          @edit_emplyoee="edit_emplyoee"
        />
      </div>

      <div v-if="showModal">
        <UpdatePopup
          @close="toggleModal"
          @text-submitted="handleTextSubmission"
          @file-uploaded="handleFileSubmission"
          @video-uploaded="handleVideoSubmission"
          :lead-data="leadTobeUpdated"
        />
      </div>
      <div class="" v-if="UpdateStatusModel">
        <UpdateStatus
          @close="toggleStatus"
          :statusList="statusData"
          :leadData="leadTobeUpdated"
          :isAdmin="isAdmin"
          @selected-status="assingStatusToLead"
        />
      </div>
      <div class="" v-if="toggleAgentModel">
        <AssignAgentPopup
          @close="toggleAgent"
          :agenntList="agentData"
          @selected-agent="assingAgentToLead"
        />
      </div>
      <div class="" v-if="toggleCCModel">
        <AssignCCPopup
          @close="toggleCC"
          :ccList="ccData"
          @selected-cc="assingCCToLead"
        />
      </div>
      <div v-if="toggleCallBackModel">
        <CallbackPopup
          :leadId="leadId"
          :lead-id="leadId"
          @close="toggleCallBackPopup"
          :lead-data="selectedLead"
          @callback-submitted="handleCallBackSubmission"
        />
      </div>

      <div class="" v-if="addTransactionModel">
        <AddTransaction
          :selectedLeadForTx="selectedLeadForTx"
          @selectedLeadForTx="selectedLeadForTx"
          @close="closeTransaction"
        />
      </div>

      <div class="" v-if="addPolicyIssuedModel">
        <UpdatePolicyInfo
          :selectedLeadForPolicyIssued="selectedLeadForPolicyIssued"
          @selectedLeadPolicyIssued="selectedLeadForPolicyIssued"
          @close="closePolicyIssued"
        />
      </div>

      <div class="" v-if="addScheduleAppointmentModel">
        <AddAppointment
          :selectedLeadForSA="selectedLeadForSA"
          @selectedLeadForSA="selectedLeadForSA"
          @close="closeScheduleAppointment"
          @appointment-submitted="handleAppointmentSubmission"
        />
      </div>
      <div class="" v-if="AddAgentModel">
        <AddAgent @close="toggleAddAgent" @added-agent="agentAdded" />
      </div>

      <div class="" v-if="ShowTranscation">
        <ShowTransaction
          :transInfo="transactionInfo"
          @close-show-transaction="showTransaction()"
        />
      </div>
      <div class="" v-if="CashBack">
        <CashBack
          @close="toggleCashback"
          @selected-status="assingStatusToLead"
        />
      </div>

      <div class="backdrop1" v-if="showDocModal">
        <div class="modal1">
          <div class="d-flex justify-content-between">
            <div>
              <h1 class="title">User Documnets</h1>
            </div>
            <div class="close-icon" @click="closePopup">
              <img src="../images/close-popup.png" alt="" />
            </div>
          </div>
          <div class="">
            <div class="d-flex flex-wrap">
              <div
                v-for="(doc, index) in selectedDocs"
                :key="index"
                @click="downloadDocument(doc.doc_url)"
                class="user_docs mx-2 my-2"
              >
                <div class="d-flex gap-2 align-items-center w-100">
                  {{ doc.doc_name }}
                  <div v-html="downloadIcon"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 d-flex justify-content-end">
            <div class="user_docs w-25" @click="downloadAllDocuments">
              Download All Docs
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Modal -->
  <div class="modal" :class="{ 'is-active': addDocModel }">
    <div class="modal-background" @click="closeDocumentModal"></div>
    <div class="model1">
      <div class="box">
        <div class="d-flex justify-content-between align-items-center">
          <h3>Add User Document</h3>
          <div class="close" @click="closeDocumentModal">X</div>
        </div>
        <div class="field">
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter Document Name"
              v-model="fileName"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="file-label d-flex gap-2">
              <input
                type="file"
                @change="handleFileSelect"
                ref="fileInput"
                name="user_doc"
                accept=".pdf, image/*"
                style="display: none"
              />
              <div class="button is-info pe-2">
                <span>Choose File</span>
              </div>

              <!-- <img  src="https://img.icons8.com/color/48/attach.png" /> -->
              
              <span class="file-icon ms-3 mt-3">
                <i class="fas fa-upload"></i>
              </span>
            </label>
            <iframe
              class="my-2 mx-3"
              v-if="selectedFile"
              :src="fileURL"
              style="width: 100%; height: 200px"
            ></iframe>
          </div>
          <p v-if="errorMessage" style="color: red;margin:10px">{{ errorMessage }}</p>
        </div>
        <div class="field">
          <div class="control d-flex align-items-center justify-content-center">
            <button class="button3" @click="addDocument">
              <span v-if="!loading">Add Document</span>
              <span v-else>
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Adding Document...
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeDocumentModal"
    ></button>
  </div>

  </div>
</template>
  
<script>
import { getWelthUserId } from "@/util/loginData";
import UpdatePopup from "./UpdatePopup.vue";
import UpdateStatus from "./UpdateStatus.vue";
import AssignAgentPopup from "./AssignAgentPopup.vue";
import AssignCCPopup from "./AssignCCPopup.vue";
import CallbackPopup from "./CallbackPopup.vue";
import LeadsStats from "./LeadsStats.vue";
import AddTransaction from "./AddTransaction.vue";
import AddAppointment from "./AddAppointment.vue";
import AddAgent from "./AddAgent.vue";
import UpdatePolicyInfo from "./UpdatePolicyInfo.vue";
import ShowTransaction from "./ShowTransaction.vue";
import CashBack from "./CashBack.vue";
import LeadTeams from "./LeadTeams.vue";
import { downloadIcon } from "@/assets/svg";

import {
  getSession,
  isAuthenticated,
  getCorporateName,
} from "../util/loginData";
import {
  formatDate,
  formatTime,
  loadInsCategory,
} from "../util/commonFunctions";
import axios from "axios";
import {
  LEAD_FETCH_URL,
  LEAD_INFO_URL,
  LEAD_INFO_FETCH_URL,
  STATUS_VALUES,
  AGENT_FETCH_URL,
  CC_FETCH_URL,
  UPDATE_LEAD_URL,
  CREATE_LEAD_URL,
  STATUS_LIST_FETCH_URL,
  GET_CITY_INFO,
  EMPLOYEE_FETCH_URL,
  VENDOR_ID,
  GET_WELTH_USER_DOCS,
  UPLOAD_USER_DOC_DET,
  UPLOAD_USER_DOCUMENT,
} from "../util/constants";



export default {
  name: "Leads",

  data() {
    return {
      links: ["Leads Info", "Lead Stats", "Lead Teams"],
      selectedLinks: "Leads Info",
      submittedText: "",
      submittedTextArray: [],
      showModal: false,
      UpdateStatusModel: false,
      openCommentSection: true,
      addTransactionModel: false,
      addPolicyIssuedModel: false,
      addScheduleAppointmentModel: false,
      isAuthenticated: isAuthenticated(),
      sessionData: getSession(),
      isCustomerCare: false,
      isAgent: false,
      isUser: false,
      isAdmin: false,
      toggleAgentModel: false,
      AddAgentModel: false,
      toggleCCModel: false,
      ShowTranscation: false,
      transactionInfo: null,
      CashBack: false,
      leadsData: null,
      currentLeadCount: 0,
      extraLeadsCount: 0,
      haveExtraLeads: false,
      visibleLeadsData: [],
      filteredLeadsData: [],
      agentData: null,
      employeeData: null,
      ccData: null,
      leadStatusList: null,
      leadTobeUpdated: null,
      selectedLead: null,
      selectedLeadForTx: null,
      selectedLeadForPolicyIssued: null,
      selectedLeadForSA: null,
      leadId: -1,
      toggleCallBackModel: false,
      selectedStatusValue: "All",
      selectedStatusFilterValue: "All",
      selectedLeadType: 0,
      selectedUserId: -1,
      isDataLoaded: false,
      statusData: [],
      userList: new Map(),
      filteredUserList: new Map(),
      allCityInfo: [],
      selectedCityId: -1,
      agentAndCCLocationInfo: new Map(),
      showLocation: false,
      locationfilterForLeads: false,
      locationfilterForUser: !this.locationfilterForLeads,
      fieldAgemtData: new Map(),
      ccAgentData: new Map(),
      loggedInUserId: "",
      startDate: "",
      endDate: "",
      startDateVal: new Date(),
      endDateVal: new Date(),
      maxStartDateVal: null,
      maxEndDateVal: null,
      timerId: null,
      delay: 10000,
      xlsxScriptLoaded: false,
      insCategory: [],
      searchQuery: "", // Holds the value of the search input field
      leadsData: [],
      UserDoc: [],
      UserId: null,
      showDocModal: false,
      selectedDocs: [],
      downloadIcon: downloadIcon,
      addDocModel:false,
      fileName: "",
      selectedFile: null,
      loading: false,
      errorMessage: '',
      currentUser:null,
    };
  },

  components: {
    UpdatePopup,
    UpdateStatus,
    AssignAgentPopup,
    CallbackPopup,
    AssignCCPopup,
    LeadsStats,
    AddTransaction,
    AddAppointment,
    UpdatePolicyInfo,
    AddAgent,
    ShowTransaction,
    CashBack,
    LeadTeams,
  },

  mounted() {
    console.log("Leads page, mounted");
    // console.log("Current user id: " + getWelthUserId());
    this.loggedInUserId = getWelthUserId();
    this.fetchLeads(true);
    this.getAgentList();
    this.getCCList();
    this.getEmployeeList();
    // console.log('mounted sessionData:', this.sessionData);

    if (this.sessionData) {
      // console.log('mounted role:', this.sessionData.role);
      this.isCustomerCare = this.sessionData.role == 3;
      this.isAgent = this.sessionData.role == 1;
      this.isUser = this.sessionData.role == 0;
      this.isAdmin = this.sessionData.role == 2 || this.sessionData.role == 4;
    }
    // console.log('mounted isAdmin:', this.isAdmin);
    // console.log('mounted isAgent:', this.isAgent);
    // console.log('mounted isCC:', this.isCustomerCare);
    // console.log('mounted isUser:', this.isUser);
    if (this.isAdmin) {
      this.selectedLinks = "Lead Info";
    } else {
      this.selectedLinks = "Lead Stats";
    }
    this.maxDate();
    this.getCategory();
    this.loadXlsxScript();
    this.getWelthUserDoc();
  },

  beforeUnmount() {
    console.log("Leads page, Un-mounting...");
    // Call stopTimer when the component is about to be destroyed
    this.stopTimer();
  },

  methods: {
    getCorporateNameValue(corporateId) {
      //console.log(corporateId);
      return getCorporateName(corporateId);
    },

    openDocumentModal(lead) {
      console.log(lead.lead_user_info.user_id); 
      this.addDocModel = true;
      this.currentUser=lead.lead_user_info.user_id
      
    },
    closeDocumentModal() {
      this.addDocModel = false;
      
    },

    handleFileSelect(event) {
      if (event.target.files && event.target.files.length > 0) {
        const newFile = event.target.files[0];

        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (newFile.size > maxSizeInBytes) {
          // File size exceeds 2MB, show error message
          this.errorMessage = ' File size exceeds 5MB. Please choose a smaller file.';
          event.target.value = null; // Reset input
          return;
        }

        if (this.selectedFile !== newFile) {
          // New file selected
          this.selectedFile = newFile;
          this.fileURL = URL.createObjectURL(this.selectedFile);
        } else {
          // Same file selected again, reset input
          event.target.value = null;
        }
        this.errorMessage = '';
      }
    },


    async addDocument() {
      this.loading = true;
      if (!this.fileName || !this.selectedFile) {
        alert("Please Enter file Name and Select a Document.");
        this.loading = false;
        return;
      }
      const formData = new FormData();
      formData.append("user_doc", this.selectedFile);
      console.log(" this.selectedFile", this.selectedFile);
      console.log("this.currentUser : ", this.currentUser);

      let resp = await axios.post(UPLOAD_USER_DOCUMENT, formData);
      console.log(resp);
      let userData = {
        welth_user_id: this.currentUser,
        doc_url: resp.data.data.user_doc_url,
        doc_name: this.fileName,
      };
      if (resp.data.code == 200) {
        let uploadData = await axios.post(UPLOAD_USER_DOC_DET, userData);
        console.log(uploadData);
        this.closeDocumentModal();
        fetchLeads(false);
      }
      this.loading = false;
    },

    filterLeads() {
    if (!this.searchQuery) {
      this.filteredLeadsData = this.visibleLeadsData;
    } else {
      const query = this.searchQuery.toLowerCase();
      this.filteredLeadsData = this.visibleLeadsData.filter(lead => {
        const userName = lead.lead_user_info?.user_name?.toLowerCase() || '';
        const userPhone = lead.lead_user_info?.user_phoneno || '';
        return userName.includes(query) || userPhone.includes(query);
      });
    }
  },

    maxDate() {
      // console.log("maxDate");
      const today = new Date();
      const year = today.getFullYear();
      const month = `${today.getMonth() + 1}`.padStart(2, "0"); // Months are zero-indexed
      const day = `${today.getDate()}`.padStart(2, "0");
      this.maxStartDateVal = `${year}-${month}-${day}`;
      this.maxEndDateVal = `${year}-${month}-${day}`;
      // console.log(this.minDateVal);
      return this.maxDateVal;
    },

    isOverdue(currentLead) {
      // console.log("isOverdue " + currentLead.id);
      // console.log(currentLead);
      if (!currentLead.created_at_original) {
        return false;
      }
      if (currentLead.assigned_agent_info || currentLead.assigned_cc_info) {
        // console.log("agent or cc assigned");
        return false;
      }
      const now = new Date();
      const today7PM = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        19,
        0,
        0
      ); // 7 PM today
      const createdAt = new Date(currentLead.created_at_original);
      if (
        createdAt.toDateString() === now.toDateString() &&
        createdAt >= today7PM
      ) {
        console.log("Assigned after 7 PM, so ignore");
        return false;
      }
      // Calculate the time difference in minutes
      const timeDifferenceInMinutes = (now - createdAt) / (1000 * 60);
      // console.log(timeDifferenceInMinutes + " " + (timeDifferenceInMinutes > 30));
      return timeDifferenceInMinutes > 30;
    },

    isDisplayDuplicateButton(currentLead) {
      return this.isAdmin;
    },

    isDisplayCCButton(currentLead) {
      const excludeStatuses = [
        STATUS_VALUES.FLP_CANCELLED,
        STATUS_VALUES.FLP_COMPLETED,
        STATUS_VALUES.CASHBACK_APPROVED,
      ];
      return this.isAdmin && !excludeStatuses.includes(currentLead.status);
    },

    isDisplayStatusButton(currentLead) {
      const excludeStatuses = [
        STATUS_VALUES.FLP_CANCELLED,
        STATUS_VALUES.FLP_COMPLETED,
        STATUS_VALUES.CASHBACK_APPROVED,
      ];
      return (
        (this.isAdmin || this.isCustomerCare) &&
        !excludeStatuses.includes(currentLead.status)
      );
    },

    isDisplayAgentButton(currentLead) {
      const excludeStatuses = [
        STATUS_VALUES.FLP_CANCELLED,
        STATUS_VALUES.FLP_COMPLETED,
        STATUS_VALUES.CASHBACK_APPROVED,
      ];
      return (
        (this.isAdmin || this.isCustomerCare) &&
        !excludeStatuses.includes(currentLead.status)
      );
    },

    isDisplayCommentsButton(currentLead) {
      const excludeStatuses = [
        STATUS_VALUES.FLP_CANCELLED,
        STATUS_VALUES.FLP_COMPLETED,
        STATUS_VALUES.CASHBACK_APPROVED,
      ];
      return (
        (this.isAdmin || this.isCustomerCare || this.isAgent) &&
        !excludeStatuses.includes(currentLead.status)
      );
    },

    isDisplayCashbackButton(currentLead) {
      const includeStatuses = [STATUS_VALUES.FLP_COMPLETED];
      return this.isAdmin && includeStatuses.includes(currentLead.status);
    },

    edit_emplyoee() {
      //this.toggleAddAgent();
    },

    toggleAddAgent() {
      this.AddAgentModel = !this.AddAgentModel;
    },

    toggleCashback(id) {
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      this.CashBack = !this.CashBack;
    },

    showTransaction(lead) {
      console.log("showTransaction");
      // console.log(lead);
      if (lead) {
        this.transactionInfo = lead.order_details;
        console.log(lead);
      } else {
        this.transactionInfo = null;
      }
      this.ShowTranscation = !this.ShowTranscation;
      console.log("Show Transaction");
      // console.log(this.ShowTranscation);
    },

    updateFilter(userId, status) {
      console.log("updateFilter.............");
      this.selectedCityId = -1;
      this.selectedUserId = userId;
      this.selectedLeadType = 0;
      this.selectedStatusFilterValue = status;
      this.selectedLinks = "Lead Info";
      this.handleFilterChange();
    },

    updateDateFilter(status, startDateFromStat, endDateFromStat) {
      console.log("updateDateFilter.............");
      this.selectedCityId = -1;
      this.selectedUserId = -1;
      this.selectedLeadType = 0;
      this.selectedStatusFilterValue = status;
      this.selectedLinks = "Lead Info";
      this.startDate = startDateFromStat;
      this.endDate = endDateFromStat;
      this.changeDate();
    },

    getStausMap() {
      let sdata = new Map();
      this.statusData.forEach((s) => {
        sdata.set(s.status, 0);
      });
      return sdata;
    },

    fillDataFromLeads(userInfo, mapInfo, lead) {
      // console.log("fillDataFromLeads");
      //console.log(mapInfo);
      //console.log("fillDataFromLeads");
      let item = null;
      let id = userInfo.user_id;
      // if (this.userList.has(id) == false) {
      //     this.userList.set(id, userInfo);
      // }
      if (mapInfo.has(id)) {
        item = mapInfo.get(id);
      } else {
        item = this.getStausMap();
        mapInfo.set(id, item);
      }
      let countData = item.get(lead.status);
      item.set(lead.status, countData + 1);
      // console.log(mapInfo);
    },

    isOptionDisabled(optionValue, index) {
      console.log("isOptionDisabled " + optionValue);
      console.log(this.selectedStatusFilterValue);
      // Implement your logic to determine if the option should be disabled
      // For example, disable options with certain values
      return (
        this.selectedStatusFilterValue !== "" &&
        optionValue <= this.selectedStatusFilterValue
      );
    },

    getLocation(userInfo) {
      let retValue = "";
      if (this.showLocation) {
        if (userInfo.location != null) {
          let cityInfo = this.getCityInfo(userInfo.location.city_id);
          if (cityInfo != null) {
            retValue = " (city: " + cityInfo.city_name + ")";
          }
        }
      }
      return retValue;
    },

    async loadCityInfo() {
      // console.log("loadCityInfo called ");
      const response = await axios.get(GET_CITY_INFO + "0", {});
      // console.log(response.data.message);
      if (response.data.code == 200) {
        this.allCityInfo = response.data.data;
        // console.log("loadCityInfo size: " + this.allCityInfo.length);
        this.updatUserData();
        this.updatedStats();
      }
      // console.log(this.allCityInfo);
    },

    updatUserData() {
      // console.log("updatUserData");
      this.leadsData.forEach((lead) => {
        let userInfo = null;
        let id = 0;
        if (lead.assigned_agent_info != null) {
          userInfo = lead.assigned_agent_info;
          id = userInfo.user_id;
          if (this.userList.has(id) == false) {
            this.userList.set(id, userInfo);
          }
          if (userInfo.location != null) {
            let cityId = userInfo.location.city_id;
            if (this.agentAndCCLocationInfo.has(cityId) == false) {
              let cityInfo = this.getCityInfo(cityId);
              if (cityInfo) {
                this.agentAndCCLocationInfo.set(cityId, cityInfo);
              }
            }
          }
        }
        if (lead.assigned_cc_info != null) {
          userInfo = lead.assigned_cc_info;
          id = userInfo.user_id;
          if (this.userList.has(id) == false) {
            this.userList.set(id, userInfo);
          }
          if (userInfo.location != null) {
            let cityId = userInfo.location.city_id;
            if (this.agentAndCCLocationInfo.has(cityId) == false) {
              let cityInfo = this.getCityInfo(cityId);
              if (cityInfo) {
                this.agentAndCCLocationInfo.set(cityId, cityInfo);
              }
            }
          }
        }
      });
      for (const [key, value] of this.userList) {
        this.filteredUserList.set(key, value);
      }
      // console.log("user List: ", this.userList);
      // console.log("filter user List: ", this.filteredUserList);
    },

    handleUserFilterChange() {
      console.log("handleUserFilterChange");
      // console.log(this.locationfilterForLeads);
      this.locationfilterForLeads = this.selectedCityId != -1;
      this.filteredUserList.clear();
      console.log(this.selectedCityId);
      if (this.selectedCityId == -1) {
        for (const [key, value] of this.userList) {
          this.filteredUserList.set(key, value);
        }
      } else {
        for (const [key, value] of this.userList) {
          if (
            value.location !== null &&
            value.location.city_id === this.selectedCityId
          ) {
            this.filteredUserList.set(key, value);
            // console.log(this.filteredUserList);
          }
        }
      }
      this.selectedUserId = -1;
      this.handleFilterChange();
    },

    getCityInfo(cityId) {
      if (this.allCityInfo) {
        let matchedCity = this.allCityInfo.find(
          (item) => item.city_id === cityId
        );
        if (matchedCity !== null) {
          return matchedCity;
        }
      }
      return null;
    },

    handleSelectStatusChange() {
      console.log("handleSelectStatusChange");
      console.log(this.selectedStatusValue);
      if (this.selectedStatusValue == "Call Back") {
        console.log("handleSelectStatusChange Call Back option selected");
        // console.log(this.leadTobeUpdated);
        console.log(this.leadId);
        this.selectedLead = this.leadsData.find(
          (item) => item.id === this.leadId
        );
        console.log(this.selectedLead);
        this.toggleCallBackModel = !this.toggleCallBackModel;
      } else if (this.selectedStatusValue == "Payment Done") {
        console.log("handleSelectStatusChange Payment Done option selected");
        this.toggleTransaction(this.leadId);
      } else if (this.selectedStatusValue == "Schedule Appointment") {
        console.log(
          "handleSelectStatusChange Schedule Appointment option selected"
        );
        this.toggleScheduleAppointment(this.leadId);
      } else if (this.selectedStatusValue == "Policy Issued") {
        console.log("handleSelectStatusChange Policy Issued option selected");
        this.togglePolicyIssued(this.leadId);
      }
    },

    togglePolicyIssued(id) {
      this.selectedLeadForPolicyIssued = this.leadsData.find(
        (item) => item.id === id
      );
      if (this.selectedLeadForPolicyIssued.order_id === 0) {
        // Alert that Payment done needs to be done 1st.
        window.alert(
          "No transaction data available for this lead. Please update 'Payment Done' status."
        );
      } else {
        this.leadId = id;
        console.log(
          "Selected lead for PolicyIssued: ",
          this.selectedLeadForPolicyIssued
        );
        this.addPolicyIssuedModel = !this.addPolicyIssuedModel;
      }
    },

    toggleScheduleAppointment(id) {
      this.selectedLeadForSA = this.leadsData.find((item) => item.id === id);
      this.leadId = id;
      console.log(
        "Selected lead for ScheduleAppointment: ",
        this.selectedLeadForTx
      );
      this.closeScheduleAppointment();
    },

    closeScheduleAppointment() {
      this.addScheduleAppointmentModel = !this.addScheduleAppointmentModel;
    },

    toggleCallBackPopup() {
      console.log("toggleCallBackPopup");
      this.toggleCallBackModel = !this.toggleCallBackModel;
    },

    toggleModal(id) {
      this.showModal = !this.showModal;
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      this.leadId = id;
      console.log(this.leadTobeUpdated);
    },

    toggleStatus(id) {
      console.log("toggleStatus");
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      this.leadId = id;
      this.UpdateStatusModel = !this.UpdateStatusModel;
    },

    toggleAgent(id) {
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      this.leadId = id;
      this.toggleAgentModel = !this.toggleAgentModel;
    },

    async duplicateLead(leadToBeDuplicated) {
      console.log("duplicateLead");
      console.log(leadToBeDuplicated);
      // const buttonElement = document.getElementById('addDupBtn_' + lead.id);
      const buttonElement = $("#addDupBtn_" + leadToBeDuplicated.id);
      buttonElement.prop("disabled", true);
      // $("#addDupBtn").attr('disabled', 'disabled');
      // const buttonSpinner = document.getElementById('addDupBtnSpinner_' + lead.id);
      const buttonSpinner = $("#addDupBtnSpinner_" + leadToBeDuplicated.id);
      buttonSpinner.show();
      // $("#addDupBtnSpinner").show();
      const interestedProductsString = leadToBeDuplicated.interested_products;
      const interestedProductsArray = interestedProductsString.split(",");
      // Remove the last item if it's empty
      if (
        interestedProductsArray.length > 0 &&
        interestedProductsArray[interestedProductsArray.length - 1] === " "
      ) {
        interestedProductsArray.pop();
      }
      if (
        interestedProductsArray.length > 0 &&
        interestedProductsArray[interestedProductsArray.length - 1] === ""
      ) {
        interestedProductsArray.pop();
      }
      // console.log(interestedProductsArray);
      const interestedProductsJSONString = JSON.stringify(
        interestedProductsArray
      );
      console.log(interestedProductsJSONString);

      let data = {
        welth_user_id: leadToBeDuplicated.lead_user_info.user_id,
        lead_type: leadToBeDuplicated.lead_type,
        lead_category: leadToBeDuplicated.lead_category,
        interested_products: interestedProductsJSONString,
        vendor_id: VENDOR_ID,
      };
      console.log(data);

      const response = await axios.post(CREATE_LEAD_URL, data);
      if (response.data.code == 200) {
        console.log(response.data);
      }
      console.log(response.data.message);
      alert(response.data.message);
      setTimeout(() => {
        // $("#addDupBtn").removeAttr('disabled');
        // $("#addDupBtnSpinner").hide();
        buttonElement.prop("disabled", false);
        buttonSpinner.hide();
      }, 50);
    },

    toggleCC(id) {
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      this.leadId = id;
      this.toggleCCModel = !this.toggleCCModel;
    },

    toggleTransaction(id) {
      this.selectedLeadForTx = this.leadsData.find((item) => item.id === id);
      this.leadId = id;
      console.log("Selected lead for add TX: ", this.selectedLeadForTx);
      this.addTransactionModel = !this.addTransactionModel;
    },

    closeTransaction(isTxAdded, orderId, orderDetails) {
      console.log(
        "closeTransaction: isTxAdded: " + isTxAdded + " orderId: " + orderId
      );
      // console.log("closeTransaction: orderDetails: " + orderDetails);
      this.addTransactionModel = !this.addTransactionModel;
      if (isTxAdded) {
        // Payment done = 4
        this.updateLeadWithTx(
          this.selectedLeadForTx,
          STATUS_VALUES.PAYMENT_DONE,
          orderDetails
        );
      }
    },

    closePolicyIssued(isPolicyIssueSuccess, orderDetails) {
      if (isPolicyIssueSuccess) {
        // Policy issued status = 5
        // this.updateLead(this.selectedLeadForPolicyIssued, this.selectedLeadForPolicyIssued.assigned_agent_id, STATUS_VALUES.POLICY_ISSUED);
        this.updateLeadWithPolicyData(
          this.selectedLeadForPolicyIssued,
          this.selectedLeadForPolicyIssued.assigned_agent_id,
          STATUS_VALUES.POLICY_ISSUED,
          orderDetails
        );
      }
      this.addPolicyIssuedModel = !this.addPolicyIssuedModel;
    },

    openOrCloseComment(id) {
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      if (this.leadTobeUpdated.isLeadInfoOpen == false) {
        this.fetchLeadInfo(this.leadTobeUpdated);
        this.leadTobeUpdated.isLeadInfoOpen = true;
      } else {
        this.leadTobeUpdated.isLeadInfoOpen = false;
      }
    },

    updateCommentList(id) {
      console.log("updateCommentList");
      // console.log(id);
      this.leadTobeUpdated = this.leadsData.find((item) => item.id === id);
      this.fetchLeadInfo(this.leadTobeUpdated);
    },

    getLeadType(id) {
      return id == 1 ? "New Policy" : "Renewal";
    },

    getCategoryName(categoryId) {
      console.log("getCategoryName");
      console.log(categoryId);
      console.log(this.insCategory);
      if (this.insCategory) {
        const insObj = this.insCategory.find((item) => item.id === categoryId);
        if (insObj) {
          console.log(insObj.value);
          return insObj.value;
        }
      }
    },

    async getCategory() {
      this.insCategory = await loadInsCategory();
      console.log("this.insCategory", this.insCategory);
    },

    getCallbackTime(status, callback_at) {
      // console.log("getCallbackTime ");
      return status == 1 ? " : " + callback_at : "";
    },

    getAppointmentTime(status, field_visit_at) {
      // console.log("getAppointmentTime ");
      return status == 2 ? " : " + field_visit_at : "";
    },

    getLeadStatusDisplayName(id) {
      let status = this.statusData.find((item) => item.status == id);
      if (status) {
        // console.log("status it",status,id);
        return status.value;
      }
      return "NO Status";
    },

    async assingStatusToLead(status) {
      console.log("Selected status ----" + status);
      // this.leadTobeUpdated = this.visibleLeadsData.find((item) => item.id === this.leadTobeUpdated.id);
      // this.updateLead(this.leadTobeUpdated, this.leadTobeUpdated.assigned_agent_id, status)
      console.log("Selected status ----" + status);
      if (status == 1) {
        // Callback
        this.updateLead(
          this.leadTobeUpdated,
          this.leadTobeUpdated.assigned_agent_id,
          status
        );
        this.selectedStatusValue = "Call Back";
        this.handleSelectStatusChange();
      } else if (status == 2) {
        console.log("Appointment status----");
        this.updateLead(
          this.leadTobeUpdated,
          this.leadTobeUpdated.assigned_agent_id,
          status
        );
        this.selectedStatusValue = "Schedule Appointment";
        this.handleSelectStatusChange();
      } else if (status == 4) {
        console.log("Payment done status----");
        // Lead status to be updated after add transaction is successful
        this.selectedStatusValue = "Payment Done";
        this.handleSelectStatusChange();
      } else if (status == 5) {
        console.log("Policy Issued status----");
        // Lead status to be updated after policy number is updated
        this.selectedStatusValue = "Policy Issued";
        this.handleSelectStatusChange();
      } else {
        this.updateLead(
          this.leadTobeUpdated,
          this.leadTobeUpdated.assigned_agent_id,
          status
        );
        console.log("Selected status not 1----");
      }
    },

    async assingCallbackStatusToLead(status) {
      console.log("assingCallbackStatusToLead ----" + status);
      this.updateLead(
        this.leadTobeUpdated,
        this.leadTobeUpdated.assigned_agent_id,
        status
      );
      if (status == 1) {
        console.log("Selected status ----" + status);
        this.selectedStatusValue = "Call Back";
        this.handleSelectStatusChange();
      } else {
        console.log("Selected status not 1----");
      }
    },

    async assingAgentToLead(agentId) {
      console.log("Selected Agent Id----" + agentId);
      this.updateLead(
        this.leadTobeUpdated,
        agentId,
        this.leadTobeUpdated.status
      );
    },

    async assingCCToLead(ccId) {
      console.log("Selected CC Id----" + ccId);
      this.updateCCInLead(
        this.leadTobeUpdated,
        ccId,
        this.leadTobeUpdated.status
      );
    },

    async updateLeadWithTx(lead, status, orderDetails) {
      console.log("updateLeadWithTx");
      // console.log(orderDetails);
      let data = {
        lead_id: lead.id,
        status: status,
        welth_user_id:this.loggedInUserId,
      };

      const response = await axios.post(UPDATE_LEAD_URL, data);
      if (response.data.code == 200) {
        // console.log(response.data);
        let l = response.data.data;
        lead.order_id = l.order_id;
        if (orderDetails) {
          lead.order_details = orderDetails;
        }
        lead.status = l.status;
      }
      console.log(response.data.message);
    },

    async updateLeadWithPolicyData(lead, agentId, status, orderDetails) {
      if (orderDetails) {
        lead.order_details = orderDetails;
      }
      this.updateLead(lead, agentId, status);
    },

    async updateLead(lead, agentId, status) {
      let data = {
        lead_id: lead.id,
        status: status,
        assigned_agent_id: agentId,
        welth_user_id:this.loggedInUserId,
      };

      console.log("welth_user_id:this.loggedInUserId, ",this.loggedInUserId,);

      const response = await axios.post(UPDATE_LEAD_URL, data);

      if (response.data.code == 200) {
        let l = response.data.data;
        lead.assigned_agent_info = l.assigned_agent_info;
        lead.status = l.status;
      }
      console.log(response.data.message);
    },

    async updateCCInLead(lead, ccId, status) {
      let data = {
        lead_id: lead.id,
        status: status,
        assigned_cc_id: ccId,
        welth_user_id:this.loggedInUserId,
      };

      const response = await axios.post(UPDATE_LEAD_URL, data);

      if (response.data.code == 200) {
        let l = response.data.data;
        lead.assigned_cc_info = l.assigned_cc_info;
        lead.status = l.status;
      }
      console.log(response.data.message);
    },

    async handleTextSubmission1(text) {
      await this.updateLeadInfo(this.leadTobeUpdated, text);
    },

    async handleTextSubmission(newLeadData) {
      if (newLeadData.created_at) {
        newLeadData.created_at_original = newLeadData.created_at;
        newLeadData.created_at = formatDate(newLeadData.created_at);
      }
      console.log(this.leadId);
      if (this.leadTobeUpdated)
        this.leadTobeUpdated.leadInof.unshift(newLeadData);
    },

    async handleCallBackSubmission(newLeadData, lead_id) {
      console.log("handleCallBackSubmission");
      // console.log(newLeadData);
      if (newLeadData.created_at) {
        newLeadData.created_at_original = newLeadData.created_at;
        newLeadData.created_at = formatDate(newLeadData.created_at);
        newLeadData.created_at_time = formatTime(
          newLeadData.created_at_original
        );
      }
      if (newLeadData.callback_at)
        newLeadData.callback_at =
          " at " +
          formatDate(newLeadData.callback_at) +
          " " +
          formatTime(newLeadData.callback_at);
      console.log(lead_id);
      // console.log(this.leadTobeUpdated);
      if (this.leadTobeUpdated) {
        console.log("unshifting");
        this.leadTobeUpdated.leadInof.unshift(newLeadData);
        this.leadTobeUpdated.status = newLeadData.status;
        this.leadTobeUpdated.callback_at = newLeadData.callback_at;
      } else {
        console.log("leadTobeUpdated empty");
        this.leadTobeUpdated = this.visibleLeadsData.find(
          (item) => item.id === lead_id
        );
        // console.log(this.leadTobeUpdated);
        this.updateCommentList(this.leadTobeUpdated.id);
        this.leadTobeUpdated.status = newLeadData.status;
        this.leadTobeUpdated.callback_at = newLeadData.callback_at;
      }
      // console.log(this.visibleLeadsData);
    },

    agentAdded() {
      console.log("Agent added");
      this.getAgentList();
      this.getCCList();
    },

    async handleAppointmentSubmission(newLeadData, lead_id) {
      console.log("handleAppointmentSubmission");
      console.log(newLeadData.field_visit_at);
      // console.log(newLeadData);
      if (newLeadData.created_at) {
        newLeadData.created_at_original = newLeadData.created_at;
        newLeadData.created_at = formatDate(newLeadData.created_at);
        newLeadData.created_at_time = formatTime(
          newLeadData.created_at_original
        );
      }
      if (newLeadData.field_visit_at)
        newLeadData.field_visit_at =
          " at " +
          formatDate(newLeadData.field_visit_at) +
          " " +
          formatTime(newLeadData.field_visit_at);
      console.log(lead_id);
      if (this.leadTobeUpdated) {
        console.log("unshifting");
        this.leadTobeUpdated.leadInof.unshift(newLeadData);
        this.leadTobeUpdated.status = newLeadData.status;
        this.leadTobeUpdated.field_visit_at = newLeadData.field_visit_at;
      } else {
        console.log("leadTobeUpdated updated");
        this.leadTobeUpdated = this.visibleLeadsData.find(
          (item) => item.id === lead_id
        );
        this.updateCommentList(this.leadTobeUpdated.id);
        this.leadTobeUpdated.status = newLeadData.status;
        this.leadTobeUpdated.field_visit_at = newLeadData.field_visit_at;
      }
      // console.log(this.visibleLeadsData);
    },

    async handleFileSubmission(newLeadData) {
      this.updateCommentList(this.leadTobeUpdated.id);
    },

    async handleVideoSubmission(newLeadData) {
      if (newLeadData.created_at) {
        newLeadData.created_at_original = newLeadData.created_at;
        newLeadData.created_at = formatDate(newLeadData.created_at);
        newLeadData.created_at_time = formatTime(
          newLeadData.created_at_original
        );
      }
      // this.leadTobeUpdated.leadInof.unshift(newLeadData);
      this.updateCommentList(this.leadTobeUpdated.id);
    },

    async updateLeadInfo(leadData, descr) {
      try {
        let data = {
          welth_leads_id: leadData.id,
          description: descr,
          created_by_id: this.loggedInUserId,
        };
        console.log("updateLeadInfo", data);
        const response = await axios.post(LEAD_INFO_URL, data);
        if (response.data.code == 200) {
          console.log(response.data.message);
          let newLeadData = response.data.data;
          newLeadData.created_at_original = newLeadData.created_at;
          newLeadData.created_at = formatDate(newLeadData.created_at);
          newLeadData.created_at_time = formatTime(
            newLeadData.created_at_original
          );
          leadData.leadInof.unshift(newLeadData);
        } else {
          alert(response.data.message);
          return null;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async fetchLeadStatusLIst() {
      // console.log("fetchLeadStatusLIst info ");
      const response = await axios.get(STATUS_LIST_FETCH_URL, {});
      // console.log(response.data.message);
      if (response.data.code == 200) {
        this.statusData = response.data.data;
      }
    },

    async fetchLeadInfo(leadData) {
      // console.log("fetchLead info ");
      const response = await axios.get(LEAD_INFO_FETCH_URL + leadData.id, {});
      //console.log(response);
      leadData.leadInof = [];
      if (response.data.data.leads_info) {
        leadData.leadInof = response.data.data.leads_info;
      }
      leadData.leadInof.forEach((e) => {
        e.created_at_original = e.created_at;
        e.created_at = formatDate(e.created_at);
        e.created_at_time = formatTime(e.created_at_original);
      });
    },

    async getLeadStatusList() {
      // console.log("getLeadStatusList");
      const response = await axios.get(AGENT_FETCH_URL, {});
      // console.log("getLeadStatusList result");
      if (response.code == 200) {
        this.leadStatusList = response.data.data;
      }
      // console.log(response.message);
    },

    async getAgentList() {
      // console.log("getAgentList");
      const response = await axios.get(AGENT_FETCH_URL, {});
      // console.log("getAgentList result");
      if (response.data.code == 200) {
        this.agentData = response.data.data;
      }
      // console.log(response);
      // console.log(this.agentData);
      // console.log(response.data.message);
    },

    async getEmployeeList() {
      // console.log(" getEmployeeList");
      const response = await axios.get(EMPLOYEE_FETCH_URL, {});
      // console.log("getEmployeeList result");
      if (response.data.code == 200) {
        this.employeeData = response.data.data;
      }
      // console.log(response);
      // console.log(this.agentData);
      // console.log(response.data.message);
    },

    async getCCList() {
      // console.log("getCCList");
      const response = await axios.get(CC_FETCH_URL, {});
      // console.log("getCCList result");
      if (response.data.code == 200) {
        this.ccData = response.data.data;
      }
      // console.log(response);
      // console.log(response.data.message);
    },

    updatedStats() {
      // console.log("updatedStats");
      this.fieldAgemtData.clear();
      this.ccAgentData.clear();
      this.leadsData.forEach((lead) => {
        if (lead.assigned_agent_info != null) {
          this.fillDataFromLeads(
            lead.assigned_agent_info,
            this.fieldAgemtData,
            lead
          );
        }
        if (lead.assigned_cc_info != null) {
          this.fillDataFromLeads(lead.assigned_cc_info, this.ccAgentData, lead);
        }
      });
    },

    async fetchLeads(startTimer) {
      this.leadsData = [];
      this.visibleLeadsData = [];
      await this.fetchLeadStatusLIst();
      console.log("fetchLeads");
      const idParam = "&welth_user_id=" + this.sessionData.welthUserId;
      let response = await axios.get(LEAD_FETCH_URL + idParam, {});
      // console.log("fetchLeads result");
      this.leadsData = response.data.data;
      // console.log(this.leadsData);
      this.currentLeadCount = this.leadsData.length;
      // console.log("currentLeadCount: " + this.currentLeadCount);
      this.leadsData.forEach((e) => {
        try {
          let d = JSON.parse(e.interested_products);
          let str = "";
          for (let i = 0; i < d.length; i++) {
            str += d[i] + " , ";
          }
          e.interested_products = str;
          e.created_at_original = e.created_at;
          e.created_at = formatDate(e.created_at);
          e.created_at_time = formatTime(e.created_at_original);
          if (e.callback_at)
            e.callback_at =
              " at " +
              formatDate(e.callback_at) +
              " " +
              formatTime(e.callback_at);
          if (e.field_visit_at)
            e.field_visit_at =
              " at " +
              formatDate(e.field_visit_at) +
              " " +
              formatTime(e.field_visit_at);
          // e.leadInof = [];
          e.isLeadInfoOpen = false;
        } catch (error) {
          console.error(error);
        }
      });
      this.leadsData.sort((a, b) => b.id - a.id);
      this.visibleLeadsData = this.leadsData;
      // console.log('fetchLeads response:', this.leadsData);
      this.loadCityInfo();
      this.isDataLoaded = true;
      this.haveExtraLeads = false;
      this.extraLeadsCount = 0;
      if (startTimer) {
        console.log("Starting the timer for leads count");
        this.startTimer();
      }
      this.handleFilterChange();
    },

    async fetchLeadsCount() {
      console.log("fetchLeadsCount");
      const idParam = "&welth_user_id=" + this.sessionData.welthUserId;
      let response = await axios.get(LEAD_FETCH_URL + idParam, {});
      const newLeadsData = response.data.data;
      // console.log(this.leadsData);
      const newLeadCount = newLeadsData.length;
      // console.log("newLeadCount: " + newLeadCount);
      if (newLeadCount > this.currentLeadCount) {
        this.extraLeadsCount = newLeadCount - this.currentLeadCount;
        this.haveExtraLeads = true;
        console.log("Have extra leads: " + this.extraLeadsCount);
      } else {
        this.haveExtraLeads = false;
      }
    },

    startTimer() {
      this.fetchLeadsCount();
      this.timerId = setInterval(this.fetchLeadsCount, this.delay);
    },

    stopTimer() {
      // Stop the timer when the "Stop API Calls" button is clicked
      clearInterval(this.timerId);
    },

    changeDate() {
      // console.log("changeDate");
      // console.log("start date", this.startDate);
      // console.log("today date", this.todayDate);
      if (this.startDate && this.endDate) {
        this.startDateVal = new Date(this.startDate);
        this.endDateVal = new Date(this.endDate);
        // console.log("start date", this.startDateVal);
        if (this.startDateVal > this.endDateVal) {
          // alert("Start date cannot be higher than end date");
          window.alert("Start date cannot be higher than end date");
          this.endDate = "";
        }
        this.handleFilterChange();
      } else {
        this.handleFilterChange();
      }
    },

    handleFilterChange() {
      console.log("handleFilterChange");
      console.log(this.selectedStatusFilterValue);
       
      let newData = []
      const startDateOnly = this.startDateVal.toISOString().split("T")[0];
      const endDateOnly = this.endDateVal.toISOString().split("T")[0];

       
      for (let index = 0; index < this.leadsData.length; index++)  {
        let isFiltered = true;
        let e = this.leadsData[index];
        // For status
        let statusVal = this.selectedStatusFilterValue;
        const selIndex = this.statusData.findIndex(
          (info) => info.value === statusVal
        );
        if (statusVal != "All" && selIndex != e.status) {
          isFiltered = false;
        }

        //For lead type
        if (
          isFiltered &&
          this.selectedLeadType != 0 &&
          e.lead_type != this.selectedLeadType
        ) {
          isFiltered = false;
        }

        //For agent or cc filter
        if (this.selectedUserId != -1) {
          let agentUser = e.assigned_agent_info;
          let ccUser = e.assigned_cc_info;

          if (isFiltered && agentUser == null && ccUser == null) {
            isFiltered = false;
          }

          if (
            isFiltered &&
            ccUser == null &&
            agentUser != null &&
            agentUser.user_id != this.selectedUserId
          ) {
            isFiltered = false;
          }

          if (
            isFiltered &&
            agentUser == null &&
            ccUser != null &&
            ccUser.user_id != this.selectedUserId
          ) {
            isFiltered = false;
          }

          if (
            isFiltered &&
            agentUser != null &&
            ccUser != null &&
            ccUser.user_id != this.selectedUserId &&
            agentUser.user_id != this.selectedUserId
          ) {
            isFiltered = false;
          }
        }

        // Location Filter
        if (this.locationfilterForLeads && this.selectedCityId != -1) {
          let agentUser = e.assigned_agent_info;
          let ccUser = e.assigned_cc_info;

          if (
            isFiltered &&
            (agentUser == null || agentUser.location == null) &&
            (ccUser == null || ccUser.location == null)
          ) {
            isFiltered = false;
          }

          if (
            isFiltered &&
            (ccUser == null || ccUser.location == null) &&
            agentUser != null &&
            agentUser.location != null &&
            agentUser.location.city_id != this.selectedCityId
          ) {
            isFiltered = false;
          }

          if (
            isFiltered &&
            (agentUser == null || agentUser.location == null) &&
            ccUser != null &&
            ccUser.location != null &&
            ccUser.location.city_id != this.selectedCityId
          ) {
            isFiltered = false;
          }

          if (
            isFiltered &&
            agentUser != null &&
            ccUser != null &&
            agentUser.location != null &&
            ccUser.location != null &&
            ccUser.location.city_id != this.selectedCityId &&
            agentUser.location.city_id != this.selectedCityId
          ) {
            isFiltered = false;
          }
        }

        if (isFiltered && this.startDate && this.endDate && e.created_at) {
          // console.log("Filtering based on dates");
          const leadDate = new Date(e.created_at_original);
          // const leadDateOriginal = new Date(e.created_at_original);
          // Extract date portion from the dates
          const leadDateOnly = leadDate.toISOString().split("T")[0];
          const leadDateObj = new Date(leadDateOnly);
          const startDateObj = new Date(startDateOnly);
          const endDateObj = new Date(endDateOnly);
 
          if (leadDateObj >= startDateObj && leadDateObj <= endDateObj) {
            console.log(" consider , "+isFiltered+" :" + leadDateOnly + " " + startDateOnly+ " "+endDateOnly);
            
          } else {
            ///console.log(" dont consider "+isFiltered+" :" + leadDateOnly + " " + startDateOnly+ " "+endDateOnly);
            isFiltered = false;
            
          }
        }

       
        
        if (isFiltered) {
          newData.push(e);
        }
         
      };
      this.visibleLeadsData = [];
      this.$nextTick(() => {
         
        this.visibleLeadsData = [...newData];
        this.filterLeads();
         
      });
    },

    formatCreatedAtOriginal(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

    async importData() {
      if (!this.xlsxScriptLoaded) {
        await this.loadXlsxScript();
      }
      // console.log(this.visibleLeadsData);
      const flattenedData = this.visibleLeadsData.map((lead) => {
        const flattenedLead = { ...lead };
        delete flattenedLead.id;
        delete flattenedLead.lead_display_id;
        delete flattenedLead.welth_lead_id;
        delete flattenedLead.isLeadInfoOpen;
        delete flattenedLead.vendor_id;
        delete flattenedLead.insurance_category;

        // Replace lead_type with the result of getLeadType method
        flattenedLead["lead Type "] = this.getLeadType(flattenedLead.lead_type);
        flattenedLead["Lead Status"] = this.getLeadStatusDisplayName(
          flattenedLead.status
        );
        flattenedLead["Lead Category"] = this.getCategoryName(
          flattenedLead.lead_category
        );

        // Flatten lead_user_info first
        for (const key in lead.lead_user_info) {
          if (
            Object.prototype.hasOwnProperty.call(lead.lead_user_info, key) &&
            key !== "location"
          ) {
            flattenedLead[
              key === "dob"
                ? "Policy Holder DoB"
                : key === "user_name"
                ? "Customer Name"
                : key === "user_phoneno"
                ? "Customer Phone Number"
                : key.toUpperCase()
            ] = lead.lead_user_info[key];
          }
        }

        if (flattenedLead.CORPORATE_ID == 1) {
          flattenedLead["Corporate Name"] = this.getCorporateNameValue(
            flattenedLead.CORPORATE_ID
          );
          // console.log(flattenedLead.CORPORATE_ID);
        } else {
          flattenedLead["Corporate Name"] = "";
        }

        //Flatten  order_details
        for (const key in lead.order_details) {
          if (
            Object.prototype.hasOwnProperty.call(lead.order_details, key) &&
            key !== "lead_category"
          ) {
            flattenedLead[key] = lead.order_details[key];
          }
        }

        flattenedLead["Order Date"] = flattenedLead.order_date;
        flattenedLead["Policy Holder Name"] = flattenedLead.policy_holder_name;
        flattenedLead["Policy Number"] = flattenedLead.policy_number;
        flattenedLead["Product Name"] = flattenedLead.product_name;
        flattenedLead["Transaction Id"] = flattenedLead.transaction_id;
        flattenedLead["Payment amount"] = flattenedLead.payment_amount;
        flattenedLead["Payment Date"] = flattenedLead.payment_date;

        for (const key in lead.assigned_cc_info) {
          if (
            Object.prototype.hasOwnProperty.call(lead.assigned_cc_info, key)
          ) {
            flattenedLead[key] = lead.assigned_cc_info[key];
          }
        }

        // flattenedLead['Created Date'] = flattenedLead.created_at;
        flattenedLead["Call Back Date"] = flattenedLead.creacallback_atted_at;
        flattenedLead["Field Visit Date"] = flattenedLead.field_visit_at;
        // flattenedLead['Order Id']= flattenedLead.order_id;
        flattenedLead["Lead Created Time"] = flattenedLead.created_at_time;

        // Format created_at_original
        // flattenedLead['Lead Creation Date'] = this.formatCreatedAtOriginal(flattenedLead.created_at_original);
        flattenedLead["Lead Creation Date"] = isNaN(
          Date.parse(flattenedLead.created_at_original)
        )
          ? ""
          : this.formatCreatedAtOriginal(flattenedLead.created_at_original);
        flattenedLead["Policy Issue Date"] = isNaN(
          Date.parse(flattenedLead.policy_issue_date)
        )
          ? ""
          : this.formatCreatedAtOriginal(flattenedLead.policy_issue_date);
        flattenedLead["Policy Start Date"] = isNaN(
          Date.parse(flattenedLead.policy_start_date)
        )
          ? ""
          : this.formatCreatedAtOriginal(flattenedLead.policy_start_date);
        // flattenedLead['Policy Done Date'] = isNaN(Date.parse(flattenedLead.policy_done_date)) ? '' : this.formatCreatedAtOriginal(flattenedLead.policy_done_date);
        flattenedLead["Policy End_Date"] = isNaN(
          Date.parse(flattenedLead.policy_end_date)
        )
          ? ""
          : this.formatCreatedAtOriginal(flattenedLead.policy_end_date);
        // flattenedLead['Lead Update Date'] = this.formatCreatedAtOriginal(flattenedLead.updated_at);

        if (lead.assigned_cc_info) {
          flattenedLead["Assigned CC Info User Name"] =
            lead.assigned_cc_info.user_name;
        } else {
          flattenedLead["Assigned CC Info User Name"] = "";
        }

        flattenedLead["Interested Products"] =
          flattenedLead.interested_products;

        delete flattenedLead.location;
        delete flattenedLead.lead_user_info;
        delete flattenedLead.order_details;
        delete flattenedLead.comments_count;
        delete flattenedLead.assigned_cc_info;
        delete flattenedLead.assigned_agent_info;
        delete flattenedLead.cashback_amount;
        delete flattenedLead.cashback_status;
        delete flattenedLead.lead_type;
        delete flattenedLead.dob;
        delete flattenedLead.user_name;
        delete flattenedLead.user_phoneno;
        delete flattenedLead.user_id;
        delete flattenedLead.status;
        delete flattenedLead.lead_category;
        delete flattenedLead.created_at_original;
        delete flattenedLead.policy_issue_date;
        delete flattenedLead.policy_start_date;
        delete flattenedLead.policy_done_date;
        delete flattenedLead.policy_end_date;
        delete flattenedLead.policy_start_date;
        delete flattenedLead.corporate_id;
        delete flattenedLead.updated_at;
        delete flattenedLead.interested_products;
        delete flattenedLead.created_at;
        delete flattenedLead.callback_at;
        delete flattenedLead.field_visit_at;
        delete flattenedLead.order_id;
        delete flattenedLead.CORPORATE_ID;
        delete flattenedLead.USER_ID;
        delete flattenedLead.order_type;
        delete flattenedLead.created_at_time;
        delete flattenedLead.insurance_category;
        delete flattenedLead.order_date;
        delete flattenedLead.policy_holder_name;
        delete flattenedLead.policy_number;
        delete flattenedLead.product_name;
        delete flattenedLead.transaction_id;
        delete flattenedLead.insurer_id;
        delete flattenedLead.promotion_id;
        delete flattenedLead.payment_amount;
        delete flattenedLead.payment_date;
        delete flattenedLead.created_at_time;

        return flattenedLead;
      });
      console.log("flattenedData======", flattenedData);
      const worksheet = window.XLSX.utils.json_to_sheet(flattenedData);

      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };

      const excelBuffer = window.XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "leadsData.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    handleLeadClick(lead) {
      const userId = lead.lead_user_info.user_id;
      console.log(userId);

      this.UserId = userId;
      console.log(this.UserDoc);
      const userDocs = this.UserDoc.filter(
        (doc) => doc.welth_user_id === userId
      );

      if (userDocs) {
        if (userDocs.length > 0) {
          this.showDocModal = true;
        } else {
          alert("This user has no documents");
        }

        console.log(userDocs);

        this.selectedDocs = userDocs;
        console.log(this.selectedDocs);
      } else {
        console.log("Welth user not found for this lead");
        alert("This user has no documents");
      }
    },

    async downloadDocument(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank'; // Open in a new tab if you want
      link.download = 'filename'; // Specify the filename you want the file to be saved as

      // Append the anchor element to the DOM
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();

      // Remove the anchor element from the DOM
      document.body.removeChild(link)
},
    closePopup() {
      this.showDocModal = false;

      this.selectedDocs = [];
    },

    async downloadAllDocuments() {
      this.selectedDocs.forEach((doc) => {
        const link = document.createElement("a");
        link.href = doc.doc_url;
        link.target = "_blank";
        link.download = ""; // This ensures that the browser prompts the user to save the file with its original name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    async getWelthUserDoc() {
      let response = await axios.get(GET_WELTH_USER_DOCS);
      console.log(response.data.data.welth_docs);
      this.UserDoc = response.data.data.welth_docs;
    },

    loadXlsxScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src =
          "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.16.2/xlsx.full.min.js";
        script.onload = () => {
          this.xlsxScriptLoaded = true;
          resolve();
        };
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },

    shouldShowLead(lead) {
      return true;
    },
  },

  watch: {
  searchQuery() {
    this.filterLeads();
  }
},

  computed: {
    formattedDOB() {
      const dob = this.visibleLeadsData.map((lead) => {
        if (lead.lead_user_info == null) {
          return "";
        }
        // Convert the date string to a Date object
        const dateObject = new Date(lead.lead_user_info.dob);

        // Get day, month, and year
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1; // Months are zero-based
        const year = dateObject.getFullYear();
        // console.log("day month year",day,month,year);
        // Format the date as dd-mm-yyyy
        let rv = `${day}-${month < 10 ? "0" + month : month}-${year}`;
        if (rv == "NaN-NaN-NaN") {
          return "";
        }
        return rv;
      });
      return dob;
    },
    
  },
};
</script>
  
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.log1 {
  width: 80px;
  height: 80px;
}

/*
#sec {
  height: 100vh; 
}
*/
.main_tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15%;
}

ul {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.main_tabs ul li {
  list-style: none;
  color: #3b53cb;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.activelink {
  font-size: 20px;
  font-weight: 800;
  color: #474747;
  border-bottom: 3px solid #c7a84b;
}

.logo2 {
  display: flex;
  gap: 120px;
  align-items: center;
}

.nav-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 400px;
}

.t1 {
  position: absolute;
  left: -100px;
  width: 139px;
  border-radius: 32px;
  border: 2px;
  background-color: #3b53cb;
  border: 2px solid #c7a84b;
  padding: 12px;
  color: #f2f2f2;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

.t2 {
  width: 453px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  padding: 10px;
  padding-left: 50px;
  color: #3b53cb;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

/* .t2 input {
      width: 453px;
      border-radius: 32px;
      border: 2px solid #C7A84B;
      padding: 10px;
      padding-left: 50px;
      color: #3B53CB;
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      padding-left: 20px;
  } */

.t2 span {
  margin-left: 80px;
}

.dropmenu {
  width: auto;
  height: 57px;
  border-radius: 8px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 5px;
  /* align-items: right;
  justify-content: flex-end;*/
}

.dropmenu input {
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  padding: 0px 7px;
  position: relative;
  top: 10px;
  height: 35px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

.dropmenu label {
  position: relative;
  top: 18px;
}

.dropmenu select {
  padding: 8px;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

#sec {
  background-color: #f2f2f2;
}

.dropbar {
  height: 97px;
  background-color: #ffffff;
  border-bottom: 2px solid #c7a84b;
  margin: auto 13%;
  margin-top: 10px;
}

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 480px;
  gap: 50px;
  padding: 40px 10px;
  margin-left: 60px;
}

.p2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leads_right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.plans h2 {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #c7a84b;
}

.p1 p {
  font-size: 12px;
}

.p2 p {
  font-size: 14px;
}

.p22 {
  padding: 8px;
  border: 1px solid #c7a84b;
  border-radius: 8px;
  background: #fff;
}

.p21 {
  padding: 18px 8px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-left: 10px;
  color: #474747;
}

.user_info {
  margin-top: 20px;
}

@media screen and (max-width: 608px) {
  #sec {
    width: 608px;
    overflow: hidden;
  }

  .t2 span {
    margin: 20px;
  }

  .t2 {
    width: 400px;
  }

  .navbar {
    margin-left: 0px;
    width: 608px;
    position: relative;
    margin-bottom: 100px;
  }

  .dropmenu {
    width: 608px;
    margin-left: 0px;
  }

  .nav-text {
    position: absolute;
    left: 350px;
    top: 150px;
  }

  body {
    width: 608px;
  }

  .dropbar {
    width: 608px;
  }

  .plans {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  #sec {
    width: 1024px;
    overflow: hidden;
  }

  .t2 span {
    margin: 20px;
  }

  .t2 {
    width: 400px;
  }

  .navbar {
    margin-left: 0px;
    width: 1024px;
  }

  .logo2 {
    margin: 0px 100px;
  }

  .dropmenu {
    width: 1024px;
    margin-left: 0px;
  }

  .dropmenu select {
    flex-wrap: wrap;
  }

  body {
    width: 1024px;
  }

  .dropbar {
    width: 1024px;
  }

  .plans {
    margin-right: 320px;
  }
}

.box1 {
  width: 1118px;
  border-radius: 8px;
  margin-left: 200px;
  margin-top: 40px;
}

.box1-f21 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  margin-left: 40px;
}

.box1-f2 .th1,
.box1-f2 .th2 {
  margin-left: 30px;
}

.box1-f2 {
  /* display: flex;
    justify-content: space-between;*/
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 5;
}

.box1-f2 h3,
.box1-f22 span {
  font-size: 12px;
  font-weight: 600;
  color: #3b53cb;
  margin-left: 10px;
}

.box1-f1 {
  margin-bottom: 3px;

  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
}

.box1-f3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box1-f1 h1 {
  font-size: 16px;
  color: #3b53cb;
}

.box1-f1 h2 {
  font-size: 14px;
  color: #474747;
  font-weight: 600;
}

.box1-f1 p {
  font-size: 14px;
  color: #474747;
  font-weight: 600;
  align-items: center;
  text-align: justify;
}

.dropmenu button {
  padding: 8px 20px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
  margin-top: 10px;
}

.box1-f1 button {
  padding: 8px 20px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
}

.box1-f1 button:hover {
  color: #f2f2f2;
  background: #c7a84b;
}

.dropmenu button:hover {
  color: #f2f2f2;
  background: #c7a84b;
}

.add_role button {
  padding: 5px 20px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
}

.add_role button:hover {
  color: #f2f2f2;
  background: #c7a84b;
}

.assign-agent-btn {
  padding: 5px 8px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
  font-size: 12px;
}

@media screen and (max-width: 608px) {
  .box1 {
    width: 608px;
    margin-left: 0px;
  }

  .box1-f21,
  .box1-f22 {
    width: 608px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .box1 {
    width: 1024px;
    margin-left: 0px;
  }

  .box1-f21,
  .box1-f22 {
    width: 1024px;
    margin-left: 0px;
  }
}

.txt-size {
  font-size: 14px;
  color: #3b53cb;
}

.txt-size1 {
  font-size: 14px;
}

.updated_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leads-status-info {
  display: flex;

  margin-left: 50%;
  margin-top: 10px;
  gap: 30px;
  align-items: center;
}

.leads-status-info h2 {
  font-size: 16px;
  font-weight: 400;
  color: #3b53cb;
}

.text-clr {
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.box1-f1 h3 {
  color: #263ba0;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
  width: 200px;
  display: flex;
  align-items: flex-start;
}

.box1-f4 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 20px;
}

.no_of_count {
  font-size: 14px;
  color: #3b53cb;
}

.show-leads {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: red;
  border: 2px solid #c7a84b;
  position: relative;
  right: 14px;
  bottom: 12px;
  color: #fff;
  text-align: center;
}
.search_name {
  width: 60%;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.04em;
  color: #000;
  margin-left: 216px;
}

.box {
  width: 800px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  flex-direction: column;
}

.modal1 {
  width: 50%;
  padding: 20px;
  margin: 100px auto;

  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  cursor: pointer;
}

.title {
  color: #c7a84b;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.user_docs {
  padding: 8px 20px;
  height: 40px;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background-color: #3b53cb;
  color: #f2f2f2;
  cursor: pointer;
}

.flex-wrap {
  display: flex;
}


.box {
  width: 800px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  flex-direction: column;
}

.box input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.box .button {
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
  padding-left: 20px;
  padding-top: 5px;
  cursor: pointer;
}

.box h3 {
  color: #c7a84b;

  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}



.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
}

.modal-close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-close.is-large::after {
  font-size: 30px;
  color: black;
}

.close {
  color: #c7a84b;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.button3 {
  border-radius: 24px;
  background: #3b53cb;
  color: #fff;
  border: 1px solid #c7a84b;
  height: 40px;
  padding: 0px 10px;
}
.button4 {
  border-radius: 24px;
  background: #3b53cb;
  color: #fff;
  border: 1px solid #c7a84b;
  height: 40px;
  padding: 0px 30px;
}

.delete-icon {
  color: #c7a84b;
  position: relative;
  right: 10px;
  bottom: 5px;
  cursor: pointer;
  /*background: #3b53cb;*/
  padding: 5px;
  border-radius: 50%;
}

.user_name {
  display: flex;
  flex-direction: column;
}

.show_doc {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}
</style>