import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import Plans1 from '../components/Plans1.vue';
import Terms from '../components/Terms.vue';
import Privacy from '../components/Privacy.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/login',
    name: 'login',
    component: HomeView,
  },

  {
    path: '/plans1',
    name: 'Plans1',
    component: Plans1,
  },

  {
    path: '/terms',
    name: "Terms",
    component: Terms,
  },

  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    // meta: { requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  // Check if the route requires authentication and if there's a token
  if (to.meta.requiresAuth && !token) {
    // Redirect to the login page if not authenticated
    next('/login');
  } else {
    // Continue with the navigation if authentication is not required or the token is present
    next();
  }
});

export default router
