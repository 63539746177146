<template>
  <router-view />
</template>

<style>
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
</style>
