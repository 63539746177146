<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="box1-f3">
        <h1 class="title">Transaction Details</h1>
        <div class="close-icon" @click="closePopup">
          <img src="../images/close-popup.png" alt="" />
        </div>
      </div>

      <div  style="max-height: 600px; overflow-y: auto;" class="">
        <div class="d-flex align-items-center">
          <h4 class="title-name"> Application No</h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.transaction_id }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy Holder Name</h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.policy_holder_name }}</h4>
        </div>

        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Date of Birth</h4>
          <h4 class="info" v-if="transInfo"> : {{ formatDateVal(transInfo.dob) }}</h4>
        </div>

        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy Name</h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.product_name }} </h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">New / Renew</h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.order_type == 1 ? 'New' : 'Renew' }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy Type</h4>
          <h4 class="info" v-if="transInfo"> : {{ getCategoryName(transInfo.insurance_category) }} </h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Payment Date</h4>
          <h4 class="info" v-if="transInfo"> : {{ formatDateVal(transInfo.payment_date) }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Total Payment</h4>
          <h4 class="info" v-if="transInfo"> : ₹ {{ transInfo.payment_amount }}</h4>
        </div>
         
          <div class="d-flex align-items-center mt-4" v-if="transInfo.insurance_category==3">
            <h4 class="title-name">OD Amout</h4>
            <h4 class="info" v-if="transInfo"> : ₹ {{ transInfo.od_amount }}</h4>
          </div>
          <div class="d-flex align-items-center mt-4" v-if="transInfo.insurance_category==3">
            <h4 class="title-name">TP Amout</h4>
            <h4 class="info" v-if="transInfo"> : ₹ {{ transInfo.tp_amount }}</h4>
          </div>
       
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">CGST Amout</h4>
          <h4 class="info" v-if="transInfo"> : ₹ {{ transInfo.cgst_amount }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">SGST Amout</h4>
          <h4 class="info" v-if="transInfo"> : ₹ {{ transInfo.sgst_amount }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">IGST Amout</h4>
          <h4 class="info" v-if="transInfo"> : ₹ {{ transInfo.igst_amount }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy No</h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.policy_number ? transInfo.policy_number : 'NA' }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Issued Date </h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.policy_issue_date ? formatDateVal(transInfo.policy_issue_date)
            : 'NA' }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy Start Date </h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.policy_start_date ? formatDateVal(transInfo.policy_start_date)
            : 'NA' }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy End Date </h4>
          <h4 class="info" v-if="transInfo"> : {{ transInfo.policy_end_date ? formatDateVal(transInfo.policy_end_date)
            : 'NA' }}</h4>
        </div>
        <div class="d-flex align-items-center mt-4">
          <h4 class="title-name">Policy Document</h4>
          <h4 class="info" v-if="transInfo">  <a href="#" @click.prevent="viewPolicyDocument">{{ transInfo.policy_pdf_url ? 'View Attachment' : 'NA' }}</a></h4>
        </div>
        <div class="save">
          <button class="save-btn" @click="closePopup">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatTime } from '../util/commonFunctions'
import { loadInsCategory, loadBrandData, loadInsType } from '../util/commonFunctions'
export default {
  props: ['transInfo'],
  data() {
    return {
      allBrandData: [],
      insType: [],
      insCategory: [],
    }
  },

  mounted() {
    this.getCategory();
    console.log(this.transInfo);
  },

  methods: {
    getCategoryName(categoryId) {
      console.log("getCategoryName");
      // console.log(categoryId);
      // console.log(this.insCategory);
      if (this.insCategory) {
        const insObj = this.insCategory.find(item => item.id === categoryId);
        if (insObj) {
          console.log(insObj.value);
          return insObj.value;
        }
      }
    },
    viewPolicyDocument() {
      if (this.transInfo.policy_pdf_url) {
        window.open(this.transInfo.policy_pdf_url, '_blank');
      }
    },
    async getCategory() {
      this.insCategory = await loadInsCategory();
      console.log("this.insCategory", this.insCategory);
    },

    formatDateVal(d) {
      return formatDate(d);
    },

    closePopup() {
      this.$emit('close-show-transaction');
    }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 430px;
  padding: 20px;
  margin: 10px auto;
  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  cursor: pointer;
}

.box1-f3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.title {
  display: table-cell;
  color: #c7a84b;
  justify-content: flex-start;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.title-name {
  color: #263ba0;
  font-size: 14px;
  font-weight: 600;
  width: 170px;
}

.info {
  margin-left: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;
}

.save-btn {
  height: 36px;
  width: 200px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
  margin-top: 20px;
}
</style>