<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="box1-f3">
        <h1 class="title">Update Policy Info</h1>
        <div class="close-icon" @click="closePopup">
          <img src="../images/close-popup.png" alt="" />
        </div>
      </div>
      <div style="overflow-y: auto; height: 400px">
        <!-- <form @submit="submitForm"> -->
        <div class="policy_number">
          <input
            type="text"
            placeholder="Policy Number"
            v-model="policyNumber"
          />
        </div>
        <div class="date-info">
          <div style="padding-top: 11px">Issue Date</div>
          <input type="date" v-model="issueDate" :style="{ color: issueDate ? '#263ba0' : '' }" style="width: 346px" />
        </div>
        <div class="date-info">
          <div style="padding-top: 11px">Start Date</div>
          <input type="date" v-model="startDate" :style="{ color: startDate ? '#263ba0' : '' }" style="width: 346px" />
        </div>
        <div class="date-info">
          <div style="padding-top: 11px">End Date</div>
          <input
            class="mx-4"
            type="date"
            v-model="endDate"
            style="width: 349px"
            :style="{ color: endDate ? '#263ba0' : '' }"
          />
        </div>
        <div class="mt-4 mx-4">
          <input
            type="file"
            id="docId"
            name="docFiles[]"
            accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            style="display: none"
            @click="openFileDialog"
          />
          <label
            for="docId"
            class="title_top d-flex justify-content-start"
            style="cursor: pointer"
          >
            <h5 class="labelclr">Select Policy Document</h5>
            <img
              width="24"
              height="24"
              src="https://img.icons8.com/color/48/attach.png"
              alt="attach"
            />
          </label>
        </div>

        <div class="error">{{ validationMessage }}</div>
        <div class="title_top1">
          
          <div ref="previewContainer"   style="display: none; ">
            <iframe
              id="pdfPreview"
              style=" height: 100px; overflow-y: auto;"
              frameborder="0"
            ></iframe>
            <img
              class="close-icon-svg"
              src="../images/close-popup.png"
              alt="Close"
              @click="deleteFile"
            />
          </div>
        </div>
        <div class="save">
          <button class="save-btn" @click="updatePolicyInfo">
            <span>Update Policy Info</span>
          </button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_POLICY_INFO, UPLOAD_POLICY_DOCUMET } from "@/util/constants";
import { getWelthUserId } from "@/util/loginData";
import axios from "axios";

export default {
  props: ["selectedLeadForPolicyIssued"],

  data() {
    return {
      policyNumber: "",
      issueDate: null,
      startDate: null,
      endDate: null,
      validationMessage: "",
      selectedImageFiles: [],
      selectedVideoFiles: [],
      selectedFiles: [],
      hasText: false,
      hasImage: false,
      hasVideo: false,
      hasFile: false,
      loggedInUserId: "",
    };
  },

  mounted() {
    console.log(
      "Lead data for update policy info: ",
      this.selectedLeadForPolicyIssued
    );

    this.loggedInUserId = getWelthUserId();
    const pdfPreview = document.getElementById("pdfPreview");
    pdfPreview.addEventListener("click", this.openSelectedPDF);
  },

  methods: {
    closePopup() {
      this.closeModal(false);
    },
    closeModal(isPolicyIssueSuccess, order_details) {
      this.$emit("close", isPolicyIssueSuccess, order_details);
    },

    async updatePolicyInfo() {
      console.log("updatePolicyInfo...");
      let validation = true;
      if (validation && this.policyNumber == "") {
        this.validationMessage = "Please enter policy number";
        validation = false;
      }
      if (validation && this.issueDate == null) {
        this.validationMessage = "Please select policy issued date";
        validation = false;
      }
      if (validation && this.startDate == null) {
        this.validationMessage = "Please select policy start date";
        validation = false;
      }
      if (validation && this.endDate == null) {
        this.validationMessage = "Please select policy end date";
        validation = false;
      }
      if (validation && this.selectedFiles.length == 0) {
        this.validationMessage = "Please select the policy document";
        validation = false;
      }

      if (validation) {
        try {

          const formData = new FormData();
          for (let i = 0; i < this.selectedFiles.length; i++) {
            formData.append("policy_doc", this.selectedFiles[i]);
          }

          console.log("Update policy Info Befoere");
          const response = await axios.post(UPLOAD_POLICY_DOCUMET, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          console.log("upd before");
          console.log("upd response",response);
          console.log("upd after");
          if (response.data.code == 200) {
            let url = response.data.data.policy_pdf_url;
            console.log("Update policy Info response: ", url);
            let orderData = {
              order_id: this.selectedLeadForPolicyIssued.order_id,
              policy_number: this.policyNumber,
              policy_issue_date: this.issueDate,
              policy_start_date: this.startDate,
              policy_end_date: this.endDate,
              policy_pdf_url: url,
              welth_user_id:this.loggedInUserId,
            };
            console.log("Order data: ", orderData);
            const res = await axios.post(UPDATE_POLICY_INFO, orderData);
            if (res.data.code == 200) {
              console.log("Policy updated wiht url  ", url);
              console.log(response.data.data);
              this.closeModal(true, response.data.data);
            }
          }
        } catch (error) {
          console.error("Error pdf pagae Update policy Info", error);
        }
      }
    },

    openFileDialog() {
          const inputElement = document.getElementById("docId");
          const filePreviewElement = this.$refs.previewContainer;
          const pdfPreview = document.getElementById("pdfPreview");

          if (!inputElement) {
            console.error("Element not found.");
            return;
          }
          const self = this;

          inputElement.addEventListener("change", function () {
            if (inputElement.files.length > 0) {
              const file = inputElement.files[0];
              const isPdf = file.type === "application/pdf";

              const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
              if (file.size > maxSizeInBytes) {
                self.validationMessage = " File size exceeds 5MB. Please choose a smaller file.";
                filePreviewElement.style.display = "none";
                pdfPreview.src = "";
                self.selectedFiles = [];
                return;
              }

              if (isPdf) {
                self.validationMessage = "";
                pdfPreview.src = URL.createObjectURL(file);
                filePreviewElement.style.display = "block";
                console.log("PDF Previewed");

                // Clear previous files and add the new file to selectedFiles
                self.selectedFiles = [file];
              } else {
                self.validationMessage = "Please select a PDF file.";
                filePreviewElement.style.display = "none";
                pdfPreview.src = "";
                self.selectedFiles = [];
              }
            } else {
              console.log("No selected files");
              pdfPreview.src = "";
              filePreviewElement.style.display = "none";
              self.selectedFiles = [];
            }
      });

        inputElement.click(); // Trigger the file input dialog
      },


    
    deleteFile() {
      this.selectedFiles = [];
      const fileInput = document.getElementById("docId");
      fileInput.value = "";
      const event = new Event("change");
      fileInput.dispatchEvent(event);
    }
   
  },
  watch: {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 600px;
  padding: 20px;
  margin: 100px auto;
  border-radius: 24px;
  height: 50 0px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
  cursor: pointer;
}

.date-info {
  display: flex;
  gap: 0px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 70px;
  align-items: center;
  cursor: pointer;
  color: #3b53cb;
}

.error {
  margin-top: 10px;
  margin-left: 25px;
  color: red;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.box1-f3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-info input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.policy_number input {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
  color: #263ba0;
}

.policy_number input[type="text"]:focus,
.date-info input[type="date"]:focus,
.title_top label:focus-within {
  outline: 1px solid #263ba0;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  color: #263ba0;
}

.modal1 select {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
}

.date-info label {
  width: 80%;
  height: 36px;
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  border: 0.5px solid #c7a84b;
  background: #fff;
  margin-top: 10px;
  color: #9598a7;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
}

.close-icon {
  display: table-cell;
  justify-content: end;
  cursor: pointer;
  line-height: 48px;
}

.title {
  display: table-cell;
  color: #c7a84b;
  justify-content: flex-start;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  /* 150% */
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;
}

.save-btn {
  height: 36px;
  width: 200px;
  background: #263ba0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16;
  font-weight: 500;
  border-radius: 25px;
  margin-top: 20px;
}

.title_top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-icon-svg {
  position: relative;
  bottom: 50px;
  left: 10px;
}
</style>