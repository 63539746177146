import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

// createApp(App).use(router).mount('#app')

const app = createApp(App);

// Check for the token in local storage and set it in the Vuex store
const token = localStorage.getItem('token');
if (token) {
  store.commit('setToken', token);
}

app.use(router);
app.use(store);

app.mount('#app');