<template>
  <div class="backdrop1">
    <div class="modal1">
      <div class="close-icon" @click="closeModal">
        <img src="../images/close-popup.png" alt="" />
      </div>

      <!-- <form action="" @submit.prevent="submit"> -->
      <div class="contact-part">
        <select name="" id="" v-model="countycode">
          <option value="">India (+91)</option>
        </select>
        <input type="text" v-model="mobileNumber" placeholder="Mobile number" />

        <button @click="sendOtp">Send otp</button>
      </div>
      <div class="green" v-if="otpSent"> {{ otpSent }} </div>
      <div class="error" v-if="numberNotregi">{{ numberNotregi }}</div>

      <div class="otp-part">
        <input type="text" v-model="enterotp" name="" id="" placeholder="Enter otp" />
        <button @click="validateOtp">Validate otp</button>
      </div>

      <div class="error"> {{ validationMessage }} </div>
      <div class="green"> {{ correctOtp }} </div>
      <div class="resend">
        <p>You can resend the OTP after 1 minute.</p>
        <button :disabled="!mobileNumber"   @click="sendOtp" v-if="resendDisabled" >resend</button>
       <button v-if="!resendDisabled">{{ formattedCountdownTime }}</button> 
      </div>
      <div class="login">
        <button type="submit" @click="submit">Log in</button>
      </div>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { GENERATE_OTP_URL, LOGIN_URL, MERCHANT_ID, VENDOR_ID } from '../util/constants';
import { setSessionData, setSessionDetails, getSessionObj } from '../util/loginData';

export default {
  props: [],

  data() {
    return {
      countycode: '',
      mobileNumber: '',
      enterotp: '',
      validationMessage: '',
      correctOtp: "",
      otpSent: "",
      numberNotregi: "",
      submitbtn: "false",
      resendDisabled: true,
      countdownTime: 60,
    }
  },

  computed: {
    formattedCountdownTime() {
      const minutes = Math.floor(this.countdownTime / 60);
      const seconds = this.countdownTime % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },


  methods: {
    closeModal() {
      this.$emit("close");
    },

    async sendOtp() {
      this.startCountdown();
      this.resendDisabled = false;
      try {
        const response = await axios.post(GENERATE_OTP_URL, { "phoneNo": this.mobileNumber, "vendor_id": VENDOR_ID });
        console.log("otp sending");
        console.log('OTP sent:', response.data);
        this.numberNotregi = "";
        return this.otpSent = `succussfully otp sent  to this ${this.mobileNumber} number  `
      }
      catch (error) {
        console.error('Error sending OTP:', error);
        this.otpSent = "";
        return this.numberNotregi = "This number is not registered"
      }
    },

    async validateOtp() {
      console.log("validate otp");
      try {
        const response = await axios.post(LOGIN_URL, {
          "phoneNo": this.mobileNumber,
          "otp": this.enterotp,
          "vendor_id": VENDOR_ID
        });
        // console.log(response)
        // console.log("validateing otp");
        // console.log(response.data.data);
        if (response.data.code == 200) {
          // setSessionDetails(response.data.data);
          this.$store.dispatch('login', response.data.data.password);
          setSessionData(response.data.data.user_name, response.data.data.welth_user_id,
            response.data.data.user_email, response.data.data.role);
          this.$store.dispatch('setData', getSessionObj());
          this.correctOtp = 'OTP is valid!';
          this.submitbtn = true;
          this.closeModal();
        } else {
          this.validationMessage = 'Invalid OTP.';
        }
      } catch (error) {
        console.error('Error validating OTP:', error);
        this.validationMessage = 'Failed to validate OTP.';
      }
    },

    submit() {
      console.log(this.submitbtn)
      if (this.submitbtn == true) {
        this.closeModal();
      }
    },

    startCountdown() {
      // Update the countdown every second
      const countdownInterval = setInterval(() => {
        this.countdownTime--;

        if (this.countdownTime <= 0) {
          // Disable the Resend button after the countdown reaches zero
          this.resendDisabled = true;
          // Reset the countdown time for future use
          this.countdownTime = 60;
          // Clear the interval to stop the countdown
          clearInterval(countdownInterval);
        }
      }, 1000); // 1000 milliseconds = 1 second
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,500&family=League+Spartan:wght@400;500&family=Poppins:ital,wght@0,200;0,400;0,500;1,200;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  outline: none;
}

.modal1 {
  width: 800px;

  padding: 20px;
  margin: 100px auto;

  border-radius: 24px;
  border: 4px solid #c7a84b;
  background: #f2f2f2;
}

.backdrop1 {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.name input {
  width: 532.94px;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  margin-left: 30px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.04em;
  color: #9598a7;
}

.email {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.email input {
  width: 532.94px;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  margin-left: 30px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.04em;
  color: #9598a7;
}

.contact-part {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  gap: 15px;
}

.contact-part input {
  height: 48px;
  padding-left: 20px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
}

.contact-part select {
  width: 151.85px;
  height: 48px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  -webkit-appearance: none;
  appearance: none;
  letter-spacing: 0.04em;
  color: #9598a7;
}

.contact-part button {
  height: 48px;
  background: #c7a84b;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  width: 110px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
  margin-left: 10px;
}

.otp-part {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  gap: 10px;
  margin-left: 18px;
}

.otp-part input {
  height: 48px;
  padding-left: 20px;
  background: #ffffff;
  border: 0.5px solid #c7a84b;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.3);
  width: 352px;
  color: #9598a7;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.72px;
}

.otp-part button {
  width: 180px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
  color: #f2f2f2;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.login button {
  width: 208px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #c7a84b;
  background: #3b53cb;
  color: #f2f2f2;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.resend{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
}

.resend button{
  color: #BB9B46;
  outline: none;
  border: none;
}

.error {
  margin-left: 117px;
  margin-top: 15px;
  color: red;
}

.green {
  margin-left: 117px;
  margin-top: 15px;
  color: green;
}
</style>