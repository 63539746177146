// constants.js

// Change the Merchant Id here.
// export const MERCHANT_ID = 110;
export const MERCHANT_ID = 0;
export const PARTNER_ID = 209;
export const VENDOR_ID = 5;

// Change Server Urls here.
 const API_BASE_URL = 'https://api.dev.narayanwealth.in/live/api/v1/';

 const WELTH_API_BASE_URL = "https://api.dev.welthtech.com/live/api/v1/";

// Local URL data
// const API_BASE_URL = "http://localhost:3006/api/v1/";

// const WELTH_API_BASE_URL = "http://localhost:3005/api/v1/";

// Define APIs here.
// export const LOGIN_URL = API_BASE_URL + 'merchant/login';
// export const GENERATE_OTP_URL = API_BASE_URL + 'merchant/generateOtp';
export const LOGIN_URL = API_BASE_URL + "users/login";
export const GENERATE_OTP_URL = API_BASE_URL + "users/generateOtp";
export const UPLOAD_TX_URL =
  API_BASE_URL + "transaction/uploadTransactionData?partnerId=";
// Transcation APIs here.
//======================= APIS called to Welth Server Start =========================
// Create transaction in Welth
export const CREATE_NEW_TRANSACTION = WELTH_API_BASE_URL + "policy/renewPolicy";
export const CREATE_LEAD_URL = WELTH_API_BASE_URL + "welth-leads/create-lead";
//======================= APIS called to Welth Server End =========================

// APIS that are called to Narayan Server
export const UPLOAD_TX_IMAGE = API_BASE_URL + "users/upload-transaction-image";
export const GET_BRAND_DATA = WELTH_API_BASE_URL + "master-data/getCategoryByType";
export const UPDATE_POLICY_INFO = API_BASE_URL + "transaction/updatePolicyInfo";
export const UPLOAD_POLICY_DOCUMET = API_BASE_URL + "transaction/uploadPolicyDocument";
// Lead APIs
export const AGENT_FETCH_URL =
  API_BASE_URL + "welth-leads/get-all-agents?vendor_id=" + VENDOR_ID;
export const EMPLOYEE_FETCH_URL =
  API_BASE_URL + "welth-leads/get-employees?vendor_id=" + VENDOR_ID;
export const CC_FETCH_URL =
  API_BASE_URL + "welth-leads/get-all-ccs?vendor_id=" + VENDOR_ID;
export const LEAD_FETCH_URL =
  API_BASE_URL + "welth-leads/get-leads-narayan?vendor_id=" + VENDOR_ID;
export const LEAD_INFO_FETCH_URL =
  API_BASE_URL + "welth-leads/get-lead-info?welth_leads_id=";
export const LEAD_INFO_URL = API_BASE_URL + "welth-leads/add-lead-info";
export const UPDATE_LEAD_URL = API_BASE_URL + "welth-leads/update-lead";
export const STATUS_LIST_FETCH_URL =
  API_BASE_URL + "welth-leads/get-defined-lead-status";
// export const LEAD_COMMENT_UPLOADS = API_BASE_URL + 'welth-leads/uploadLeadsDocument?welth_leads_info_id=20&created_by_id=209';
export const LEAD_COMMENT_UPLOADS =
  API_BASE_URL + "welth-leads/uploadLeadsDocument";
export const GET_CITY_INFO = API_BASE_URL + "master-data/citys?stateId=";
export const GET_STATES_INFO = API_BASE_URL + "master-data/states";
export const GET_USER_ROLES = API_BASE_URL + "master-data/getUserRoles";
export const GET_CORPORATE_DATA = API_BASE_URL + "users/getCorporateData";
export const ADD_EXECUTIVE = API_BASE_URL + "users/add-executive";

export const STATUS_VALUES = {
  NEW_LEAD: 0,
  CALLBACK: 1,
  APPOINTMENT: 2,
  NOT_INTERESTED: 3,
  PAYMENT_DONE: 4,
  POLICY_ISSUED: 5,
  FLP_CANCELLED: 6,
  FLP_COMPLETED: 7,
  CASHBACK_APPROVED: 8,
};

//=============== Get all welth User ==============

export const GET_WELTH_USER_INFO=WELTH_API_BASE_URL+"users/getAllUserInfo?vendor_id=" + VENDOR_ID;
export const  UPLOAD_USER_DOCUMENT = WELTH_API_BASE_URL+"users/uploadUserDocument";
export const  UPLOAD_USER_DOC_DET = WELTH_API_BASE_URL+"users/uploadUserDoc";
export const  GET_WELTH_USER_DOCS = WELTH_API_BASE_URL+"users/getUserDoc";
export const  DELETE_WELTH_USER_DOCS = WELTH_API_BASE_URL+"users/deleteUserDoc/";
