import { createStore } from 'vuex';

export default createStore({
  state: {
    token: null,
    sessionData: null,
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },

    setSessionData(state, sessionData) {
      // console.log("setSessionData");
      state.sessionData = JSON.stringify(sessionData);
      // console.log(state.sessionData);
      localStorage.setItem('sessionData', JSON.stringify(sessionData));
    },

    clearToken(state) {
      state.token = null;
      localStorage.removeItem('token');
    },

    clearSessionData(state) {
      state.sessionData = null;
      localStorage.removeItem('sessionData');
    },
  },

  actions: {
    login({ commit }, token) {
      console.log("login success, storing data");
      commit('setToken', token);
      // console.log(token);
    },

    setData({ commit }, sessionData) {
      // console.log("setData");
      commit('setSessionData', sessionData);
      // console.log(sessionData);
    },

    logout({ commit }) {
      commit('clearToken');
      commit('clearSessionData');
    },
  },
});
